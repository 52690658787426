import { Edit, FilterList, Star } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../providers/SnackbarProvider";

import moment from "moment-timezone";
import { useSession } from "../../providers/SessionProvider";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const BookingOptions = ({
  memberId,
  filters,
  setFilters,
  isStacked,
  handleReset,
  visits,
  employees,
  bestMatchEmployee,
  selectedDate,
  setSelectedDate,
  onFilterVisits,
  onFilterVisitsDate,
}) => {
  const { showSnackbar } = useSnackbar();
  const { createLog } = useSession();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const buttonSize = isMobile ? "medium" : "medium";

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterResults, setFilterResults] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);

  const updateVisibleVisits = (date, filters) => {
    if (!employees) return;

    if (!Array.isArray(visits)) {
      console.error("Visits data is not an array or not loaded yet.");
      return null; // Or return a loading state/component
    }

    if (!selectedDate) return;

    // Assuming visits is some global array or fetched data.
    const { filterResults, filterEmployees } = filterVisits(visits, {
      date,
      ...filters,
    });
    onFilterVisitsDate(filterResults);
    setAvailableEmployees(filterEmployees);
  };

  // useEffect(() => {
  //   const availableDurations = getAvailableDurations();
  //   if (filters.duration && !availableDurations.includes(filters.duration)) {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       duration: availableDurations[availableDurations.length - 1],
  //     }));
  //   }
  // }, [filters, visits]);

  useEffect(() => {
    const availableFrequencies = getAvailableFrequencies();
    if (
      filters.frequency &&
      !availableFrequencies.includes(filters.frequency)
    ) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        frequency: availableFrequencies[availableFrequencies.length - 1],
      }));
    }
  }, [filters, visits]);

  // useEffect(() => {
  //   const availableNumEmployees = getAvailableNumEmployees();
  //   if (
  //     filters.numEmployees &&
  //     !availableNumEmployees.includes(filters.numEmployees)
  //   ) {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       numEmployees: availableNumEmployees[availableNumEmployees.length - 1],
  //     }));
  //   }
  // }, [filters.duration, visits]);

  useEffect(() => {
    const availableDurations = [
      ...new Set(
        visits
          .filter(
            (visit) =>
              filters.numEmployees === "all" ||
              visit.employeeIds.length === parseInt(filters.numEmployees)
          )
          .map((visit) => visit.duration)
      ),
    ].sort((a, b) => a - b);

    if (!availableDurations.includes(filters.duration)) {
      const newDuration =
        availableDurations[availableDurations.length - 1] || "";
      setFilters((prevFilters) => ({
        ...prevFilters,
        duration: newDuration,
      }));
    }
  }, [visits, filters.numEmployees, filters.duration]);

  const filterVisits = (visits, filters) => {
    if (!employees) return;

    if (!Array.isArray(visits)) {
      console.error("Visits data is not an array or not loaded yet.");
      return null; // Or return a loading state/component
    }
    const { date, duration, frequency, employeeIds, numEmployees } = filters;

    let filterResults = visits;

    if (duration) {
      filterResults = filterResults.filter(
        (visit) => visit.duration === duration
      );
    }

    if (frequency) {
      filterResults = filterResults.filter((visit) =>
        visit.frequency.includes(frequency)
      );
    }

    // console.log("visit.employeeIds: ", visit.employeeIds);

    if (numEmployees && numEmployees !== "all") {
      // Filter based on the number of employees
      const num = parseInt(numEmployees);
      filterResults = filterResults.filter(
        (visit) => visit.employeeIds.length === num
      );
    }

    // Initially, assume all employees are available
    // let filterEmployees = Object.keys(employees);

    const filterEmployees = [
      ...new Set(filterResults.flatMap((visit) => visit.employeeIds)),
    ];

    if (employeeIds && employeeIds.length > 0) {
      if (numEmployees == 1) {
        // For numEmployees 1, consider all available slots for the selected employees
        filterResults = filterResults.filter((visit) =>
          employeeIds.some((id) => visit.employeeIds.includes(id))
        );
      } else {
        // Filter based on specific employeeIds
        filterResults = filterResults.filter((visit) =>
          employeeIds.every((id) => visit.employeeIds.includes(id))
        );

        // Calculate available employees based on the filtered visits
        // filterEmployees = [
        //   ...new Set(filterResults.flatMap((visit) => visit.employeeIds)),
        // ];
      }
    }

    if (date) {
      filterResults = filterResults.filter((visit) =>
        moment(visit.start).isSame(moment(date), "day")
      );
    }

    return {
      filterResults,
      filterEmployees,
    };
  };

  const formatFilterSummary = () => {
    const { duration, frequency, numEmployees, employeeIds } = filters;

    const summaryItems = [];

    if (isMobile) {
      summaryItems.push(<FilterList />);
    } else {
      summaryItems.push(
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            mr: 1,
          }} // Subtle color for the title
        >
          <FilterList sx={{ mr: 1 }} /> {/* Material UI Filter Icon */}
          Filters
        </Typography>
      );
    }

    // Number of Employees
    if (numEmployees) {
      summaryItems.push(
        <Button
          sx={{ textTransform: "none", borderRadius: "10px" }}
          key="employees"
          size={buttonSize}
          variant="outlined"
          startIcon={<Edit />}
        >
          {`${numEmployees} ${numEmployees > 1 ? "helpers" : "helper"}`}
        </Button>
      );
    }

    // Duration
    if (duration) {
      summaryItems.push(
        <Button
          sx={{ textTransform: "none", borderRadius: "10px" }}
          key="duration"
          size={buttonSize}
          variant="outlined"
          startIcon={<Edit />}
        >
          {`${duration} hour${duration > 1 ? "s" : ""}`}
        </Button>
      );
    }

    // Frequency
    if (frequency) {
      const frequencyText =
        frequency === 1
          ? "Every week"
          : frequency === 2
          ? "Every 2-weeks"
          : "Every 4-weeks";

      summaryItems.push(
        <Button
          sx={{ textTransform: "none", borderRadius: "10px" }}
          key="frequency"
          size={buttonSize}
          variant="outlined"
          startIcon={<Edit />}
        >
          {frequencyText}
        </Button>
      );
    }

    // Employee Names
    if (employeeIds.length > 0) {
      const employeeNames = employeeIds
        .map((id) => employees[id]?.firstName)
        .join(", ");

      summaryItems.push(
        <Button
          sx={{ textTransform: "none", borderRadius: "10px" }}
          key="employeeNames"
          size={buttonSize}
          variant="outlined"
          startIcon={<Edit />}
        >
          {employeeNames}
        </Button>
      );
    }

    return summaryItems;
  };

  useEffect(() => {
    updateVisibleVisits(selectedDate, filters);
  }, [filters, visits, selectedDate]);

  const getAvailableDurations = () => {
    const { frequency, employeeIds, numEmployees, date } = filters;
    return [
      ...new Set(
        visits
          .filter((visit) => {
            let valid = true;
            if (frequency) valid = valid && visit.frequency.includes(frequency);
            if (numEmployees && numEmployees !== "all")
              valid =
                valid && visit.employeeIds.length === parseInt(numEmployees);
            if (employeeIds && employeeIds.length > 0)
              valid =
                valid &&
                employeeIds.some((id) => visit.employeeIds.includes(id));
            if (date)
              valid = valid && moment(visit.start).isSame(moment(date), "day");
            return valid;
          })
          .map((visit) => visit.duration)
      ),
    ].sort((a, b) => a - b);
  };

  const getAvailableFrequencies = () => {
    const { duration, employeeIds, numEmployees, date } = filters;
    return [
      ...new Set(
        visits
          .filter((visit) => {
            let valid = true;
            if (duration) valid = valid && visit.duration === duration;
            if (numEmployees && numEmployees !== "all")
              valid =
                valid && visit.employeeIds.length === parseInt(numEmployees);
            if (employeeIds && employeeIds.length > 0)
              valid =
                valid &&
                employeeIds.some((id) => visit.employeeIds.includes(id));
            if (date)
              valid = valid && moment(visit.start).isSame(moment(date), "day");
            return valid;
          })
          .flatMap((visit) => visit.frequency)
      ),
    ].sort((a, b) => a - b);
  };

  const getAvailableNumEmployees = () => {
    const { duration, frequency, employeeIds, date } = filters;
    return [
      ...new Set(
        visits
          .filter((visit) => {
            let valid = true;
            if (duration) valid = valid && visit.duration === duration;
            if (frequency) valid = valid && visit.frequency.includes(frequency);
            if (employeeIds && employeeIds.length > 0)
              valid =
                valid &&
                employeeIds.some((id) => visit.employeeIds.includes(id));
            if (date)
              valid = valid && moment(visit.start).isSame(moment(date), "day");
            return valid;
          })
          .map((visit) => visit.employeeIds.length)
      ),
    ].sort((a, b) => a - b);
  };

  useEffect(() => {
    if (!employees) return;
    if (!visits) return;

    const { filterResults = {}, filterEmployees = {} } =
      filterVisits(visits, filters) || {};
    onFilterVisits(filterResults);
    setFilterResults(filterResults);
    setAvailableEmployees(filterEmployees);
  }, [filters, visits]);

  useEffect(() => {
    // When numEmployees changes, reset the employeeId filter to empty

    setFilters((prevFilters) => ({
      ...prevFilters,
      employeeIds: [],
    }));
  }, [filters?.numEmployees]); // Add filters.numEmployees as a dependency of the effect

  // useEffect(() => {

  //   const uniqueDurations = [...new Set(visits.map((visit) => visit.duration))];

  //   console.log("uniqueDurations: ", uniqueDurations);
  //   console.log("filters.duration: ", filters.duration);

  //   if (!uniqueDurations.includes(filters.duration)) {
  //     setFilters((prevFilters) => {
  //       const newDuration = uniqueDurations.includes(2)
  //         ? 2
  //         : uniqueDurations[0] || "";
  //       return {
  //         ...prevFilters,
  //         duration: newDuration,
  //       };
  //     });
  //   }
  // }, [visits, filters.duration]);

  if (!Array.isArray(visits)) {
    console.error("Visits data is not an array or not loaded yet.");
    return null; // Or return a loading state/component
  }

  const handleApplyFilters = () => {
    const { numEmployees, frequency, duration, employeeIds } = filters;
    const activeFilters = [];
    if (numEmployees) {
      activeFilters.push(`Number of helpers: ${numEmployees}`);
    }
    if (frequency) {
      const frequencyText =
        frequency === 1
          ? "Every week"
          : frequency === 2
          ? "Every 2-weeks"
          : "Every 4-weeks";
      activeFilters.push(`Frequency: ${frequencyText}`);
    }
    if (duration) {
      activeFilters.push(
        `Duration: ${duration} hour${duration > 1 ? "s" : ""}`
      );
    }
    if (employeeIds && employeeIds.length > 0) {
      activeFilters.push(`Selected Helpers: ${employeeIds.join(", ")}`);
    }
    const filterString = `Applied Filters: ${activeFilters.join(" | ")}`;

    createLog({
      collectionName: "members",
      idsArr: [memberId],
      summary: filterString,
      logType: "activity",
    });

    setFiltersOpen(false);
    setSelectedDate(null);
  };

  return (
    <>
      {/* Minimized Filter View */}
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          transition: "background-color 0.3s ease", // Transition effect for background-color

          "&:hover": {
            backgroundColor: "#e0e0e0", // Noticeably darker on hover
          },
          py: 1,
          px: isStacked ? 1 : 2,
          mx: isStacked ? "-12px" : 0,
          mt: { xs: "-8px" },
          mb: 2,
          borderRadius: { xs: "15px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setFiltersOpen(!filtersOpen)}
      >
        <Box
          sx={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              gap: { xs: 0, sm: 1 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {/* Subtle color for the summary text */}
              {formatFilterSummary()}
            </Box>
          </Box>
          {/* <Button
            size={buttonSize}
            variant="outlined"
            startIcon={<Edit />}
            onClick={() => setFiltersOpen(!filtersOpen)}
            sx={{ textTransform: "none", borderRadius: "10px" }}
          >
            Edit
          </Button> */}
        </Box>
      </Box>

      {/* Expanded Filters */}
      {/* <Stack spacing={2} direction={{ xs: "column", sm: "column", md: "row" }}>
        <Collapse in={!filtersOpen}> */}

      <ResponsiveDialog
        title={"Booking Filters"}
        open={filtersOpen}
        onClose={handleApplyFilters}
        dialogActions={
          <Box
            sx={{
              display: { xs: "flex", sm: "block" },
              flexDirection: { xs: "column-reverse", sm: "row" },

              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Button
              onClick={handleReset}
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
                textTransform: "none",
                mr: { xs: 0, sm: 1 },
              }}
              disableElevation={true}
              variant="outlined"
              color="primary"
            >
              Reset
            </Button>
            <Button
              onClick={handleApplyFilters}
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
                textTransform: "none",
                mb: { xs: 1, sm: 0 },
              }}
              disableElevation={true}
              variant="contained"
              color="primary"
              disabled={filterResults.length === 0}
            >
              {filterResults.length === 0
                ? "No results"
                : `Show ${filterResults.length} ${
                    filterResults.length === 1 ? "result" : "results"
                  }`}
            </Button>
          </Box>
        }
        width={"600px"}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="body1" gutterBottom>
            Set your filters to see available visit times.
          </Typography>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel># of Helpers</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.numEmployees}
              label="# of Helpers"
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  numEmployees: e.target.value,
                }))
              }
            >
              {[...new Set(visits.map((visit) => visit.employeeIds.length))]
                .sort((a, b) => a - b) // Sort in ascending order
                .map((numEmployees) => (
                  <MenuItem key={numEmployees} value={numEmployees}>
                    <Typography>{numEmployees}</Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Duration</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              label="Duration"
              value={filters.duration}
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  duration: e.target.value,
                }))
              }
            >
              {getAvailableDurations().map((duration) => (
                <MenuItem key={duration} value={duration}>
                  {duration} {duration > 1 ? "hours" : "hour"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel>Frequency</InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.frequency}
              label="Frequency"
              onChange={(e) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  frequency: e.target.value,
                }))
              }
            >
              {getAvailableFrequencies().map((freq) => {
                const freqLabel =
                  freq === 0
                    ? "One-time"
                    : freq === 1
                    ? "Every week"
                    : freq === 2
                    ? "Every 2-weeks"
                    : "Every 4-weeks";
                return (
                  <MenuItem key={freq} value={freq}>
                    {freqLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel>
              {filters.numEmployees > 1 ? "Helpers" : "Helper"}
            </InputLabel>
            <Select
              sx={{ backgroundColor: "#f5f5f5" }}
              value={filters.employeeIds}
              label={filters.numEmployees > 1 ? "Helpers" : "Helper"}
              onChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  employeeIds: e.target.value,
                }));
                setSelectOpen(false); // Close the select menu
              }}
              open={selectOpen}
              onClose={() => setSelectOpen(false)}
              onOpen={() => setSelectOpen(true)}
              multiple
              renderValue={(selected) => (
                <Grid container alignItems="center" justifyContent="flex-start">
                  <AvatarGroup max={4}>
                    {/* {selected.map((id) => {
                            const employee = employees.find(
                              (emp) => emp.id === id
                            ); */}
                    {selected.map((id) => {
                      const employee = employees[id];
                      return (
                        <Avatar
                          key={id}
                          src={employee?.avatarUrl}
                          sx={{ width: 24, height: 24 }}
                          alt={employee?.firstName}
                        />
                      );
                    })}
                  </AvatarGroup>
                </Grid>
              )}
            >
              {employees && availableEmployees?.length > 0 ? (
                Object.entries(employees)
                  .filter(([id]) => availableEmployees.includes(id))
                  .map(([id, employee], index, array) => (
                    <MenuItem
                      key={id}
                      value={id}
                      sx={{
                        marginBottom: index !== array.length - 1 ? 1 : 0,
                      }}
                      disabled={!availableEmployees.includes(id)}
                    >
                      <Grid container alignItems="center">
                        <Checkbox checked={filters.employeeIds.includes(id)} />
                        <Grid item marginRight={2}>
                          <Avatar
                            src={employee?.avatarUrl}
                            sx={{ width: 24, height: 24 }}
                            alt={employee?.firstName}
                          />
                        </Grid>
                        <Grid item marginRight={2}>
                          <Typography>
                            {employee?.firstName}{" "}
                            {employee?.lastName?.charAt(0)}.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          marginRight={2}
                          display={"flex"}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {id === bestMatchEmployee && (
                            <Tooltip
                              enterTouchDelay={0}
                              title="Great match for the tasks you want completed."
                            >
                              <SvgIcon color="action" style={{ color: "gold" }}>
                                <Star />
                              </SvgIcon>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))
              ) : (
                <MenuItem disabled>
                  <div>No helpers found.</div>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </DialogContent>
      </ResponsiveDialog>
      {/* </Collapse>
      </Stack> */}
    </>
  );
};

export default BookingOptions;
