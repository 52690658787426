import { Box } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import AdGroup from "./AdGroup";
import HelperHighlight from "./HelperHighlight";
import HelperReviews from "./HelperReviews";

const AdsView = () => {
  const { helperId } = useParams();
  const [helperData, setHelperData] = useState(null);

  const [isHelperDataLoaded, setIsHelperDataLoaded] = useState(false);
  const [isAdGroupLoaded, setIsAdGroupLoaded] = useState(false);
  const [isReviewsLoaded, setIsReviewsLoaded] = useState(false);

  useEffect(() => {
    const fetchHelperData = async () => {
      if (!helperId) return;
      try {
        const helperDocRef = doc(db, "employees", helperId);
        const helperDocSnap = await getDoc(helperDocRef);
        if (helperDocSnap.exists()) {
          setHelperData(helperDocSnap.data());
        }
      } catch (error) {
        console.error("Error fetching helper data:", error);
      } finally {
        setIsHelperDataLoaded(true);
      }
    };

    fetchHelperData();
  }, [helperId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        alignItems: "center",
      }}
    >
      <AdGroup
        helperData={helperData}
        onLoaded={() => setIsAdGroupLoaded(true)}
      />
      {helperData && <HelperHighlight helperData={helperData} />}
      <HelperReviews onLoaded={() => setIsReviewsLoaded(true)} />
      {isAdGroupLoaded && isReviewsLoaded && isHelperDataLoaded && (
        <div id="page-loaded" style={{ display: "none" }} />
      )}
    </Box>
  );
};

export default AdsView;
