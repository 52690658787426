import { CreditCardOutlined, HomeRepairServiceOutlined, SavingsOutlined, VerifiedUserOutlined, WavingHandOutlined } from "@mui/icons-material";
import BuildIcon from "@mui/icons-material/Build";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Box, Typography } from "@mui/material";
import React from "react";

const guidelines = [
  {
    title: "Tools and Supplies",
    description: "Please have any items needed ready.",
    icon: <HomeRepairServiceOutlined color="primary" />,
  },
  {
    title: "Payment Online",
    description: "We bill your card the day after the visit.",
    icon: <CreditCardOutlined color="primary" />,
  },
  {
    title: "Satisfaction Guarantee",
    description:
      "No charges for tasks beyond our skill.",
    icon: <VerifiedUserOutlined color="primary" />,
  },
  {
    title: "Tips Appreciated",
    description:
      "Great visit? Tip your helper directly!",
    icon: <SavingsOutlined color="primary" />,
  },
  {
    title: "Invite Friends",
    description: "Your word of mouth is a huge help.",
    icon: <WavingHandOutlined color="primary" />,
  }
];

const VisitGuidelines = ({ items = guidelines }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {items.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" gap={2}>
          <Box mt={0.5}>{item.icon}</Box>
          <Box>
            <Typography fontWeight={600}>{item.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {item.description}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default VisitGuidelines;
