import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { db } from "../../firebase";
import { sendSMSClient } from "../../services/communicationServices";

const InterviewCard = ({
  handleNext = () => {},
  handleBack = () => {},
  showBack = false,
  employeePrivateData,
  userId = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [interviewDate, setInterviewDate] = useState("");
  const [error, setError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e) => {
    setInterviewDate(e.target.value);
    setError(""); // Clear any existing errors on input change
  };

  const handleSubmit = async () => {
    if (!interviewDate) {
      setError("Please select a valid interview date and time.");
      return;
    }

    setLoading(true);
    try {
      // Format the date
      const formattedInterviewDate = DateTime.fromISO(interviewDate).toFormat(
        "cccc, LLLL dd, yyyy 'at' h:mm a"
      ); // Example: Monday, June 10, 2024 at 3:00 PM

      sendSMSClient({
        phone: employeePrivateData?.phone,
        body: `Linked Lives: Hi ${employeePrivateData?.firstName}, your interview is scheduled for ${formattedInterviewDate}! Please check the app for details.`,
      });

      // Update Firestore with interviewDate
      await setDoc(
        doc(db, "employeesPrivate", userId),
        { interviewDate: DateTime.fromISO(interviewDate).toJSDate() },
        { merge: true }
      );
      handleNext(); // Proceed to the next step
    } catch (err) {
      console.error("Error scheduling interview:", err);
      setError("Failed to schedule the interview. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogContent>
        {showBack && (
          <Button
            onClick={handleBack}
            disabled={loading}
            sx={{ color: "text.secondary", my: 1 }}
          >
            Back
          </Button>
        )}
        <Typography
          align="left"
          fontWeight={"600"}
          fontSize={"1.15rem"}
          gutterBottom
        >
          Schedule an Interview
        </Typography>
        <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
          Select a date and time for your interview.
        </Typography>
        <Box
          sx={{
            maxWidth: "400px",
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Interview Date & Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={interviewDate}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              width: "100%",
              height: "56px",
              textTransform: "none",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Typography fontWeight={"500"}>Schedule Interview</Typography>
            )}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default InterviewCard;
