import {
  CakeOutlined,
  EventOutlined,
  LocationOnOutlined,
  Straighten,
  Verified,
} from "@mui/icons-material";
import { Avatar, Box, Rating, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { getCurrentArea } from "../../services/locationServices";
import { TASKS_LIST } from "../../services/taskServices";

// Simple age calculation helper – adjust as needed.
const calculateAge = (birthMonth) => {
  if (!birthMonth) return "";
  const birthDate = new Date(birthMonth);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const HelperHighlight = ({
  helperData,
  fontScale = 1.25,
  showSkills = true,
  showLocation = true,
  autoHeight = false,
  autoWidth = false,
  bioLineClamp = 5,
}) => {
  if (!helperData) return null;

  const { areaData = {} } = getCurrentArea(helperData?.areas) || {};

  // Extract and sort skills based on ratings
  const skills =
    helperData.tasks && helperData.tasks.length > 0
      ? helperData.tasks
      : TASKS_LIST.map((task) => task.title);

  const ratings = helperData.ratings?.byCategory || {};

  // Sort skills by highest rating first
  const sortedSkills = [...skills].sort((a, b) => {
    const ratingA = ratings[a]?.totalScore || 0;
    const ratingB = ratings[b]?.totalScore || 0;
    return ratingB - ratingA; // Descending order
  });

  // Convert to string format
  const skillsText = sortedSkills.join(", ");

  const locationText = areaData.city ? `${areaData.city}` : "";

  const infoItems = [
    showLocation && {
      icon: (
        <LocationOnOutlined
          sx={{ fontSize: `${18 * fontScale}px`, color: "text.secondary" }}
        />
      ),
      text: locationText,
    },
    helperData?.created && {
      icon: (
        <EventOutlined
          sx={{ fontSize: `${18 * fontScale}px`, color: "text.secondary" }}
        />
      ),
      text: `Joined ${DateTime.fromJSDate(helperData.created.toDate()).toFormat(
        "LLL yyyy"
      )}`,
    },
    {
      icon: (
        <CakeOutlined
          sx={{ fontSize: `${18 * fontScale}px`, color: "text.secondary" }}
        />
      ),
      text: `${calculateAge(helperData.birthMonth)}, ${helperData.gender}`,
    },
    helperData?.height && {
      icon: (
        <Straighten
          sx={{
            fontSize: `${18 * fontScale}px`,
            color: "text.secondary",
            transform: "rotate(90deg)",
          }}
        />
      ),
      text: helperData.height,
    },
  ].filter(Boolean); // Removes any undefined/null items (e.g., height if not available)

  return (
    <Box
      sx={{
        width: autoWidth ? "100%" : 540,
        height: autoHeight ? "auto" : 540,
        // border: "1px solid",
        boxSizing: "border-box",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 0,
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {/* Header */}
      {/* <Typography
        variant="body1"
        sx={{ fontWeight: 700, fontSize: 22, color: "text.secondary", mb: 5 }}
      >
        Meet your Household Helper...
      </Typography> */}
      {/* <Divider sx={{width: "80%", my: 1}} /> */}

      {/* Avatar and details */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar
          alt={`${helperData.firstName} ${helperData.lastName}`}
          src={helperData.avatarUrl}
          sx={{ width: 120 * fontScale, height: 120 * fontScale }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: `${20 * fontScale}px`, fontWeight: "700" }}
            >
              {helperData.firstName} {helperData.lastName.charAt(0)}.
            </Typography>
            <Verified
              color={"primary"}
              sx={{ fontSize: `${20 * fontScale}px`, fontWeight: "700" }}
            />
          </Box>

          {infoItems.map((item, index) => {
            const { icon, text } = item || {};
            return (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                {icon}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: `${14 * fontScale}px`,
                    color: "text.secondary",
                  }}
                >
                  {text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 0.5,
          mt: 2,
          backgroundColor: "#F5F5F5",
          p: 2,
          borderRadius: "15px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: `${14 * fontScale}px`, fontWeight: "700" }}
        >
          About me
        </Typography>

        {/* Bio text restricted to 5 lines */}
        <Typography
          variant="body1"
          sx={{
            fontSize: `${16 * fontScale}px`,
            textAlign: "flex-start",
            display: "-webkit-box",
            WebkitLineClamp: bioLineClamp,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {helperData.bio}
        </Typography>
      </Box>

      {/* Display skills, restricting to 2 lines with ellipsis */}
      {showSkills && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 0.5,
            mt: 0,
            // backgroundColor: "#F5F5F5",
            p: 2,
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: `${14 * fontScale}px`, fontWeight: "700" }}
            >
              My skills
            </Typography>
            <Rating
              value={5}
              precision={0.1}
              readOnly
              sx={{ fontSize: 20 * fontScale }}
            />
          </Box>

          <Typography
            variant="body1"
            sx={{
              fontSize: `${16 * fontScale}px`,
              textAlign: "flex-start",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {skillsText}
          </Typography>
        </Box>
      )}

      {/* Skills with icons */}
      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
          mt: 2,
        }}
      >
        {matchedSkills.map((skill, index) => (
          <Chip
            key={index}
            label={skill.label}
            icon={skill.icon ? skill.icon : <LocationOn />}
          />
        ))}
      </Box> */}
    </Box>
  );
};

export default HelperHighlight;
