import React, { useState, useEffect, useRef } from "react";
import { ChevronRightRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

const LevelBar = ({ progressPercentage, pointsInCurrentLevel, handleOpenLevelUp, showLevelUp }) => {
  const progressRef = useRef(null);
  const textRef = useRef(null);
  const [useBlackText, setUseBlackText] = useState(false);

  // This effect measures both the progress bar's fill and the text width,
  // updating our state so we know which mode to use.
  useEffect(() => {
    const updateTextMode = () => {
      if (progressRef.current && textRef.current) {
        const progressWidth = progressRef.current.getBoundingClientRect().width;
        const textWidth = textRef.current.getBoundingClientRect().width;
        setUseBlackText(progressWidth < textWidth);
      }
    };

    // Run on mount and whenever progress changes.
    updateTextMode();
    window.addEventListener("resize", updateTextMode);
    return () => window.removeEventListener("resize", updateTextMode);
  }, [progressPercentage]);

  return (
    <>
      {!showLevelUp && (
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#F5F5F5",
            borderRadius: "15px",
            height: "24px",
            width: "100%",
            overflow: "hidden",
            mt: { xs: 0, sm: 0 },
          }}
        >
          {/* The progress fill */}
          <Box
            ref={progressRef}
            sx={{
              width: `${Math.max(0, progressPercentage)}%`,
              background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
              height: "100%",
              borderRadius: "15px",
              minWidth: "8px",
              transition: "width 0.5s ease",
            }}
          ></Box>

          {/* The progress text */}
          <Box
            ref={textRef}
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              // If the progress bar is too short, position the text at its end,
              // otherwise use a fixed left offset.
              left: useBlackText ? `max(${progressPercentage}%, 8px)` : "8px",
              color: useBlackText ? "black" : "white",
              fontWeight: "bold",
              // A small left padding when text is moved to the end of the progress fill.
              pl: useBlackText ? "4px" : 0,
              whiteSpace: "nowrap",
              pr: "12px",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="body1" component="span" sx={{ fontWeight: "600" }}>
              {pointsInCurrentLevel}
            </Typography>
            <Typography variant="body1" component="span" sx={{ fontWeight: "500", opacity: 0.8 }}>
              /1000
            </Typography>
          </Box>
        </Box>
      )}

      {showLevelUp && (
        <Button
          disableElevation
          variant="contained"
          color="success"
          sx={{
            width: "100%",
            height: "44px",
            textTransform: "none",
            background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
            mx: "auto",
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: "-150%",
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)",
              transform: "skewX(-20deg)",
              animation: "wipe 2.5s infinite",
            },
            "@keyframes wipe": {
              "0%": { left: "-200%" },
              "100%": { left: "200%" },
            },
          }}
          onClick={handleOpenLevelUp}
          endIcon={<ChevronRightRounded />}
        >
          Level up
        </Button>
      )}
    </>
  );
};

export default LevelBar;
