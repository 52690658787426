import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import { getActiveBadges, renderIcon } from "./ProgressTab";

// Standalone BadgesGroup component
const BadgesGroup = ({ progress = {}, size = 34, spacing=10 }) => {
  const activeBadges = getActiveBadges(progress);

  return (
    <AvatarGroup max={12} spacing={spacing}>
      {activeBadges.map((badgeKey) => (
        <Avatar key={badgeKey} sx={{ width: size - 2, height: size - 2 }}>
          {renderIcon({
            key: badgeKey,
            size,
            multiplier: 0,
            glow: false,
            active: true,
            onClick: () => {},
          })}
        </Avatar>
      ))}
    </AvatarGroup>
  );
};

export default BadgesGroup;
