import { Box, DialogContent, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import React from "react";
import { calculateAge, formatAddress } from "../../services/dateServices";
import { formatPhoneNumber } from "../../services/stringServices";
import HelperReferralField from "../fields/HelperReferralField";

const ApplicationInfo = ({ employeePrivateData }) => {
  const {
    firstName = "",
    lastName = "",
    phone = "",
    email = "",
    birthMonth = "",
    address = {},
    gender = "",
    tshirtSize = "",
    personalInterests = "",
    communityInvolvement = "",
    missionAlignment = "",
    heardAboutUs = "",
    setup: { installBypass = null } = {},
    bio = "",
    tasks = [],
    created = Timestamp.now(),
    appInstalled = false,
    idealHours = null,
    references = [],
    setup: {
      merch: {
        trackingUrl = null,
        status: merchStatus = "unknown",
        shippedAt = null,
        deliveredAt = null,
      } = {},
      orderedMerch = false,
      avatarUpdated,
    } = {},
  } = employeePrivateData || {};

  // Helper function to format values based on type
  const formatValue = (key, value) => {
    if (value instanceof Timestamp) {
      return value.toDate().toLocaleString(); // Format Firebase Timestamp as a date
    } else if (Array.isArray(value)) {
      return value.join(", "); // Join arrays into comma-separated strings
    } else if (typeof value === "boolean") {
      return value ? "Yes" : "No"; // Format booleans as Yes/No
    } else if (typeof value === "string") {
      return value.trim() === "" ? "-" : value; // Return N/A if string is whitespace, otherwise return string as-is
    } else if (typeof value === "number") {
      return value; // Return the number as-is
    } else {
      return "N/A"; // Fallback for unsupported types
    }
  };

  // Key-value pairs to display
  const dataEntries = {
    Name: `${firstName} ${lastName}`,
    Phone: formatPhoneNumber(phone),
    email,
    age: calculateAge(birthMonth),
    address: formatAddress(address),
    gender,
    "T-Shirt Size": tshirtSize,
    bio,
    "Personal Interests": personalInterests,
    "Community Involvement": communityInvolvement,
    "Mission Alignment": missionAlignment,
    tasks,
    created,
    "Ideal weekly hours": idealHours,
    "Heard About Us": heardAboutUs,
    "App Insalled": appInstalled,
    "Install bypass": installBypass,
    "Merch status": merchStatus,
    "Merch shipped": shippedAt,
    "Merch delivered": deliveredAt,
    "Avatar updated": avatarUpdated
  };

  return (
    <DialogContent>
      <Box>
        {Object.entries(dataEntries).map(([key, value]) => (
          <Box key={key} sx={{ marginBottom: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </Typography>
            <Typography variant="body1">{formatValue(key, value)}</Typography>
          </Box>
        ))}
      </Box>

      {/* References Section */}

      {references.length > 0 && (
        <>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            References
          </Typography>

          <Box>
            {references.map((ref, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography variant="body2">
                  {ref.referenceName || "Reference"}:
                </Typography>
                <Typography variant="body1">
                  {formatPhoneNumber(ref.phone)}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      <div style={{ height: "16px" }} />

      {/* Helper referral */}
      <HelperReferralField employeePrivateData={employeePrivateData} />
    </DialogContent>
  );
};

export default ApplicationInfo;
