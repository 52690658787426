import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { auth } from "../../firebase";

import {
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useSession } from "../../providers/SessionProvider";
import { sendSMSClient } from "../../services/communicationServices";
import { addAdminData, addMemberRole } from "../../services/memberServices";
import NameField from "../fields/NameField";
import PhoneField from "../fields/PhoneField";

const SignUpView = ({ title, email, setView, showBack = false }) => {
  const { createLog, sessionId, utmSource, locationData, logSessionActivity } =
    useSession();

  const [values, setValues] = useState({
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    termsAccepted: true,
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const nextUrl = "/dashboard";

  // const query = new URLSearchParams(window.location.search);
  // const referralParam = query.get("referral");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    debounceActivity(values);
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateStep = () => {
    let tempErrors = {};
    if (!values.password) tempErrors.password = "You must choose a password.";
    if (!values.firstName) tempErrors.firstName = "First Name is required";
    if (!values.lastName) tempErrors.lastName = "Last Name is required";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Your phone number must be 10 digits.";
    if (!values.termsAccepted || values.termsAccepted === false)
      tempErrors.termsAccepted = "You must accept the terms and conditions.";

    setErrors(tempErrors);
    return tempErrors;
  };

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Debounced task matching logic
  const debounceActivity = useCallback(
    debounce((values) => {
      const combinedValues = Object.entries(values)
        .filter(
          ([key, val]) =>
            key !== "termsAccepted" && key !== "password" && val !== ""
        )
        .map(([key, val]) => `${key}: ${val}`)
        .join(", ");

      logSessionActivity(`Typing form → ${combinedValues}`);
    }, 1000),
    []
  );

  const handleLog = async () => {
    const created = DateTime.now().toFormat("ccc LLL dd, hh:mm a");

    const smsBody = `New sign up\nName: ${values?.firstName} ${values?.lastName}\nPhone: ${values?.phone}\nEmail: ${email}\n${created}`;

    // Send Admin a message that a new person has signed up
    sendSMSClient({
      phone: "3603366344",
      body: smsBody,
    });

    if (sessionId) {
      try {
        await setDoc(
          doc(db, "sessions", sessionId),
          {
            _createdAccount: new Date(),
            _name: values?.firstName + " " + values?.lastName || "",
            _source: "signup",
            _phone: values?.phone.replace(/\D/g, "") || "",
            _email: email || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    localStorage.setItem("leadCaptured", "true");
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    const localErrors = validateStep();

    if (Object.keys(localErrors).length > 0) {
      setLoading(false);
      const errorString = Object.entries(localErrors)
        .map(([key, val]) => `${key}: ${val}`)
        .join(", ");
      console.log("errorString: ", errorString);
      logSessionActivity(`Steps are not complete: ${errorString}`);
      return;
    }

    logSessionActivity(`Creating account...`);

    const newError = {}; // initialize newError object here
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email || "unknown",
        values.password || "unknown"
      );
      const user = userCredential.user;

      // Usage with values.firstName and values.lastName
      const formattedFirstName = capitalizeFirstLetter(values.firstName);
      const formattedLastName = capitalizeFirstLetter(values.lastName);

      // Run updateProfile and addMemberRole in parallel
      await Promise.all([
        updateProfile(user, {
          displayName: `${formattedFirstName} ${formattedLastName}`,
        }),
        addMemberRole(),
      ]);

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);

      console.log("3");

      const announcements = [
        "welcome_to_linked_lives",
        "scheduling_visits",
        "tools_and_supplies",
        "services_overview",
        "monthly_billing",
      ];

      const notificationSettings = {
        bookingConfirmation: { email: true, sms: true },
        visitCancellations: { email: true, sms: true },
        visitUpdates: { email: true, sms: true },
        visitReminders: { email: true, sms: true },
        visitExpirations: { email: true, sms: true },
        visitReviews: { email: true, sms: true },
        newsletters: { email: true, sms: true },
        promotions: { email: true, sms: true },
      };
      console.log("4");

      // const now = Date.now();
      // const COUNTDOWN_DURATION = 3600; // 1 hour countdown in seconds
      // const firstVisited = localStorage.getItem("firstVisited") || 0;
      // const timePassed = Math.floor((now - firstVisited) / 1000);
      // const remainingTime = Math.max(COUNTDOWN_DURATION - timePassed, 0);
      // const freeHour = remainingTime > 0;

      // Data for addAdminData function
      const adminData = {
        termsAccepted: values.termsAccepted,
        // freeHour: freeHour,
        // referralParam,
      };

      const timeZoneId =
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "America/Los_Angeles";
      const sanitizedPhone = values.phone.replace(/\D/g, "");

      // Create a document in Firestore for the member
      await Promise.all([
        // Setting the member's public data
        setDoc(doc(db, "members", user.uid), {
          avatarUrl: "",
          firstName: formattedFirstName,
          lastName: formattedLastName,
          created: Timestamp.now(),
          timeZoneId,
          location: {
            city: locationData?.city || "",
            state: locationData?.region || "",
            zipCode: locationData?.postal || "",
            geohash: locationData?.geohash?.substring(0, 6) || "",
          },
        }),
        // Setting the member's private data
        setDoc(doc(db, "membersPrivate", user.uid), {
          avatarUrl: "",
          firstName: formattedFirstName,
          lastName: formattedLastName,
          email,
          phone: sanitizedPhone,
          announcements,
          autoPay: true,
          hasLoggedIn: true,
          notifications: notificationSettings,
          created: Timestamp.now(),
          timeZoneId,
          billing: {
            nextMembershipFeeDate: null,
            nextInvoiceDate: null,
            lastInvoiceDate: Timestamp.now(),
          },
          admin: {
            createdBy: user?.uid,
            sessionIds: [sessionId],
          },
          crm: {
            lastDiscountDate: Timestamp.now(),
            lastVisitDate: false,
            lastContactedDate: false,
            followUpDate: Timestamp.now(),
            contactedCount: 0,
            utmSource,
          },
          location: {
            city: locationData?.city || "",
            state: locationData?.region || "",
            zipCode: locationData?.postal || "",
            geohash: locationData?.geohash || "",
            lat: locationData?.latitude || 0,
            lng: locationData?.longitude || 0,
          },
        }),
        // Creating or updating the account document using the user's UID
        setDoc(
          doc(db, "accounts", user.uid),
          {
            roles: { [user.uid]: "owner" }, // Assigning the role of 'owner' to the user
          },
          { merge: true }
        ),
      ]);

      await createLog({
        collectionName: "members",
        idsArr: [user.uid],
        summary: `${formattedFirstName} created an account online`,
      });

      // Backend fn is special, must keep
      addAdminData(adminData)
        .then((result) => {
          console.log("Admin data function call result:", result);
        })
        .catch((error) => {
          console.error("Error calling addAdminData function:", error);
        });

      // Update the lead form and session data
      await handleLog();

      navigate(nextUrl);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    logSessionActivity(`Clicked back...`);
    setView("EMAIL");
  };

  return (
    <Box>
      {/* {referralParam && (
          <Alert
            severity="success"
            sx={{
              mx: { xs: -2, sm: -3 },
              mt: { xs: -3, sm: -3 },
              mb: 2,
              borderRadius: 0,
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            You have been gifted a free 1-hour visit.
          </Alert>
        )} */}
      {showBack && (
        <ButtonBase
          onClick={handleBack}
          disabled={loading}
          sx={{ color: "text.secondary", my: 1 }}
        >
          <KeyboardArrowLeft /> Back
        </ButtonBase>
      )}
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Complete your information to create your account.
      </Typography>
      {/* <Box sx={{ mx: { xs: -1, sm: -1 }, my: 2 }}>
          <CountdownBar
            showButton={false}
            showLabels={false}
            showZeros={false}
            showClose={false}
            clickEnabled={false}
            messageSize={"1rem"}
            timerSize={"1rem"}
          />
        </Box> */}
      <form onSubmit={handleSignUp} noValidate>
        <Stack direction="column" gap={2}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <NameField
              name={"firstName"}
              value={values.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              label={"First Name"}
            />
            <NameField
              name={"lastName"}
              value={values.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              label={"Last Name"}
            />
          </Box>
          <PhoneField
            phone={values.phone}
            error={errors.phone}
            handleChange={handleChange}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            name="password"
            type={showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              <span>
                By clicking Submit below and creating an account, I agree to the
                Linked Lives{" "}
                <a
                  href="/legal/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                .
              </span>
            </Typography>
            {errors.termsAccepted && (
              <div>
                <Typography variant="caption" color="error">
                  {errors.termsAccepted}
                </Typography>
              </div>
            )}
          </Box>

          <Button
            variant={
              email &&
              values.password &&
              values.firstName &&
              values.lastName &&
              values.termsAccepted &&
              values.phone
                ? "contained"
                : "contained" //"outlined"
            }
            type="submit"
            size="large"
            fullWidth
            disabled={loading}
            sx={{
              height: {
                xs: "56px",
                textTransform: "none",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default SignUpView;
