import React from "react";
import { Box, Typography } from "@mui/material";

const AboutMe = ({ employeeData }) => {
  const sections = [
    { title: "Personal Interests", content: employeeData?.personalInterests },
    { title: "Community Involvement", content: employeeData?.communityInvolvement },
    { title: "Mission Alignment", content: employeeData?.missionAlignment },
  ];

  return (
    <Box>
{sections.map(({ title, content }) =>
  content ? (
    <Box key={title} mb={2}>
      <Typography variant="body2" fontWeight={"bold"}>{title}</Typography>
      <Typography variant="body1">{content}</Typography>
    </Box>
  ) : null
)}

    </Box>
  );
};

export default AboutMe;