import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase"; // Adjust the path to your firebase config
import { getEmployeeStatus } from "../services/locationServices";

const useMemberData = (memberId) => {
  // loading states
  const [loading, setLoading] = useState(true);
  const [loadingPrivate, setLoadingPrivate] = useState(true);
  const [loadingVisits, setLoadingVisits] = useState(false);

  // To hold member data
  const [memberData, setMemberData] = useState(null);
  const [memberAdminData, setMemberAdminData] = useState(null);
  const [memberAccountData, setMemberAccountData] = useState(null);
  const [memberPrivateData, setMemberPrivateData] = useState(null);
  const [visitsData, setVisitsData] = useState({});
  const [employeesData, setEmployeesData] = useState(null);

  const defaultAddressKey = memberPrivateData?.defaultAddress;
  const address = memberAccountData?.addresses?.[defaultAddressKey];

  useEffect(() => {
    if (!memberId) return;

    setLoading(true);

    // Member data listener
    const unsubMember = onSnapshot(doc(db, "members", memberId), (doc) =>
      setMemberData({ id: doc.id, ...doc.data() })
    );

    // Admin data listener
    const unsubAdmin = onSnapshot(
      doc(db, "members", memberId, "admin", "data"),
      (doc) => {
        setMemberAdminData(doc.data());
      }
    );

    // Start loading for private data
    setLoadingPrivate(true);

    // Private data listener
    const unsubPrivate = onSnapshot(
      doc(db, "membersPrivate", memberId),
      (doc) => {
        setMemberPrivateData({ id: doc.id, ...doc.data() });

        // Stop loading after private data is fetched
        setLoadingPrivate(false);
      },
      (error) => {
        console.error("Error fetching private data:", error);
        // Stop loading if an error occurs
        setLoadingPrivate(false);
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubMember();
      unsubAdmin();
      unsubPrivate();
    };
  }, [memberId]);

  useEffect(() => {
    if (!memberPrivateData?.location?.geohash) {
      return;
    }

    const memberGeohash5 = memberPrivateData.location.geohash.slice(0, 5); // Use the member's precise geohash
    const memberLocation = memberPrivateData.location; // Use the member's precise geohash

    // Query Firestore using array-contains to match the geohash
    const q = query(
      collection(db, "employees"),
      where("status", "==", "active"),
      where("geohash5Arr", "array-contains", memberGeohash5) // Check if the geohash exists in geohash5Arr
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const employeesData = {};

      querySnapshot.forEach((doc) => {
        const employeeData = doc.data();

        const {
          inArea = false,
          futureAvailability,
          end,
          start,
        } = getEmployeeStatus(
          memberLocation?.lat,
          memberLocation?.lng,
          employeeData.areas,
          employeeData.availability
        );
        if (inArea) {
          employeesData[doc.id] = {
            ...employeeData,
            startDate: start,
            endDate: end,
          };
        }
      });

      // Update state with employeesData
      setEmployeesData(employeesData);
      console.log("useMemberData - employeesData: ", employeesData)
    });

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribe();
    };
  }, [memberPrivateData?.location?.geohash]);

  useEffect(() => {
    if (!memberAdminData?.accountId) return;

    // Member data listener
    const unsubAccount = onSnapshot(
      doc(db, "accounts", memberAdminData?.accountId),
      (doc) => {
        setMemberAccountData(doc.data());
      }
    );

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubAccount();
    };
  }, [memberAdminData?.accountId]);

  // Subscribe to visit data
  useEffect(() => {
    if (!memberId) return;

    setLoadingVisits(true);

    // Set up the listener only when the dialog opens
    const visitsQuery = query(
      collection(db, "visits"),
      where("member", "==", memberId)
    );

    const unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
      const visitsData = snapshot.docs
        .filter((doc) => doc.data().status !== "cancelled") // Exclude cancelled visits
        .reduce((acc, doc) => {
          // Use the doc.id as the key and the doc data as the value
          acc[doc.id] = {
            ...doc.data(),
            id: doc.id, // Include the id inside the visit data as well
          };
          return acc;
        }, {});

      console.log("Subscribing to visit data");
      setVisitsData(visitsData); // Ensure setVisitsData can handle an object structure
      setLoadingVisits(false);
    });

    // Cleanup on component unmount or memberId change
    return () => {
      console.log("Unsubscribing from visit data");
      unsubscribe();
    };
  }, [memberId]);

  return {
    memberData,
    memberAdminData,
    memberAccountData,
    memberPrivateData,
    visitsData,
    employeesData,
    loading,
    loadingVisits,
    loadingPrivate,
  };
};

export default useMemberData;
