import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import bbbBadge from "../../../assets/AB-seal-horz.svg";
import familyOwned from "../../../assets/family-owned.png";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import Logo from "../../../svgs/Logo";

const WebFooter = ({ pageName = "" }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"footer"`); // Pass the logMessage here

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const linkCategories = [
    {
      title: "Services",
      path: "/services",
      links: [
        { name: "Gardening", path: "/services" },
        { name: "Yard Work", path: "/services" },
        { name: "Cleaning", path: "/services" },
        { name: "Garage Help", path: "/services" },
        { name: "Organization", path: "/services" },
        { name: "Heavy Lifting", path: "/services" },
        { name: "Tech Help", path: "/services" },
        { name: "Decoration", path: "/services" },
        { name: "Errands", path: "/services" },
        { name: "Furniture", path: "/services" },
        { name: "Companion", path: "/services" },
        { name: "Odd Jobs", path: "/services" },
        // { name: "Pricing", path: "/taskmom/pricing" },
      ],
    },
    {
      title: "Seniors",
      path: "/",
      links: [
        { name: "Book a visit", path: "/dashboard" },
        { name: "My Dashboard", path: "/dashboard" },
        { name: "Login", path: "/login" },
        { name: "Services", path: "/services" },
        { name: "Reviews", path: "/reviews" },
        { name: "Pricing", path: "/pricing" },
        { name: "About Us", path: "/about" },
        { name: "Sign Up", path: "/get-started" },
        { name: "Spin to win", path: "/spin-to-win" },

        // { name: "Book a Visit", path: "/book-visit" },
        // { name: "Hiring", path: "/hiring" },
        // { name: "Stories", path: "/stories" },
        // { name: "Review", path: "/review" },
      ],
    },
    // {
    //   title: "Locations",
    //   path: "/locations",
    //   links: [
    //     { name: "Spokane, WA", path: "/get-started" },
    //     { name: "Santee, CA", path: "/get-started" },
    //     { name: "Anacortes, WA", path: "/get-started" },
    //     { name: "Vancouver, WA", path: "/get-started" },
    //     { name: "Battle Ground, WA", path: "/get-started" },
    //     { name: "Ridgefield, WA", path: "/get-started" },
    //     { name: "Camas, WA", path: "/get-started" },
    //     { name: "Amboy, WA", path: "/get-started" },
    //     { name: "Yacolt, WA", path: "/get-started" },
    //     { name: "La Center, WA", path: "/get-started" },
    //     { name: "Mount Vernon, WA", path: "/get-started" },
    //     { name: "Burlington, WA", path: "/get-started" },
    //     { name: "Sedro-Woolley, WA", path: "/get-started" },
    //     { name: "Oak Harbor, WA", path: "/get-started" },
    //     { name: "Coupeville, WA", path: "/get-started" },
    //   ],
    // },
    // {
    //   title: "\u00A0",
    //   path: "/locations",
    //   links: [
    //     { name: "Boise, ID", path: "/get-started" },
    //     { name: "Beaverton, OR", path: "/get-started" },
    //     { name: "Spokane Valley, WA", path: "/get-started" },
    //     { name: "Fairwood, WA", path: "/get-started" },
    //     { name: "Glenrose, WA", path: "/get-started" },
    //     { name: "Mukilteo, WA", path: "/get-started" },
    //     { name: "Lynnwood, WA", path: "/get-started" },
    //     { name: "Shoreline, WA", path: "/get-started" },
    //     { name: "Bonney Lake, WA", path: "/get-started" },
    //     { name: "Sumner, WA", path: "/get-started" },
    //     { name: "Prairie Ridge, WA", path: "/get-started" },
    //     { name: "South Hill, WA", path: "/get-started" },
    //     { name: "and more!", path: "/get-started" },
    //   ],
    // },
    {
      title: "Business",
      path: "/contact",
      links: [
        { name: "Contact Us", path: "/contact" },
        { name: "Hiring", path: "/hiring" },
        { name: "Local Partnerships", path: "/contact" },
        { name: "Terms of Service", path: "/legal/terms-of-service" },
        { name: "Privacy Policy", path: "/legal/privacy-policy" },
        // { name: "Pricing", path: "/taskmom/pricing" },
      ],
    },
    // {
    //   title: "Churches",
    //   path: "/churches",
    //   links: [{ name: "Start a Branch", path: "/pricing" }],
    // },
    // {
    //   title: "Services",
    //   path: "/services",
    //   links: [
    //     { name: "Organizing", path: "/services/organizing" },
    //     { name: "Child care", path: "/services/childcare" },
    //     { name: "Gardening", path: "/services/gardening" },
    //     { name: "DIY Projects", path: "/services/diy-projects" },
    //     { name: "Tech Help", path: "/services/tech-help" },
    //     { name: "Household Chores", path: "/services/household-chores" },
    //     { name: "Decoration", path: "/services/decoration" },
    //     { name: "About", path: "/services/organizing" },
    //   ],
    // },
  ];

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: theme.palette.primary.darkest,
        color: "white",
        padding: "24px",
        position: "relative", // Ensures it's part of the normal flow
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            <Box mb={2} width="200px">
              <Logo color="#fff" />
            </Box>

            <Grid container direction="row" spacing={1}>
              {linkCategories.map((category, idx) => (
                <Grid item xs={6} sm={2} key={idx}>
                  <Stack gap={1}>
                    <Typography fontWeight="700" fontSize={"1.1rem"}>
                      <Link
                        href={category.path}
                        color="inherit"
                        underline="none"
                      >
                        {category.title}
                      </Link>
                    </Typography>
                    {category.links.map((link, linkIdx) => (
                      <Typography variant="body2" key={linkIdx}>
                        <Link href={link.path} color="inherit" underline="none">
                          {link.name}
                        </Link>
                      </Typography>
                    ))}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={!isMobile ? "flex-end" : "center"}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Contact Us
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Questions? We are available to help!
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <MailOutlineIcon sx={{ mr: 1 }} />
                  <Link href="mailto:info@ourlinkedlives.com" color="inherit">
                    info@ourlinkedlives.com
                  </Link>
                </Box>
                <Link href="tel:+13603366344" color="inherit" underline="none">
                  <Button
                    startIcon={<PhoneIcon />}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                      borderColor: "#fff",
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    Call 1-(833) 433-0363
                  </Button>
                </Link>
                <Box
                  component="img"
                  src={bbbBadge}
                  sx={{ width: "200px", height: "auto", mt: 2 }}
                />
                <Box
                  component="img"
                  src={familyOwned}
                  sx={{ width: "150px", height: "auto", mt: 2 }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, borderWidth: "2px" }} />

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              © {new Date().getFullYear()} Linked Lives, LLC. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-end">
              <Link
                href="https://www.instagram.com/ourlinkedlives/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  color="white"
                  sx={{ color: "#fff" }}
                  aria-label="Instagram"
                  size="small"
                >
                  <InstagramIcon />
                </IconButton>
              </Link>

              <Link
                href="https://www.facebook.com/profile.php?id=100086284501288"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  color="white"
                  sx={{ color: "#fff" }}
                  aria-label="Facebook"
                  size="small"
                >
                  <FacebookIcon />
                </IconButton>
              </Link>

              <Link
                href="https://youtube.com/@LinkedLives"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  color="white"
                  sx={{ color: "#fff" }}
                  aria-label="YouTube"
                  size="small"
                >
                  <YouTubeIcon />
                </IconButton>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WebFooter;
