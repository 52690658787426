import { Box, Typography } from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import VisitPostCard from "../reviews/VisitPostCard";
import ReviewCard from "./ReviewCard";

const HelperReviews = ({ onLoaded }) => {
  const { helperId } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Fetch the 3 most recent reviews with sentiment 3 and confirmed status.
        const q = query(
          collection(db, "visits"),
          where("sentiment", "==", 3),
          orderBy("start", "desc"),
          limit(3)
        );
        const snapshot = await getDocs(q);
        const fetchedReviews = [];
        snapshot.forEach((doc) => {
          fetchedReviews.push({ id: doc.id, ...doc.data() });
        });
        setReviews(fetchedReviews);
        console.log("Fetched reviews:", fetchedReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setReviews([]);
      } finally {
        if (onLoaded) onLoaded();
      }
    };

    fetchReviews();
  }, [helperId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        alignItems: "center",
      }}
    >
      {reviews.length > 0 ? (
        reviews.map((review) => (
          <Box
            key={review.id}
            sx={{
              width: 540,
              height: 540,
              // border: "1px solid",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%", height: "auto" }}>
              <ReviewCard visit={review} noCard={true} fontScale={1.25} />
            </Box>
          </Box>
        ))
      ) : (
        <Typography align="center" color="text.secondary" sx={{ p: 3 }}>
          No reviews
        </Typography>
      )}
    </Box>
  );
};

export default HelperReviews;
