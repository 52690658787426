import { keyframes } from "@emotion/react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // updated import

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

import { Alert, Box, Button, Typography, useTheme } from "@mui/material";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import FooterSimple from "../../components/FooterSimple";
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from "../../constants";
import Logo from "../../svgs/Logo";

// Define our keyframes for the animation
const fadeInMoveDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InvitesPage = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const theme = useTheme();

  // Function to simulate progress
  const simulateProgress = () => {
    setProgress((oldProgress) => {
      if (oldProgress === 90) {
        return 90; // Pause at 90%
      }
      const diff = Math.random() * 20; // Increase by a random amount
      return Math.min(oldProgress + diff, 90); // Do not exceed 90%
    });
  };

  // Simulating a loading progress bar over 4 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      simulateProgress();
    }, 500); // 500ms for smoother transition

    // After 4 seconds, pause the progress bar
    setTimeout(() => {
      clearInterval(timer);
    }, 4000); // 4 seconds

    return () => {
      clearInterval(timer); // Clean up the interval on component unmount
    };
  }, []);

  useEffect(() => {
    const validateAndSignIn = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!token) {
        setError("Token is missing in the URL."); // Display error message
        setLoading(false); // Stop loading
        return;
      }

      try {
        // Call your Firebase Function for token validation
        const functions = getFunctions();
        const validateLoginLink = httpsCallable(functions, "validateLoginLink");

        const { data: validationResponse } = await validateLoginLink({
          token,
        });

        // Handle any errors or issues from your function's response
        if (validationResponse.status !== "success") {
          throw new Error(validationResponse.message); // This error message should come from your function's response
        }

        // Sign in the user with the custom token
        const auth = getAuth();
        await signInWithCustomToken(auth, validationResponse.customToken);
        // Build a new query string with all params except `token`
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete("token");

        const queryString = queryParams.toString();
        navigate(`/dashboard${queryString ? `?${queryString}` : ""}`);
        
      } catch (err) {
        // Extract the detailed error message sent from the Firebase function
        let errorMessage = err.message || "An unexpected error occurred.";

        // Set this message in your component's state to display it
        setError(errorMessage);
        setLoading(false);
      }
    };

    validateAndSignIn(); // Call the function when the component mounts
  }, [token, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // Changed from 'center' to 'space-between' to push footer to the bottom
      alignItems="center"
      minHeight="100vh" // Ensures minimum height is full viewport height
      bgcolor="background.default"
    >
      <Box
        alignSelf="flex-start"
        padding={2}
        style={{ width: "150px" }} // Explicitly giving the container a size
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <Logo style={{ width: "100%", height: "100%" }} />{" "}
        </Link>
        {/* Ensuring the logo takes up all the available space */}
      </Box>

      <Box // Container for the progress bar or error message, centered
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="90%" // Full width
        maxWidth="600px"
        paddingX={2} // Some horizontal padding
        textAlign="center" // Center the text elements
      >
        {loading ? (
          <>
            <Typography variant="h6" gutterBottom>
              Signing you in...
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 10,
                transition: "transform 0.5s ease-in-out", // This line ensures the progress bar's visual update will transition smoothly
                borderRadius: 5,
                width: "100%", // Ensures the progress bar takes full width of its container
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor:
                    theme.palette.grey[
                      theme.palette.mode === "light" ? 200 : 800
                    ],
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                },
              }}
            />
          </>
        ) : (
          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3, // Uniform padding to avoid cramped components
              maxWidth: 400, // Avoids excessively wide elements on larger screens
              margin: "auto", // Centers the box
              textAlign: "center", // Makes text more readable

              opacity: 0, // initial state of opacity, as animation starts from 0% keyframe
              animation: `${fadeInMoveDown} 1s forwards`, // 'forwards' makes it retain the end state
            }}
          >
            <Typography variant="h6" component="h2">
              Oops, something went wrong!
            </Typography>
            <Alert
              severity="error"
              // variant="outlined"
              sx={{
                margin: 2,
                width: "auto",
                display: "inline-flex",
              }}
            >
              {error}
            </Alert>
            <Typography variant="body2">
              We're here to help, please reach out and we'll make it right.
            </Typography>
            <Box
              sx={{
                marginTop: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column", // ensures the buttons are stacked
                gap: 2, // provides consistent spacing between the buttons
              }}
            >
              {/* Call Support Button */}
              <Button
                disableElevation
                href={`tel:${SUPPORT_PHONE_NUMBER}`}
                variant="contained"
                color="primary"
                startIcon={<PhoneIcon />} // adds a phone icon before the button label
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center", // centers the button label with the icon
                  alignItems: "center",
                }}
              >
                Call Support
              </Button>

              {/* Email Support Button */}
              <Button
                disableElevation
                href={`mailto:${SUPPORT_EMAIL}`}
                variant="outlined"
                color="primary"
                startIcon={<EmailIcon />} // adds an email icon before the button label
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center", // centers the button label with the icon
                  alignItems: "center",
                }}
              >
                Email Support
              </Button>
            </Box>
            <Button
              component={Link}
              to="/"
              variant="text" // More prominent button style
              color="primary"
              sx={{ marginTop: 2, width: "100%" }} // Full-width button is easier to interact with
            >
              Return to Home Page
            </Button>
          </Box>
        )}
      </Box>

      <FooterSimple />
    </Box>
  );
};

export default InvitesPage;
