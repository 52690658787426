import { WavingHand } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScrollAvatars from "../../../components/popup/InfiniteScrollAvatars";
import ServicesGrid from "./ServicesGrid";

const ServicesOverviewSection = ({ pageName = "" }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        py: { xs: 4, sm: 4 },
        backgroundColor: "hsla( 190, 100%, 50%, .1)",
      }}
    >
      <Typography
        variant="h2"
        align="center"
        sx={{ mb: { xs: 1, sm: 1 }, fontSize: { xs: "42px", sm: "48px" } }}
      >
        Your Household Helper
      </Typography>
      <Typography variant="h6" fontWeight={400} sx={{ textAlign: "center" }}>
        {/* One step closer to peace-of-mind. */}
        Eagle Scouts to student leaders, varsity athletes to future engineers...
      </Typography>
      <InfiniteScrollAvatars />
      <div style={{ height: "16px" }} />

      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mb: { xs: 2, sm: 2 } }}>
          All of our exceptional young adult helpers are hand picked for their
          work ethic, diligence, respect, and attention to detail. You will be
          delighted to meet each and every one of them and learn about their unique
          stories!
        </Typography>
        <ServicesGrid expanded={false} listOnly={true} pageName={pageName} />
        <div style={{ height: "24px" }} />
        <Button
          variant="contained"
          startIcon={<WavingHand />}
          sx={{
            height: "48px",
            width: { xs: "100%", sm: "250px", textTransform: "none" },
          }}
          onClick={() => navigate("/helpers")}
        >
          Meet our Helpers
        </Button>
      </Container>
    </Box>
  );
};

export default ServicesOverviewSection;
