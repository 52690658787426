import "chart.js/auto"; // necessary to auto-register the charts
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

// Subcomponents (which we'll build next)
import {
  AddCircle,
  AutoMode,
  Ballot,
  CheckCircle,
  EmojiEvents,
  EventAvailable,
  GroupAdd,
  PersonAdd,
  QuestionAnswerOutlined,
  Star,
  Timer,
  TouchApp,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import ContentHeader from "../../dashboard/ContentHeader";
import Boosts from "./Boosts";
import LevelUpDialog from "./LevelUpDialog";
import MultiplierDialog from "./MultiplierDialog";
import PointsBar from "./PointsBar";
import PointsDialog from "./PointsDialog";
import StreaksV2 from "./StreaksV2";

const progressTest = {
  // currentLevel: 8,
  // currentPoints: 800,
  boosts: {
    openVisit: {
      created: Timestamp.fromDate(new Date("2024-10-19")), // When the modifier was applied
    },
    employeeReferral: {
      created: Timestamp.fromDate(new Date("2024-10-1")), // Applied date
    },
    defaultAvailability: {
      created: Timestamp.fromDate(new Date("2024-10-19")),
    },
    aboveAndBeyond: {
      created: Timestamp.fromDate(new Date("2024-10-19")),
    },
    memberReferral: {
      created: Timestamp.fromDate(new Date("2024-10-19")),
    },
    starReviews: {
      created: null,
      // created: Timestamp.fromDate(new Date("2024-10-19")),
    },
  },
  streaks: {
    visitsAccepted: {
      count: 4,
    },
    respondingEarly: {
      count: 17,
    },
    appCheckIn: {
      count: 40,
    },
    meetingAttendance: {
      count: 5,
    },
  },
};

const pointsData = [
  { date: Timestamp.fromDate(new Date("2024-10-12")), points: 750 },
  { date: Timestamp.fromDate(new Date("2024-10-13")), points: 100 },
  { date: Timestamp.fromDate(new Date("2024-10-14")), points: 1350 },
  { date: Timestamp.fromDate(new Date("2024-10-15")), points: 550 },
  { date: Timestamp.fromDate(new Date("2024-10-16")), points: 100 },
  { date: Timestamp.fromDate(new Date("2024-10-17")), points: 1550 },
  { date: Timestamp.fromDate(new Date("2024-10-18")), points: 2150 },
  { date: Timestamp.fromDate(new Date("2024-10-19")), points: 250 },
  { date: Timestamp.fromDate(new Date("2024-10-20")), points: 1550 },
  { date: Timestamp.fromDate(new Date("2024-10-21")), points: 500 },
  { date: Timestamp.fromDate(new Date("2024-10-22")), points: 600 },
];

// multiplierMap
export const badgesMap = {
  // Streaks

  visitsAccepted: {
    type: "streaks",
    threshold: 3,
    multiplier: 5,
    increment: 2,
    icon: CheckCircle,
    color: "#4CAF50", // Green
    description: "Visits Accepted",
    body: "Grow this streak each time you accept a visit. This encourages you to manage your availability properly, so that you rarely need to decline a visit.",
    cancelCriteria: "declining a visit",
  },
  appCheckIn: {
    type: "streaks",
    threshold: 1,
    multiplier: 5,
    increment: 1,
    icon: TouchApp,
    color: "#2196F3", // Blue
    description: "App Check-In",
    body: "Grow this streak by checking your app at least once a week. Staying active ensures you never miss any visits or opportunities.",
    cancelCriteria: "missing a week",
  },
  respondingEarly: {
    type: "streaks",
    threshold: 3,
    multiplier: 4,
    increment: 1,
    icon: Timer,
    color: "#FF9800", // Orange
    description: "Responded Early",
    body: "Grow this streak by accepting or declining new visits within 48 hours of creation, or at least 2 weeks before the visit's start date. This demonstrates timeliness and proactivity, providing peace of mind to our members.",
    cancelCriteria: "responding after 2 days",
  },
  starReviews: {
    type: "boosts",
    multiplier: 10,
    totalDuration: 14,
    icon: Star, // Star Icon
    color: "#FFD700", // Gold
    description: "5-Star Review",
    body: "Earn this boost by receiving a 5-star review from a member. Outstanding reviews reflects excellent performance. Keep up the great work to build your reputation!",
  },

  // Boosts
  defaultAvailability: {
    type: "boosts",
    multiplier: 15,
    totalDuration: null, // Indefinite
    icon: EventAvailable,
    color: "#E91E63", // Vibrant Pink
    description: "Weekly Availability",
    body: "Earn this boost by setting your weekly default availability. When you have availability for the weeks to come, it gives members more options to find the time that works best for everyone.",
    cancelCriteria: "no default availability",
  },
  meetingAttendance: {
    type: "boosts",
    multiplier: 5,
    totalDuration: 35, // Total duration in days
    icon: QuestionAnswerOutlined, // Calendar Icon
    color: "#9C27B0", // Purple
    description: "Q&A Attendance",
    body: "Earn this boost by attending our monthly Q&A meetings. It's a great way to stay connected, ask questions, and be informed of any updates.",
  },
  polls: {
    type: "boosts",
    multiplier: 5,
    totalDuration: 14, // Total duration in days
    icon: Ballot,
    color: "#7511f7",
    description: "Polls Participation",
    body: "Earn this boost by giving your feedback when polls come up. Participating in polls is a great way to share your feedback on new ideas.",
  },
  // New Boost Entry
  aboveAndBeyond: {
    type: "boosts",
    multiplier: 10,
    totalDuration: 28, // Total duration in days
    icon: EmojiEvents, // Trophy Icon
    color: "#FFD700", // Gold
    description: "Above and Beyond",
    body: "Awarded by management for stepping up and helping in a big way. Your exceptional effort and dedication have not gone unnoticed. Keep up the great work!",
  },
  openVisit: {
    type: "boosts",
    multiplier: 10,
    totalDuration: 21, // Total duration in days
    icon: AddCircle,
    color: "#00BCD4", // Cyan
    description: "Open Visit Pickup",
    body: "Earn this boost by picking up an open visit. Picking up visits is a great way to get extra work outside of your regular hours.",
  },

  employeeReferral: {
    type: "boosts",
    multiplier: 15,
    totalDuration: 28, // Total duration in days
    icon: GroupAdd,
    color: "#61cd18", // More Vibrant Light Green
    description: "Helper Referral",
    body: "Earn this boost by referring a new helper. Once they join, this boost will be applied. Since you know what it takes, we highly value your recommendations for new hires.",
  },

  // New Boost Entry
  memberReferral: {
    type: "boosts",
    multiplier: 10,
    totalDuration: 28, // Total duration in days
    icon: PersonAdd, // Member Add Icon
    color: "#3F51B5", // Indigo
    description: "Member Referral",
    body: "Earn this boost by referring a new member. Your contribution helps grow our community, and we value your efforts in bringing in new members!",
  },
};

export const getActiveBadges = (progress) => {
  const badges = [];
  Object.entries(badgesMap).forEach(([key, data]) => {
    if (data.type === "streaks") {
      const count = progress?.streaks?.[key]?.count || 0;
      if (count >= data.threshold) {
        badges.push(key);
      }
    } else if (data.type === "boosts") {
      const boost = progress?.boosts?.[key];
      if (boost && isBoostActive(boost.created, data.totalDuration)) {
        badges.push(key);
      }
    }
  });
  return badges;
};

export const renderIcon = ({
  key,
  multiplier,
  glow = true,
  zIndex = null,
  onClick = () => {},
  active = true,
  size = 60,
}) => {
  console.log("renderIcon: ", [key, multiplier, badgesMap]);

  const IconComponent = badgesMap[key]?.icon; // Get the icon component
  const backgroundColor = badgesMap[key]?.color || "#e0e0e0"; // Get background color or default to gray

  return (
    <Box
      onClick={() => onClick(key)} // Add click handler
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        cursor: "pointer",
        overflow: "hidden",
        backgroundColor: active ? backgroundColor : "#c0c0c0",
        ...(glow && { boxShadow: `0px 0px 15px 3px ${backgroundColor}` }),

        ...(zIndex !== null && { zIndex: zIndex }), // Conditionally add zIndex if it's not null
      }}
    >
      {IconComponent && (
        <IconComponent style={{ fontSize: (size * 2) / 3, color: "#fff" }} />
      )}
    </Box>
  );
};

export const calculateStreakMultiplier = (streak, streakMap) => {
  const { count } = streak;

  if (!streakMap) return 0;

  console.log("streakMap: ", streakMap);
  const { threshold, multiplier, increment } = streakMap;

  // If the count hasn't met the threshold, streak isn't active
  if (count < threshold) return 0;

  const levelsReached = Math.min(Math.floor((count || 0) / threshold), 5);

  // Final multiplier is the base multiplier plus the number of times the increment is applied
  return multiplier + (levelsReached - 1) * increment;
};

// Helper function to calculate total multiplier (streaks + active multipliers)
export const calculateTotalMultiplier = (progress) => {
  let total = 0; // Base multiplier

  // Add streak multipliers if streak is active
  Object.entries(progress?.streaks || {}).forEach(([streakKey, streak]) => {
    const streakMap = badgesMap[streakKey]; // Access the badgesMap for this specific streak
    const streakMultiplier = calculateStreakMultiplier(streak, streakMap);
    console.log("streakKey, points: ", [streakKey, streakMultiplier]);
    total += streakMultiplier; // Add streak multiplier if active
  });

  // Add active multipliers based on created timestamp and totalDuration
  Object.entries(progress?.boosts || {}).forEach(([boostKey, boostData]) => {
    const boostMap = badgesMap[boostKey]; // Access the badgesMap for this specific boost
    if (isBoostActive(boostData.created, boostMap.totalDuration)) {
      console.log("boostKey, points: ", [boostKey, boostMap.multiplier]);
      total += boostMap.multiplier; // Add boost multiplier if active
    }
  });

  return total;
};

// Check if a boost is still active based on its created date and total duration
export const isBoostActive = (created, totalDuration) => {
  if (created === null) return false;

  if (!totalDuration || !created?.seconds) return true; // If no total duration, it's always active

  const createdTime = DateTime.fromSeconds(created.seconds);
  const now = DateTime.now();
  const durationInDays = now.diff(createdTime, "days").days;

  return durationInDays < totalDuration; // Active if duration has not passed
};

const ProgressTab = ({
  showHeader = true,
  progress = {},
  currentLevel = 1,
  employeeId = "",
  employeeName = "",
  avatarUrl = "",
  hourlyRate = 17,
}) => {
  console.log("ProgressTab init");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const [dialogPointsOpen, setDialogPointsOpen] = useState(false);
  const [totalMultiplier, setTotalMultiplier] = useState(
    calculateTotalMultiplier(progress)
  ); // Start with base 1x multiplier

  const [levelUpOpen, setLevelUpOpen] = useState(false);

  const currentPoints = progress?.currentPoints || 0;
  console.log("currentPoints: ", currentPoints);

  // Calculate points within the current level (each level is 1000 points)
  const pointsInCurrentLevel = currentPoints - 1000 * (currentLevel - 1);
  console.log("pointsInCurrentLevel: ", pointsInCurrentLevel);
  const progressPercentage = Math.min((pointsInCurrentLevel / 1000) * 100, 100); // Percent filled
  console.log("progressPercentage: ", progressPercentage);

  const showLevelUp = pointsInCurrentLevel >= 1000;

  useEffect(() => {
    setTotalMultiplier(calculateTotalMultiplier(progress));
  }, [progress?.streaks, progress?.boosts]);

  const handleOpenPointsDialog = () => {
    setDialogPointsOpen(true);
  };

  const handleClosePointsDialog = () => {
    setDialogPointsOpen(false);
  };

  // Function to handle opening the dialog with selected data
  const handleOpenDialog = (key) => {
    console.log("handleOpenDialog key: ", key);
    setDialogOpen(true);
    setSelectedKey(key);
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenLevelUp = (event) => {
    event.stopPropagation(); // Prevent event from propagating to other listeners
    setLevelUpOpen(true);
  };

  const handleCloseLevelUp = () => {
    setLevelUpOpen(false);
  };

  return (
    <>
      {showHeader && <ContentHeader title={"Progress"} />}
      <Box
        className="progress-tab-container p-6"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        {/* Points Bar */}
        <div className="mb-4">
          <PointsBar
            showHeader={showHeader}
            progress={progress}
            totalMultiplier={totalMultiplier}
            currentLevel={currentLevel}
            pointsInCurrentLevel={pointsInCurrentLevel}
            progressPercentage={progressPercentage}
            handleOpenLevelUp={handleOpenLevelUp}
            showLevelUp={showLevelUp}
            onClick={handleOpenPointsDialog}
            avatarUrl={avatarUrl}
          />
        </div>

        {/* Multiplier Overview*/}
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", sm: "center" },
            gap: 2,
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column" },
            backgroundColor: "white",
            width: "auto",
            boxSizing: "border-box",
            // padding: 2,
            // borderRadius: "15px",
            // border: `1px solid rgba(0, 0, 0, 0.12)`,
            // boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          }}
        >
          {/* <HomeCardHeader
            title={"Badges"}
            // subtitle={"Grow streaks and unlock boosts for extra points"}
            IconComponent={LocalPolice}
            hideIcon={true}
          /> */}
          {/* <Typography
              variant="h6"
              align="center"
              sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" }, color: "#333" }}
            >
              Multipliers
            </Typography> */}
          {/* Icons for Active Multipliers */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
              justifyContent: { xs: "space-around", sm: "center" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">Badges</Typography>
              <Typography>Unlock badges to earn more points.</Typography>
            </Box>
            <StreaksV2
              streaks={progress.streaks}
              map={badgesMap}
              onClick={handleOpenDialog}
            />
            <Boosts
              boosts={progress.boosts}
              map={badgesMap}
              onClick={handleOpenDialog}
            />
          </Box>
        </Box>

        {/* Detail Dialog for showing additional info */}
        <MultiplierDialog
          onClose={handleCloseDialog}
          selectedKey={selectedKey}
          streaks={progress.streaks}
          boosts={progress.boosts}
          map={badgesMap}
          open={dialogOpen}
        />
      </Box>
      <PointsDialog
        open={dialogPointsOpen}
        onClose={handleClosePointsDialog}
        currentLevel={currentLevel}
        totalMultiplier={totalMultiplier}
        pointsInCurrentLevel={pointsInCurrentLevel}
        showLevelUp={showLevelUp}
        handleOpenLevelUp={handleOpenLevelUp}
        hourlyRate={hourlyRate}
      />
      <LevelUpDialog
        open={levelUpOpen}
        onClose={handleCloseLevelUp}
        employeeId={employeeId}
        employeeName={employeeName}
        pointsInCurrentLevel={pointsInCurrentLevel}
      />
    </>
  );
};

export default ProgressTab;
