import {
  collection,
  endAt,
  endBefore,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import tzlookup from "tz-lookup";
import { db } from "../firebase";

import { geohashForLocation, geohashQueryBounds } from "geofire-common";
import geohash from "ngeohash";
const EARTH_RADIUS_MILES = 3958.8; // Earth's radius in miles

const BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
const BITS = [16, 8, 4, 2, 1];
const MIN_DATE = DateTime.fromISO("0001-01-01T00:00:00.000Z");
const MAX_DATE = DateTime.fromISO("9999-12-31T23:59:59.999Z");

export function decodeGeoHash(geohash) {
  const bounds = decodeGeoHashToBounds(geohash);
  if (bounds.length === 0) return [0, 0];
  const lat = (bounds.sw.lat + bounds.ne.lat) / 2;
  const lng = (bounds.sw.lng + bounds.ne.lng) / 2;

  return [lat, lng];
}

export const getCityStateFromZip = async (zipCode) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;
  console.log("Request URL:", url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log("data: ", data);

    if (data.status === "OK") {
      const addressComponents = data.results[0].address_components;

      // Initialize variables to hold city and state
      let city = "";
      let state = "";

      // Extract city and state from address components
      for (let component of addressComponents) {
        if (component.types.includes("locality")) {
          city = component.long_name; // City name
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name; // State abbreviation
        }
      }

      if (city && state) {
        return `${city}, ${state}`;
      }

      return "City or state not found";
    } else {
      return zipCode;
    }
  } catch (error) {
    console.error("Error fetching city:", error);
    return zipCode;
  }
};

export const getLocationDetailsFromPartialAddress = async (address) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  // Ensure the address contains at least one valid field
  const { line1, line2, city, state, zipCode } = address || {};
  if (!line1 && !line2 && !city && !state && !zipCode) {
    throw new Error("Address must contain at least one field");
  }

  // Construct the query string based on available address fields
  const query = [line1, line2, city, state, zipCode]
    .filter(Boolean) // Remove any empty fields
    .join(", ");

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    query
  )}&key=${apiKey}`;
  console.log("Request URL:", url);

  try {
    const response = await fetch(url);
    const data = await response.json();

    console.log("data: ", data);

    if (data.status === "OK") {
      const location = data.results[0].geometry.location; // Extract lat/lng
      const lat = location.lat;
      const lng = location.lng;
      const geohash = geohashForLocation([lat, lng]);

      return { lat, lng, geohash };
    } else {
      console.error("Geocoding API error:", data.status);
      return null; // Return null if the API cannot resolve the address
    }
  } catch (error) {
    console.error("Error fetching geocoding data:", error);
    return null;
  }
};

export const fetchLocationDetailsFromBounds = async (boundsArray) => {
  if (!boundsArray || boundsArray.length === 0) {
    throw new Error("Bounds array is required.");
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  // Calculate the center of the bounds
  const calculateCenter = (bounds) => {
    const latitudes = bounds.map((point) => point.lat);
    const longitudes = bounds.map((point) => point.lng);
    const latCenter = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
    const lngCenter = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
    return { lat: latCenter, lng: lngCenter };
  };

  const center = calculateCenter(boundsArray);

  // Build the URL for reverse geocoding
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status !== "OK") {
      throw new Error(`Geocoding API error: ${data.status}`);
    }

    // Parse the city, state, and zip code from the API response
    const addressComponents = data.results[0].address_components;
    let city = "";
    let state = "";
    let zipCode = "";

    for (let component of addressComponents) {
      if (component.types.includes("locality")) {
        city = component.long_name; // City name
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.short_name; // State abbreviation
      }
      if (component.types.includes("postal_code")) {
        zipCode = component.long_name; // Zip code
      }
    }

    // Fallback values if any of the details are missing
    city = city || "Unknown City";
    state = state || "Unknown State";
    zipCode = zipCode || "00000";

    const { timeZone: timeZoneId } = getTimeZone(center.lat, center.lng);
    console.log("locationServices timeZoneId: ", timeZoneId);

    // Optionally, generate geohash
    const geohash = geohashForLocation([center.lat, center.lng]);

    return {
      city,
      state,
      zipCode,
      geohash6: geohash.substring(0, 6),
      lat: center.lat,
      lng: center.lng,
      timeZoneId,
    };
  } catch (error) {
    console.error("Error fetching location details:", error);
    throw new Error("Error fetching location details.");
  }
};

export const getTimeZone = (latitude, longitude) => {
  try {
    const timeZone = tzlookup(latitude, longitude); // Get timezone using tz-lookup
    const currentTime = moment.tz(timeZone).format(); // Get the current time in the timezone
    return { timeZone, currentTime };
  } catch (error) {
    console.error("Error fetching timezone:", error);
    throw new Error("Unable to determine timezone.");
  }
};

function decodeGeoHashToBounds(geohash) {
  if (!geohash) return [];

  let isEven = true;
  const lat = [-90.0, 90.0];
  const lon = [-180.0, 180.0];

  for (let i = 0; i < geohash.length; i++) {
    const c = geohash[i];
    const cd = BASE32.indexOf(c);
    for (let j = 0; j < 5; j++) {
      const mask = BITS[j];
      if (isEven) {
        refineInterval(lon, cd, mask);
      } else {
        refineInterval(lat, cd, mask);
      }
      isEven = !isEven;
    }
  }
  const latCenter = (lat[0] + lat[1]) / 2;
  const lonCenter = (lon[0] + lon[1]) / 2;

  return {
    sw: { lat: lat[0], lng: lon[0] },
    ne: { lat: lat[1], lng: lon[1] },
    center: { lat: latCenter, lng: lonCenter },
  };
}

function refineInterval(interval, cd, mask) {
  if (cd & mask) {
    interval[0] = (interval[0] + interval[1]) / 2;
  } else {
    interval[1] = (interval[0] + interval[1]) / 2;
  }
}

const stateAbbreviations = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const convertStateNameToAbbreviation = (input) => {
  // Check if input is a valid string, return it as is if not
  if (typeof input !== "string" || input.trim() === "") {
    return input;
  }

  const inputUpperCase = input.toUpperCase();

  const stateNames = Object.keys(stateAbbreviations);
  const stateAbbreviationValues = Object.values(stateAbbreviations);

  // Check if input is already an abbreviation
  if (stateAbbreviationValues.includes(inputUpperCase)) {
    return inputUpperCase;
  }

  // Convert full state name to abbreviation
  const foundState = stateNames.find(
    (stateName) => stateName.toUpperCase() === inputUpperCase
  );

  return foundState ? stateAbbreviations[foundState] : input;
};

// Function to get members in an area
export const getMembersInArea = async (
  { geohash = null, center = null },
  radiusInMiles = 5
) => {
  let searchCenter;

  console.log("getMembersInArea - center: ", center);

  if (geohash) {
    searchCenter = decodeGeoHash(geohash);
    console.log("using geohash");
  } else if (center && center.lat && center.lng) {
    searchCenter = [center.lat, center.lng];
    console.log("using center");
  } else {
    console.error("Either geohash or center with lat/lng is required");
    return [];
  }

  // console.log("searchCenter: ", searchCenter);

  const radiusInM = radiusInMiles * 1609.34; // Convert miles to meters
  const bounds = geohashQueryBounds(searchCenter, radiusInM);

  // console.log("bounds: ", bounds);

  let membersLocations = [];

  try {
    for (const [start, end] of bounds) {
      const q = query(
        collection(db, "membersPrivate"), // Adjust according to your collection name
        // where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const querySnapshot = await getDocs(q);

      // console.log("querySnapshot: ", querySnapshot)

      querySnapshot.forEach((doc) => {
        const memberData = doc.data();
        let coords = decodeGeoHash(memberData?.location?.geohash);
        let memberCoordinates = {
          lat: memberData?.location?.lat,
          lng: memberData?.location?.lng,
          // lat: coords[0],
          // lng: coords[1],
          status: memberData?.status || "active",
        };
        // console.log("memberCoordinates: ", memberCoordinates)

        membersLocations.push(memberCoordinates);
      });
    }

    return membersLocations;
  } catch (error) {
    console.error("Error getting members: ", error);
    return [];
  }
};

// Utility function to fetch employees in the area based on lat/lng
export const getActiveEmployeesAtLocation = async (
  lat,
  lng,
  radiusInMiles = 10
) => {
  const memberGeohash5 = geohashForLocation([lat, lng], 5); // Convert lat/lng to geohash precision 5
  const memberCoordinates = [lat, lng];
  const employeesData = {};

  // Query Firestore using array-contains to match the geohash
  const q = query(
    collection(db, "employees"),
    where("status", "==", "active"),
    where("geohash5Arr", "array-contains", memberGeohash5) // Check if the geohash exists in geohash5Arr
  );

  try {
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const employeeData = doc.data();

      // new way
      const {
        inArea = false,
        futureAvailability,
        end,
        start,
      } = getEmployeeStatus(
        lat,
        lng,
        employeeData.areas,
        employeeData.availability
      );
      if (
        inArea &&
        typeof start !== "undefined" &&
        typeof end !== "undefined"
      ) {
        employeesData[doc.id] = {
          ...employeeData,
          startDate: start,
          endDate: end,
        };
      }
    });
  } catch (error) {
    console.error("Error fetching employees:", error);
  }

  return employeesData;
};

export function groupGeohashBounds(geohashes, prefixLength = 4) {
  // Sort the geohashes lexicographically
  const sortedGeohashes = geohashes.sort();

  const condensedBounds = [];
  let start = sortedGeohashes[0];
  let previous = sortedGeohashes[0];
  let currentPrefix = start?.substring(0, prefixLength);

  for (let i = 1; i < sortedGeohashes.length; i++) {
    const currentGeohash = sortedGeohashes[i];
    const currentGeohashPrefix = currentGeohash.substring(0, prefixLength);

    if (currentGeohashPrefix === currentPrefix) {
      // Continue the current range
      previous = currentGeohash;
    } else {
      // Save the current range and start a new one
      condensedBounds.push([start, previous]);
      start = currentGeohash;
      previous = currentGeohash;
      currentPrefix = currentGeohashPrefix;
    }
  }

  // Add the last range
  condensedBounds.push([start, previous]);

  return condensedBounds;
}

export const fetchMembersWithGeohash5Arr = async (employeeData) => {
  const {
    geohash5Arr = [],
    areas = {},
    availability = {},
  } = employeeData || {};

  const condensedBounds = groupGeohashBounds(geohash5Arr);
  const allMembers = new Map();

  for (const [start, end] of condensedBounds) {
    // Query Firestore with the geohash bounds
    const q = query(
      collection(db, "membersPrivate"),
      where("status", "==", "active"),
      orderBy("location.geohash"),
      startAt(start),
      endBefore(end + "\uf8ff") // Includes everything starting with 'end'
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const memberPrivateData = doc.data();

        const {
          inArea = false,
          futureAvailability,
          end,
          start,
        } = getEmployeeStatus(
          memberPrivateData?.location?.lat,
          memberPrivateData?.location?.lng,
          areas,
          availability
        );

        // Filter members inside serviceBounds
        if (inArea) {
          allMembers.set(doc.id, memberPrivateData);
        }
      });
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  }

  // Convert map to object and return
  const aggregatedMembers = Object.fromEntries(allMembers);
  return aggregatedMembers;
};

export function isPointInPolygon(point, polygon) {
  let x, y;

  // Check if point is an array, assume [0] is lat and [1] is lng
  if (Array.isArray(point)) {
    x = point[0];
    y = point[1];
  } else {
    // If point is an object
    x = point.lat;
    y = point.lng;
  }

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].lat,
      yi = polygon[i].lng;
    let xj = polygon[j].lat,
      yj = polygon[j].lng;

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

// Helper function to check if a bounding box intersects a polygon
function doesBoundingBoxIntersectPolygon(boundingBox, polygon) {
  const [minLat, maxLat, minLng, maxLng] = boundingBox;

  // Check if any polygon vertex is inside the bounding box
  const isVertexInBoundingBox = polygon.some(({ lat, lng }) => {
    return lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng;
  });

  if (isVertexInBoundingBox) return true;

  // Check if the bounding box vertices are inside the polygon
  const boundingBoxVertices = [
    [minLat, minLng],
    [minLat, maxLng],
    [maxLat, minLng],
    [maxLat, maxLng],
  ];

  const isBoundingBoxVertexInPolygon = boundingBoxVertices.some((vertex) =>
    isPointInPolygon(vertex, polygon)
  );

  if (isBoundingBoxVertexInPolygon) return true;

  return false;
}

// Generate geohashes intersecting a polygon
export const generateGeohashesInPolygon = (polygon, precision = 5) => {
  const geohashes = new Set();

  console.log("polygon: ", polygon);

  // Step 1: Compute bounding box
  let minLat = Infinity,
    maxLat = -Infinity,
    minLng = Infinity,
    maxLng = -Infinity;

  // Use the correct structure to access lat/lng properties
  polygon.forEach(({ lat, lng }) => {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  // Step 2: Get all geohashes within the bounding box
  const bboxHashes = geohash.bboxes(minLat, minLng, maxLat, maxLng, precision);
  console.log("bboxHashes: ", bboxHashes);

  // Step 3: Filter geohashes that intersect the polygon
  bboxHashes.forEach((hash) => {
    const [minLat, minLng, maxLat, maxLng] = geohash.decode_bbox(hash);
    const boundingBox = [minLat, maxLat, minLng, maxLng];

    // Use doesBoundingBoxIntersectPolygon to check intersection
    if (doesBoundingBoxIntersectPolygon(boundingBox, polygon)) {
      geohashes.add(hash);
    } else {
      console.log("Not in polygon: ", hash);
    }
  });

  return Array.from(geohashes);
};

export const generateGeohashesForEmployee = (areas, precision = 5) => {
  if (!areas || typeof areas !== "object") {
    throw new Error("Invalid areas map provided.");
  }

  const uniqueGeohashes = new Set();

  Object.entries(areas).forEach(([areaId, area]) => {
    if (area?.bounds && Array.isArray(area.bounds)) {
      try {
        // Generate geohashes for the current area's bounds
        const geohashes = generateGeohashesInPolygon(area.bounds, precision);
        geohashes.forEach((hash) => uniqueGeohashes.add(hash));
      } catch (error) {
        console.error(
          `Failed to generate geohashes for area ${areaId}:`,
          error
        );
      }
    } else {
      console.warn(`Area ${areaId} does not have valid bounds.`);
    }
  });

  return Array.from(uniqueGeohashes); // Convert the Set to an array for output
};

// Function to find areas the member is in
export const findAreas = (lat, lng, areas = {}) => {
  const memberPoint = { lat, lng };
  return Object.keys(areas).filter((key) =>
    isPointInPolygon(memberPoint, areas[key].bounds)
  );
};

export const getFutureAvailability = (
  employeeAvailability,
  startBoundary,
  endBoundary
) => {
  const todayStart = DateTime.local().startOf("day");
  const MIN_DATE = DateTime.fromISO("0001-01-01T00:00:00.000Z");
  const MAX_DATE = DateTime.fromISO("9999-12-31T23:59:59.999Z");

  // Calculate total availability within given boundaries
  const totalFutureAvailability = Object.values(
    employeeAvailability?.windows || {}
  ).reduce((totalHours, window) => {
    const availabilityStart = window.start
      ? DateTime.fromJSDate(window.start.toDate())
      : MIN_DATE;
    const availabilityEnd = window.end
      ? DateTime.fromJSDate(window.end.toDate())
      : MAX_DATE;

    // Skip this window if the availability start is in the past
    if (availabilityStart < DateTime.now()) {
      return totalHours;
    }

    // Calculate the effective start and end, considering boundaries
    const effectiveStart = DateTime.max(
      availabilityStart,
      startBoundary || todayStart
    );
    const effectiveEnd = DateTime.min(availabilityEnd, endBoundary || MAX_DATE);

    // Include this window's availability only if the effective range is valid
    if (effectiveStart < effectiveEnd) {
      const durationInHours = effectiveEnd.diff(effectiveStart, "hours").hours;
      return totalHours + durationInHours;
    }

    return totalHours;
  }, 0); // Initial total hours

  return totalFutureAvailability;
};

const getSoonestSequentialWindow = (relevantWindows) => {
  const now = DateTime.now();
  const MAX_DATE = DateTime.fromISO("9999-12-31T23:59:59.999Z");
  let soonestSequentialWindow = [];
  let currentSequence = [];

  // Iterate from the last window to the first
  for (let i = relevantWindows.length - 1; i >= 0; i--) {
    const window = relevantWindows[i];
    const previousWindow = relevantWindows[i + 1];

    const isSequential =
      currentSequence.length === 0 ||
      (previousWindow &&
        (!window.endDate || // Open-ended windows are sequential with any later window
          window.endDate.plus({ days: 1 }) >= previousWindow.startDate));

    if (isSequential) {
      currentSequence.unshift(window);
    } else {
      // Validate the current sequence
      const sequenceEnd =
        currentSequence[currentSequence.length - 1]?.endDate || MAX_DATE;
      if (sequenceEnd >= now) {
        soonestSequentialWindow = [...currentSequence];
      }
      currentSequence = [window];
    }
  }

  // Check the last sequence after the loop
  const sequenceEnd =
    currentSequence[currentSequence.length - 1]?.endDate || MAX_DATE;
  if (sequenceEnd >= now) {
    soonestSequentialWindow = [...currentSequence];
  }

  return soonestSequentialWindow;
};

export const getCurrentArea = (areas = {}, now = DateTime.now()) => {
  // Iterate through the areas to find the area that contains the point
  for (const [areaKey, areaData] of Object.entries(areas)) {
    // Convert windows object map to an array of window objects
    const windowsArray = Object.values(areaData.windows || {});

    // Check windows for the current area
    const currentWindow = windowsArray.find((window) => {
      const windowStart = window.start
        ? DateTime.fromJSDate(window.start.toDate())
        : null;
      const windowEnd = window.end
        ? DateTime.fromJSDate(window.end.toDate())
        : null;

      // Determine if the current time falls within the window
      return (
        (!windowStart || windowStart <= now) && // Start is in the past or now
        (!windowEnd || windowEnd >= now) // End is in the future or now
      );
    });

    // If a matching window is found, return the area with the window
    if (currentWindow) {
      return {
        areaKey, // Return the areaKey along with the areaData
        areaData,
      };
    }
  }

  // Fallback: Return the first area entry if none match
  const fallback = Object.entries(areas)[0];
  return fallback ? { areaKey: fallback[0], areaData: fallback[1] } : null;
};

// Main function to determine availability
export const getEmployeeStatus = (
  lat,
  lng,
  areas = {},
  availability,
  totalAvailableHours = null
) => {
  const now = DateTime.now();
  const areaKeys = findAreas(lat, lng, areas);
  const relevantWindows = [];

  if (Object.keys(areas).length === 0)
    return {
      inArea: false,
      futureAvailability: 0,
      start: undefined,
      end: undefined,
    };

  // Step 1: Collect all windows from the relevant areas
  areaKeys.forEach((key) => {
    const area = areas[key];
    Object.entries(area.windows || {}).forEach(([windowKey, window]) => {
      const startDate = DateTime.fromJSDate(window.start.toDate());
      const endDate = window.end
        ? DateTime.fromJSDate(window.end.toDate())
        : null;

      // if (!endDate || endDate > now) {
      relevantWindows.push({
        key: windowKey,
        startDate,
        endDate, // Allow null for endDate to signify open-ended
      });
      // }
    });
  });

  // Step 2: Filter sequential windows that include today
  relevantWindows.sort((a, b) => a.startDate - b.startDate); // Sort by startDate

  const soonestWindow = getSoonestSequentialWindow(relevantWindows);

  // Step 3: Determine availability
  const activeSequence = soonestWindow.flat();

  const fallbackStart = DateTime.now().minus({ months: 1 }); // Last month
  // const fallbackEnd = DateTime.now().plus({ years: 1 }); // One year from now

  let start, end;

  console.log("getEmployeeStatus - activeSequence: ", activeSequence)

  if (activeSequence.length > 0) {
    start = activeSequence[0].startDate;
    end = activeSequence[activeSequence.length - 1].endDate || null;
  }
  // There is no active sequence, meaning no windows
  else {
    start = undefined;
    end = undefined;
  }

  // If only one area and no windows, override start and end**
  if (Object.keys(areas).length === 1 && activeSequence.length === 0) {
    start = fallbackStart;
    end = null; // null represents indefinite future
    console.log("getEmployeeStatus - setting fallback")
  }

  let futureAvailability;

  // If we're within the helpers provider, we can do exact availability
  if (totalAvailableHours !== null) {
    futureAvailability = totalAvailableHours;
  }

  // Else we use an approximate
  else {
    futureAvailability = getFutureAvailability(availability, start, end);
  }

  console.log("getEmployeeStatus - end: ", end);

  return {
    inArea: areaKeys.length > 0,
    futureAvailability,
    start,
    end,
  };
};

// Calculate the straight-line distance in miles between two geohashes.
export const distanceBetweenGeohashes = (geohash1, geohash2) => {
  console.log("geohash1: ", geohash1);
  console.log("geohash2: ", geohash2);

  // Decode geohashes to latitude and longitude
  const { latitude: lat1, longitude: lon1 } = geohash.decode(geohash1);
  const { latitude: lat2, longitude: lon2 } = geohash.decode(geohash2);

  // Convert degrees to radians
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);
  const lat2Rad = toRadians(lat2);
  const lon2Rad = toRadians(lon2);

  // Haversine formula
  const deltaLat = lat2Rad - lat1Rad;
  const deltaLon = lon2Rad - lon1Rad;
  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in miles
  const EARTH_RADIUS_MILES = 3958.8;
  return EARTH_RADIUS_MILES * c;
};

export const formatLocationData = (locationData) => {
  if (!locationData) return "";
  const { line1, line2, city, region, state, postal, zipCode } = locationData || {};
  const parts = [line1, line2, city, state || region, zipCode || postal].filter(Boolean);
  return parts.join(", ");
};



export const formatUserLocation = (location) => {
  if (!location) return "";
  const { city, state, zipCode } = location;
  const parts = [city, state, zipCode].filter(Boolean); // Include only non-empty fields
  return parts.join(", "); // Combine with a comma
};
