import { Close } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import EmployeeSearchBar from "../manageEmployees/EmployeeSearchBar";

const HelperSearchSelect = ({
  referral = null,
  onSelect = () => {},
  variant = "outlined",
}) => {
  const [employeeReferral, setEmployeeReferral] = useState({
    referralUid: referral?.id || "",
    referralName: referral?.firstName
      ? `${referral.firstName} ${referral.lastName}`
      : "",
    referralAvatar: referral?.avatarUrl || "",
  });

  const handleSelect = (employee) => {
    setEmployeeReferral({
      referralUid: employee?.id,
      referralName: `${employee.firstName} ${employee.lastName}`,
      referralAvatar: employee.avatarUrl,
    });
    onSelect(employee);
  };

  const handleReferralClear = () => {
    setEmployeeReferral({
      referralUid: "",
      referralName: "",
      referralAvatar: "",
    });
  };

  const renderReferral = (referral) => {
    return (
      <ListItem
        key={referral.referralUid}
        sx={{
          borderRadius: "15px",
          border:
            variant === "outlined" ? "1px solid rgba(0, 0, 0, 0.23)" : null,
          boxSizing: "border-box",
        }}
      >
        <Avatar sx={{ marginRight: 2 }} src={referral.referralAvatar}></Avatar>
        <ListItemText primary={referral.referralName} />
        {/* <Chip label={member.status} /> */}
        <IconButton
          onClick={() => handleReferralClear()}
          sx={{ marginRight: 0 }}
          onMouseUp={(event) => event.stopPropagation()}
        >
          <Close />
        </IconButton>
      </ListItem>
    );
  };

  return (
    <>
      {!employeeReferral.referralUid ? (
        <EmployeeSearchBar onEmployeeSelect={handleSelect} />
      ) : (
        renderReferral(employeeReferral, "helper")
      )}
    </>
  );
};

export default HelperSearchSelect;
