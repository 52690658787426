// components/AvatarFieldV2.jsx

import { AddAPhotoRounded } from "@mui/icons-material";
import { Avatar, Box, styled, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDrawer } from "../../providers/DrawerProvider";
import CropImage from "../media/CropImage";

const DropzoneArea = styled(Box)(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  padding: 4,
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "white",
  transform: "scale(1)",
  boxShadow: "none",
  transition: "background-color 0.3s, box-shadow 0.3s, transform 0.3s",
  "&:hover": {
    transform: "scale(1.0)",
    boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.2)",
    backgroundColor: "white",
  },
  "&:hover .hoverText": {
    opacity: 1,
  },
  "&:focus-visible, &:focus": {
    backgroundColor: "white",
  },
  "&:active": {
    backgroundColor: "white",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "white",
  },
}));

/**
 * @param {string} avatarUrl - The current (saved) avatar URL from Firestore
 * @param {function} onAvatarChange - Called when user has a new cropped image.
 *         We pass (blob, previewUrl) so the parent can handle final upload if needed.
 */
function AvatarFieldV2({ avatarUrl, onAvatarChange }) {
  const [photoURL, setPhotoURL] = useState(avatarUrl); // For local display
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null); // The new cropped preview
  const { openDrawer } = useDrawer();

  const isMobile = useMediaQuery("(max-width:600px)");

  // If parent changes avatarUrl prop, sync it
  useEffect(() => {
    if (avatarUrl && avatarUrl !== photoURL) {
      setPhotoURL(avatarUrl);
    }
  }, [avatarUrl]);

  // Called when the user drops/selects a file
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;
    setSelectedFile(file);
    openDrawer(CropImage, {
      disableSwipeToClose: true,
      title: "Crop image",
      file,
      onCropComplete: handleCroppedImage,
      onClose: handleCropCancel,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  /**
   * handleCroppedImage: Called by CropImage once the user finishes cropping.
   * We store a local preview (Data URL) so the user can see it, and a blob for final upload.
   */
  const handleCroppedImage = (croppedBlob, croppedPreviewUrl) => {
    setPreviewURL(croppedPreviewUrl); // So we can display the new image
    // Let the parent know we have a new image. We pass the raw blob + a local preview
    onAvatarChange(croppedBlob, croppedPreviewUrl);
  };

  // If user cancels the crop
  const handleCropCancel = () => {
    setSelectedFile(null);
  };

  // The image we display is either the newly cropped preview or the original
  const finalDisplayUrl = previewURL || photoURL;

  return (
    <>
      <DropzoneArea
        {...getRootProps()}
        sx={{
          border: isMobile ? "1px dashed rgba(0,0,0,.3)" : "none",
          borderRadius: "100px",
          backgroundColor: isDragActive ? "neutral" : "inherit",
          // On hover, force the IconButton to 0 opacity
          "&:hover .avatar-icon": {
            opacity: 0, // or 0.2, etc. if you just want it partially faded
          },
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <Avatar
            src={finalDisplayUrl}
            sx={{
              width: 96,
              height: 96,
            }}
          />
          <Box
            className="avatar-icon" // Give it a class to target in the hover rule
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              width: "48px",
              height: "48px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the button
              zIndex: 10,
              opacity: 1,
              transition: "opacity 0.3s",
              borderRadius: "50px",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <AddAPhotoRounded sx={{ color: "white" }} />
          </Box>
        </Box>
      </DropzoneArea>
    </>
  );
}

export default AvatarFieldV2;
