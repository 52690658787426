import { Close, Explore } from "@mui/icons-material";
import { Box, DialogContent, IconButton, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import useMemberData from "../../hooks/useMemberData";
import { useAuth } from "../../providers/AuthProvider";
import { useHelpers } from "../../providers/HelpersProvider";
import SignupFeePayment from "../payment/SignupFeePayment";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const SignUpFeeCard = ({
  onClose,
  showAlert = true,
  initialStatus = { status: "active" },
}) => {
  const { serviceStatus: providerStatus } = useHelpers() || {};
  const { currentUser } = useAuth();
  const { memberData, memberAdminData, memberPrivateData } = useMemberData(
    currentUser?.uid
  );

  const serviceStatus = providerStatus || initialStatus || false;

  return (
    <>
      {serviceStatus?.status === "active" ||
      serviceStatus?.status === "no_availability" ||
      serviceStatus?.status === "paused" ? (
        <Elements stripe={stripePromise}>
          <SignupFeePayment
            showClose={true}
            showAlert={showAlert}
            customerId={memberAdminData?.stripeId}
            memberId={currentUser.uid}
            onClose={onClose}
            zipCode={memberData?.location?.zipCode}
            signupFeeStatus={memberPrivateData?.signupFeeStatus}
            adminMode={false}
            serviceStatus={serviceStatus}
            mName={memberData?.firstName}
            city={memberPrivateData?.location?.city}
          />
        </Elements>
      ) : (
        <DialogContent>
          <IconButton onClick={onClose} sx={{ mr: "auto", p: 2 }}>
            <Close />
          </IconButton>
          <Box textAlign="center" sx={{ my: 2 }}>
            {/* Icon */}
            <Explore color="primary" sx={{ fontSize: 40, mb: 1 }} />
            {/* Header Text */}
            <Typography variant="h6" sx={{ mb: 1 }}>
              Hang Tight!
            </Typography>

            {/* Message */}
            <Typography sx={{ mb: 10 }}>
              {memberData?.location === undefined &&
                "Please enter your address first in the previous checklist item, so that we can confirm that we provide service in your neighborhood."}
              {serviceStatus?.status === "waitlist" &&
                "Thanks for your interest! Unfortunately, we haven't reached your town quite yet. Once service begins, we will let you know!"}
              {serviceStatus?.status === "suspended" &&
                "Thanks for your interest! Unfortunately, we are short on staffing in your area. We are working hard to grow the team and will keep you in the loop!"}
            </Typography>
          </Box>
        </DialogContent>
      )}
    </>
  );
};

export default SignUpFeeCard;
