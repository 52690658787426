// -- src/firebase.js

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4KIcbR5u37ztnbmHf-WloCjIXRIn3IUg",
  authDomain: "ourlinkedlives.firebaseapp.com",
  projectId: "ourlinkedlives",
  storageBucket: "ourlinkedlives.appspot.com",
  messagingSenderId: "36400973687",
  appId: "1:36400973687:web:84bdd9da3cd9b2c4090372",
  measurementId: "G-HQNDHY9001",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize the Vertex AI service
const vertexAI = getVertexAI(app);

// Initialize the generative model with a model that supports your use case
const model = getGenerativeModel(vertexAI, { model: "gemini-2.0-flash" });

// Check if Messaging is supported before initializing
let messaging;
(async () => {
  if (await isSupported()) {
    try {
      messaging = getMessaging(app);
      console.log("Firebase Messaging initialized successfully.");
    } catch (error) {
      alert("getMessaging error: " + error.message);
      console.error("Firebase Messaging initialization failed:", error);
    }
  } else {
    console.warn("Firebase Messaging is not supported in this browser.");
  }
})();

// Register Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered:", registration);
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
} else {
  console.warn("Service Worker not supported in this browser.");
}

// Listen for foreground messages (when app is open)
if (messaging) {
  // Listen for foreground messages (when app is open)
  onMessage(messaging, async (payload) => {
    try {
      console.log("Message received in foreground: ", payload);

      const notificationTitle = payload.data.title || "New Notification";
      const notificationOptions = {
        body: payload.data.body || "You have a new message.",
        icon: "/logo.png", // Ensure this is a valid icon path
      };

      // Show notification manually (Safari PWA may still show "from Summit")
      new Notification(notificationTitle, notificationOptions);
    } catch (error) {
      alert("Unhandled error: " + error.message);
      console.error("Failed to show notification:", error);
    }
  });
} else {
  console.warn("onMessage listener skipped: Messaging is not supported.");
}
// Firebase that can also be emulated
let auth, db, functions, storage;

// Enable Firestore debug logging
// setLogLevel('debug');

if (process.env.NODE_ENV === "development") {
  // Initialize services with emulator in development
  auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099/");
  db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
  functions = getFunctions(app);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  storage = getStorage();
  connectStorageEmulator(storage, "127.0.0.1", 9199);
} else {
  // Initialize services normally for production
  auth = getAuth();
  db = getFirestore();
  functions = getFunctions(app);
  storage = getStorage();
}

export { analytics, app, auth, db, functions, messaging, storage, model };
