import {
  Box,
  Card,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import AvatarStatusBadge from "../AvatarStatusBadge";
import ColorAvatar from "../ColorAvatar";

const VisitPostCardSkeleton = () => {
  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        // boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.1)",
        border: "none",
        width: "100%",
        height: "100%",
        mb: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        boxSizing: "border-box",
      }}
    >
      {/* Avatar and Name */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Skeleton variant="circular" width={60} height={60} />
        <Box>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton variant="text" width={120} height={18} />
        </Box>
      </Box>

      {/* Description */}
      <Box>
        <Skeleton variant="text" width="90%" height={20} />
        <Skeleton variant="text" width="85%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
      </Box>

      {/* Employees List */}
      <Box sx={{ display: "flex", ml: "auto", gap: 1 }}>
        <Skeleton variant="circular" width={50} height={50} />
        <Box>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton variant="text" width={80} height={18} />
        </Box>
      </Box>
    </Card>
  );
};

const TriPanelReviews = ({ employeeId = undefined, spacing = "normal" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [visitsData, setVisitsData] = useState([]);

  useEffect(() => {
    const visitQuery = [
      collection(db, "visits"),
      where("rating", "==", 5),
      where("sentiment", "==", 3),
      orderBy("start", "desc"),
      limit(3),
    ];

    if (employeeId) {
      visitQuery.push(where("employeeArr", "array-contains", employeeId));
    }

    const q = query(...visitQuery);

    const fetchData = async () => {
      const snapshot = await getDocs(q);
      const fetchedReviews = [];
      snapshot.forEach((doc) => {
        fetchedReviews.push({ id: doc.id, ...doc.data() });
      });

      fetchedReviews.sort((a, b) => (b.review?.length || 0) - (a.review?.length || 0));

      setVisitsData(fetchedReviews);
      setLoading(false);
    };

    fetchData();
  }, [employeeId]);

  function formatTimeDifference(startTimestamp, endTimestamp) {
    const startTime = moment(startTimestamp.toDate());
    const endTime = moment(endTimestamp.toDate());

    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    let result = "";

    if (hours > 0) {
      result += `${hours} hour`;
      if (minutes > 0) {
        result += ` ${minutes} minute`;
      }
    } else {
      result = `${minutes} minute`;
    }

    return `${result} visit`;
  }

  return (
    <Box sx={{ mx: "auto", maxWidth: "1200px", mt: { xs: 1, sm: 4 } }}>
      <Box
        sx={{
          display: "flex",
          // mx: 2,
          gap: 2,
          overflowX: isMobile ? "auto" : "visible",
        }}
      >
        <div style={{ width: "16px" }} />
        {!loading &&
          visitsData.map((visit) => (
            <Box
              key={visit.id}
              sx={{
                flex: isMobile ? "0 0 80vw" : "1",
                maxWidth: isMobile ? "80vw" : "none",
                boxSizing: "border-box",
                px: 1,
              }}
            >
              <Box sx={{ my: spacing === "large" ? "300px" : 2 }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}
                >
                  <ColorAvatar
                    avatarUrl={visit.avatarUrl}
                    name={visit.displayName}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight="600">
                      {visit.displayName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {DateTime.fromSeconds(visit.start.seconds).toRelative()} •{" "}
                      {formatTimeDifference(visit.start, visit.end)}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    my: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                  }}
                >
                  {visit.description}
                </Typography>
                {visit.rating && (
                  <Box
                    sx={{
                      backgroundColor: "#F5F5F5",
                      p: 1,
                      borderRadius: "15px",
                      my: 1,
                    }}
                  >
                    <Rating
                      size="small"
                      value={visit.rating}
                      precision={0.1}
                      readOnly
                    />
                    <Typography variant="body1">{visit.review}</Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    ml: "auto",
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    justifyContent: "flex-end",
                  }}
                >
                  {Object.entries(visit.employees)
                    .filter(
                      ([, employee]) => employee.responseStatus === "accepted"
                    )
                    .sort(([keyA, employeeA], [keyB, employeeB]) =>
                      employeeA.displayName.localeCompare(employeeB.displayName)
                    )
                    .map(([key, employee], index) => (
                      <Box key={key} display="flex" justifyContent="flex-end">
                        <Stack direction="row" gap={1} alignItems="center">
                          <ListItemAvatar>
                            <AvatarStatusBadge
                              status={employee.responseStatus}
                              avatarUrl={
                                visit.employees[key]?.avatarUrl ||
                                visit?.employees[key]?.avatarUrl
                              }
                              displayName={visit?.employees[key]?.firstName}
                              index={index}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${visit?.employees[key]?.displayName}`}
                            secondary="completed"
                          />
                        </Stack>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          ))}
        {loading &&
          Array.from(new Array(3)).map((_, index) => (
            <VisitPostCardSkeleton key={index} />
          ))}
        <div style={{ width: "16px" }} />
      </Box>
    </Box>
  );
};

export default TriPanelReviews;
