import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../firebase";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import NameField from "../components/fields/NameField";
import PhoneField from "../components/fields/PhoneField";
import SimplePage from "../components/SimplePage";
import { addEmployeeRole } from "../services/employeeServices";
import { formatName } from "../services/stringServices";

const EmployeeSignUpPage = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gLoading, setGLoading] = useState(false);

  const navigate = useNavigate();
  const nextUrl = "/dashboard";

  // Calculate today's year minus 18
  const maxDate = DateTime.now().minus({ years: 16 });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const validateForm = (values) => {
    let errors = {};
    if (!values.firstName) errors.firstName = "First Name is required";
    if (!values.lastName) errors.lastName = "Last Name is required";
    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      errors.phone = "Your phone number must be 10 digits.";
    if (!values.birthday) {
      errors.birthday = "Birthday is required";
    } else if (values.birthday > maxDate) {
      errors.birthday = "You must be at least 16 years old.";
    }
    return errors;
  };

  const handleGoogleSignUp = async () => {
    setGLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await addEmployeeRole(); // Assuming this function updates the user's role

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);
      navigate(nextUrl);
    } catch (err) {
      setErrors({ general: "Google sign up failed. Please try again." });
    } finally {
      setGLoading(false);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    let newError = validateForm(values);
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;

      await addEmployeeRole();

      // Force a refresh of the token to ensure the new role is reflected
      await user.getIdToken(true);

      const firstName = formatName(values.firstName);
      const lastName = formatName(values.lastName);
      const birthMonth = values.birthday.toFormat("yyyy-MM");
      const birthday = values.birthday.toFormat("yyyy-MM-dd");
      const sanitizedPhone = values.phone.replace(/\D/g, "");

      // Determine the hourly rate
      const today = DateTime.now();
      const age = today.diff(values.birthday, "years").years;
      const hourlyRate = age < 18 ? 17 : 20;

      const notificationSettings = {
        bookingConfirmation: { email: true, sms: true },
        visitCancellations: { email: true, sms: true },
        visitUpdates: { email: true, sms: true },
        visitReminders: { email: true, sms: true },
        visitExpirations: { email: true, sms: true },
        visitReviews: { email: true, sms: true },
        newsletters: { email: true, sms: true },
        promotions: { email: true, sms: true },
      };

      // Create a document in Firestore for the member
      await Promise.all([
        // Setting the member's public data
        setDoc(doc(db, "employees", user.uid), {
          firstName,
          lastName,
          birthMonth,
          avatarUrl: "",
          created: Timestamp.now(),
          status: "applicant",
        }),
        // Setting the member's private data
        setDoc(doc(db, "employeesPrivate", user.uid), {
          firstName,
          lastName,
          birthday,
          birthMonth,
          avatarUrl: "",
          notifications: notificationSettings,
          email: values.email,
          phone: sanitizedPhone,
          created: Timestamp.now(),
          status: "applicant",
        }),
        // Setting the member's private data
        setDoc(doc(db, "employeesAdmin", user.uid), {
          created: Timestamp.now(),
          hourlyRate,
          isAdmin: false,
          status: "applicant",
        }),
      ]);

      navigate("/dashboard");
    } catch (err) {
      console.error("Error: ", err);
      switch (err.code) {
        case "auth/invalid-email":
          newError.email = "The email address is badly formatted.";
          break;
        case "auth/email-already-in-use":
          newError.email =
            "The email address is already in use by another account.";
          break;
        case "auth/weak-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        case "auth/missing-password":
          newError.password = "The password must be 6 characters long or more.";
          break;
        default:
          newError.general =
            "An unknown error occurred. Please try again later.";
          break;
      }
      setErrors(newError);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    // Update the 'birthday' in your state

    setErrors((prevErrors) => ({
      ...prevErrors,
      birthday: "", // Clear any previous error
    }));

    // Make sure to handle the newDate format correctly as per your requirements
    setValues((prevValues) => ({
      ...prevValues,
      birthday: newDate, // Assuming newDate is in the format you need; otherwise, you might need to format it
    }));
  };

  return (
    <SimplePage>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Card
          elevation={0}
          sx={{
            px: { xs: "0", sm: 3 },
            py: 3,
            maxWidth: "400px",
            border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
            overflow: "visible",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Become a Helper
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            Already have a helper account?{" "}
            <Link
              to="/helper/login"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Login
            </Link>
          </Typography>
          <form onSubmit={handleSignUp} noValidate>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "16px",
                mb: 2,
              }}
            >
              <NameField
                name={"firstName"}
                value={values.firstName}
                error={errors.firstName}
                handleChange={handleChange}
                label={"First Name"}
              />
              <NameField
                name={"lastName"}
                value={values.lastName}
                error={errors.lastName}
                handleChange={handleChange}
                label={"Last Name"}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mb: 2,
              }}
            >
              <DatePicker
                yearsOrder="desc"
                sx={{ width: "100%" }}
                label={"Birthday"}
                name="birthday"
                views={["year", "month", "day"]}
                value={values?.birthday} // Use the state value here
                openTo="year" // Start by showing the year selector
                onChange={handleDateChange} // Update the state when the date changes
                maxDate={maxDate}
              />
              <FormHelperText
                id="birth-month-error"
                error={errors.birthday}
                sx={{ ml: "14px" }}
              >
                {errors.birthday && errors.birthday}
              </FormHelperText>
            </Box>

            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <PhoneField
              phone={values.phone}
              error={errors.phone}
              handleChange={handleChange}
            />
            <div style={{ height: "20px" }} />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
              style={{ marginBottom: "20px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevState) => !prevState)} // Inline arrow function here
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: "20px" }}
            >
              <span>
                By clicking Submit below to create an account, I agree to the
                Linked Lives{" "}
                <a
                  href="/legal/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "hsl(190, 100%, 30%)",
                    fontWeight: 600,
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                .
              </span>
            </Typography>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              disableElevation
              disabled={loading || gLoading}
              sx={{ height: "60px", textTransform: "none" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
          {/* <DividerWithText>or</DividerWithText>
          <Button
            variant="outlined"
            disabled={loading || gLoading}
            color="primary"
            startIcon={<Google />} // Add the Google icon here
            onClick={handleGoogleSignUp}
            fullWidth
          >
            {gLoading ? <CircularProgress size={24} /> : "Sign Up with Google"}
          </Button> */}
        </Card>
      </LocalizationProvider>
    </SimplePage>
  );
};

export default EmployeeSignUpPage;
