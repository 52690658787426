import {
  BuildOutlined,
  CategoryOutlined,
  CelebrationOutlined,
  ChairOutlined,
  CleaningServicesOutlined,
  DesktopMacOutlined,
  DirectionsCar,
  DirectionsCarOutlined,
  FitnessCenterOutlined,
  GrassOutlined,
  LocalFloristOutlined,
  PeopleOutline,
  Stadium,
  StadiumOutlined,
} from "@mui/icons-material";
import OddJobsIcon from "@mui/icons-material/Build";
import OrganizationIcon from "@mui/icons-material/Category";
import DecorationIcon from "@mui/icons-material/Celebration";
import FurnitureIcon from "@mui/icons-material/Chair";
import CleaningIcon from "@mui/icons-material/CleaningServices";
import HeavyLiftingIcon from "@mui/icons-material/FitnessCenter";
import YardWorkIcon from "@mui/icons-material/Grass";
import GardeningIcon from "@mui/icons-material/LocalFlorist";
import CompanionshipIcon from "@mui/icons-material/People";
import React from "react";

export const TASKS_LIST = [
  {
    title: "Gardening",
    helperTitle: "Gardening",
    icon: <GardeningIcon />,
    iconOutlined: <LocalFloristOutlined />,
    description: "Transform your garden with an extra hand",
    examples: ["Weed garden beds", "Plant flowers", "Water plants"],
    helperDescription:
      "Weed garden beds, plant flowers, water plants, prune bushes, trim hedges, harvest vegetables.",
    keywords: [
      "garden",
      "gardening",
      "weed",
      "weeding",
      "flowers",
      "plant",
      "bulb",
      "watering",
      "soil",
      "compost",
    ],
  },
  {
    title: "Yard Work",
    helperTitle: "Yard Work",
    icon: <YardWorkIcon />,
    iconOutlined: <GrassOutlined />,
    description: "Keep your yard in check with regular upkeep",
    examples: ["Spread bark", "Rake leaves", "Prune bushes"],
    helperDescription:
      "Rake leaves, spread bark, pick up sticks, prune shrubs, tidy pathways, remove debris.",
    keywords: [
      "yard",
      "rake",
      "raking",
      "bark",
      "leaves",
      "leaf",
      "prune",
      "bush",
      "pruning",
      "chip",
      "hose",
      "lawn",
      "dig ", // dig, digging
      "digging ", // dig, digging
      " fence ",
      "landscap",
      "blackberry",
    ],
  },
  {
    title: "Cleaning",
    helperTitle: "Cleaning",
    icon: <CleaningIcon />,
    iconOutlined: <CleaningServicesOutlined />,
    description: "Give your home a chance to sparkle",
    examples: ["Scrub cabinets", "Vacuum carpets", "Mop floors"],
    helperDescription:
      "Scrub cabinets, dust shelves, vacuum carpets, mop floors, sweep garage, wipe surfaces, clean windows.",
    keywords: [
      "vacuum",
      "mop",
      "scrub",
      "dust",
      "clean",
      "wipe",
      "bathroom",
      "kitchen",
      "housekeep",
    ],
  },
  {
    title: "Organization",
    helperTitle: "Organization",
    icon: <OrganizationIcon />,
    iconOutlined: <CategoryOutlined />,
    description: "Declutter your garage, pantry, or stubborn areas",
    examples: ["Organize closets", "Declutter rooms", "Sort through garage"],
    helperDescription:
      "Sort items, arrange closets, organize shelves or garage, categorize items, declutter pantry.",
    keywords: ["organiz", "declutter", "sort", "closet", "arrange", "label"],
  },
  {
    title: "Lifting",
    helperTitle: "Heavy Lifting",
    icon: <HeavyLiftingIcon />,
    iconOutlined: <FitnessCenterOutlined />,
    description: "Save your back while we move heavy items",
    examples: ["Re-arrange furniture", "Carry heavy boxes", "Move rugs"],
    helperDescription:
      "Move furniture, carry boxes, lift heavy objects, move bulky items, rearrange storage, help load cars.",
    keywords: [
      "heavy",
      "boxes",
      "lift",
      "haul",
      "uhaul",
      "lifting",
      "bulky",
      "move",
      "carry",
      "moving",
      "relocat",
      "furniture",
      " lbs ",
      " lb ",
    ],
  },
  {
    title: "Tech Help",
    helperTitle: "Tech Help",
    icon: <DesktopMacOutlined />,
    iconOutlined: <DesktopMacOutlined />,
    description: "Solve your tech problems with savvy help",
    examples: [
      "Setup TV streaming",
      "Computer and phone help",
      "Connect printer",
    ],
    helperDescription:
      "Set up TV, connect printer, adjust Wi-Fi, help with passwords, install simple apps, explain basics.",
    keywords: [
      "tech",
      "printer",
      "wifi",
      "computer",
      "phone",
      "router",
      "modem",
      "tv",
      "macbook",
      "wi-fi",
      "password",
      "android",
      "ipad",
      "iphone",
      "internet",
      // "install", // install lights confused this one
      "robot",
    ],
  },
  {
    title: "Decoration",
    helperTitle: "Seasonal Decoration",
    icon: <DecorationIcon />,
    iconOutlined: <CelebrationOutlined />,
    description: "Celebrate festivities with seasonal decorations",
    examples: ["Setup Christmas tree", "String lights", "Seasonal décor"],
    helperDescription:
      "Set up holiday lights, place ornaments, arrange seasonal décor, display knick-knacks, position wreaths.",
    keywords: [
      "holiday",
      "ornaments",
      " art ",
      " artwork ",
      "picture",
      "photo",
      "hang",
      "hung",
      "lights",
      "decorat", // decorating, decoration, decorate
      "christmas",
      "halloween",
      "thanksgiving",
      "easter",
      "fourth of july",
    ],
  },
  {
    title: "Errands",
    helperTitle: "Local Errands",
    icon: <DirectionsCar />,
    iconOutlined: <DirectionsCarOutlined />,
    description: "Free your time by letting us run basic errands",
    examples: ["Grocery shop", "Pick up flowers", "Mail packages"],
    helperDescription:
      "Pick up groceries, mail packages, drop off prescriptions, buy flowers, grab household items, return rentals.",
    keywords: [
      "groceries",
      "grocery",
      "shopping",
      "errand",
      "pickup",
      " mail ", // space to disclude "email"
      " mailing ",
      "delivery",
    ],
  },
  {
    title: "Assembly",
    helperTitle: "Assembly",
    icon: <FurnitureIcon />,
    iconOutlined: <ChairOutlined />,
    description: "Get help with furniture assembly and moving.",
    examples: ["Assemble storage racks", "Move couch", "Tighten chair screws"],
    helperDescription:
      "Assemble shelves, move couch, tighten screws, arrange chairs, adjust tables.",
    keywords: [
      "assemble",
      "assembly",
      "screw",
      "tighten",
      "putting together",
      "put together",
    ],
  },
  {
    title: "Companion",
    helperTitle: "Companionship",
    icon: <CompanionshipIcon />,
    iconOutlined: <PeopleOutline />,
    description: "Enjoy friendly company and conversation.",
    examples: ["Chat over coffee", "Play board games", "Go for walks"],
    helperDescription:
      "Chat over coffee, play board games, take walks, share stories, look through photos, watch TV.",
    keywords: [
      "coffee",
      "go for a walk",
      "take a walk",
      "games",
      "boardgame",
      "companion",
      "company",
    ],
  },

  {
    title: "Events",
    helperTitle: "Event Support",
    icon: <Stadium />,
    iconOutlined: <StadiumOutlined />,
    description: "Get help preparing for or cleaning up after.",
    examples: [
      "Set up chairs",
      "Decorate for a party",
      "Clean up after an event",
    ],
    helperDescription:
      "Assist with setting up or cleaning up events, decorating, moving tables and chairs, or providing general help.",
    keywords: ["event", "party", "gathering"],
  },
  {
    title: "Odd Jobs",
    helperTitle: "Odd Jobs",
    icon: <OddJobsIcon />,
    iconOutlined: <BuildOutlined />,
    description: "Handle small tasks around the house with ease.",
    examples: [
      "Winterize deck furniture",
      "Change light bulbs",
      "Test smoke alarms",
    ],
    helperDescription:
      "Change light bulbs, wash windows, hang curtains, test smoke alarms, ground floor gutters.",
    keywords: [
      "odd",
      "fix",
      "repair",
      "window",
      "curtain",
      "minor house",
      "build",
      "gutter",
      "ladder",
      "help around",
      "general help",
      "winteriz",
      "little jobs",
      "take-down",
      "take down",
      "power wash",
      "pressure wash",
      "powerwash",
      "fire alarm",
      "smoke detector",
    ],
  },
];
