import { Box, Typography } from "@mui/material";
import React from "react";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

const LandingVideoSection = ({ pageName = "" }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"promo video"`); // Pass the logMessage here

  return (
    <Box
      ref={ref}
      sx={{
        pb: { xs: 2, sm: 4 },
        bgcolor: "primary.dark",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h2"
        align="center"
        sx={{
          mb: { xs: 1, sm: 1 },
          mt: 6,
          fontSize: { xs: "42px", sm: "48px" },
          color: "white",
        }}
      >
        Member Testimonials
      </Typography>

      <Typography variant="h6" fontWeight={400} sx={{ textAlign: "center", color: "white" }}>
        Hear amazing experiences directly from our older adult members
      </Typography>

      <Box sx={{ width: "100%", height: "100%", maxWidth: "964px" }}>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
            borderRadius: {xs: 0, sm: "30px"}, // Add this line to round the borders of the container
            my: { xs: 2, sm: 4 },
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/5r1l1J61zAo?autoplay=1&mute=1&controls=1&cc_lang_pref=en&cc_load_policy=1&loop=1&playlist=5r1l1J61zAo"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingVideoSection;
