// -- App.js

import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./App.css"; // <-- Ensure this line exists at the top of your App.js file
import InvitesPage from "./pages/public/InvitesPage";

import EmployeeLoginPage from "./pages/EmployeeLoginPage";
import MemberSignUpPage from "./pages/memberFlow/MemberSignUpPage";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Navigate } from "react-router-dom";
import EnvironmentInfo from "./components/EnvironmentInfo";
import GuestHeader from "./components/GuestHeader";
import ScrollToTop from "./components/ScrollToTop";
import WheelPopup from "./components/popup/WheelPopup";
import AdCopyPage from "./pages/AdCopyPage";
import CreateEmployeePage from "./pages/CreateEmployeePage";
import HiringPage from "./pages/public/HiringPage";
import ReviewsPage from "./pages/public/ReviewsPage";
import WebFooter from "./pages/public/sections/WebFooter";

import GeneralSignUp from "./components/GeneralSignUp";
import AdsView from "./components/ads/AdsView";
import AuthPage from "./components/auth/AuthPage";
import ReviewView from "./components/generate/ReviewView";
import HelpersPage from "./components/helpers/HelpersPage";
import TestFacebookAdCampaign from "./components/lab/TriggerFacebookAd";
import GeneralLogin from "./components/login/GeneralLogin";
import LoginOrSignUp from "./components/memberLoginOrSignUp/LoginOrSignUp";
import { legalDocuments } from "./data/legalDocuments";
import DashboardPage from "./pages/DashboardPage";
import EmployeeSignUpPage from "./pages/EmployeeSignUpPage";
import AboutPage from "./pages/public/AboutPage";
import ConfirmationPage from "./pages/public/ConfirmationPage";
import ContactFormPage from "./pages/public/ContactFormPage";
import ContactPage from "./pages/public/ContactPage";
import FaqPage from "./pages/public/FaqPage";
import Home from "./pages/public/Home";
import LocationsPage from "./pages/public/LocationsPage";
import MembershipPage from "./pages/public/MembershipPage";
import ReviewPage from "./pages/public/ReviewPage";
import ServicesPage from "./pages/public/ServicesPage";
import TaskSearchPage from "./pages/public/TaskSearchPage";
import { SnackbarProvider } from "./providers/SnackbarProvider";

const LegalDocumentPage = lazy(() => import("./pages/legal/LegalDocumentPage"));
const LegalIndexPage = lazy(() => import("./pages/legal/LegalIndexPage"));
const LegalLayout = lazy(() => import("./pages/legal/LegalLayout"));
const ReschedulePublicPage = lazy(() =>
  import("./pages/public/ReschedulePublicPage")
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/senior-help/:cityState" element={<Home />} />
        <Route path="/:cityState" element={<Home />} />
        <Route path="/pricing" element={<MembershipPage />} />
        <Route path="/confirmed" element={<ConfirmationPage />} />

        <Route
          path="/legal"
          element={
            <Suspense>
              <LegalLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense>
                <LegalIndexPage documents={legalDocuments} />
              </Suspense>
            }
          />
          <Route
            path=":slug"
            element={
              <Suspense>
                <LegalDocumentPage documents={legalDocuments} />
              </Suspense>
            }
          />
        </Route>

        <Route path="/locations" element={<LocationsPage />} />
        <Route path="/task-search" element={<TaskSearchPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/get-started" element={<LoginOrSignUp />} />
        <Route
          path="/join-waitlist"
          element={<LoginOrSignUp title="Join waitlist" />}
        />
        <Route path="/ad-copy" element={<AdCopyPage />} />
        <Route path="/ads/:helperId" element={<AdsView />} />
        <Route path="/review/:reviewId" element={<ReviewView />} />

        <Route path="/signup" element={<GeneralSignUp />} />
        <Route path="/login" element={<GeneralLogin />} />
        <Route
          path="/spin-to-win"
          element={
            <>
              <GuestHeader />
              <WheelPopup fullScreen={true} />
              <WebFooter />
            </>
          }
        />

        <Route path="/env" element={<EnvironmentInfo />} />
        <Route path="/lab/:helperId" element={<TestFacebookAdCampaign />} />

        <Route path="/member/signup" element={<MemberSignUpPage />} />
        <Route path="/member/login" element={<GeneralLogin />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/inquiry" element={<ContactFormPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/hiring" element={<HiringPage />} />
        <Route path="/invites" element={<InvitesPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/reschedule"
          element={
            <Suspense>
              <ReschedulePublicPage />
            </Suspense>
          }
        />
        <Route path="/review" element={<ReviewPage />} />
        <Route path="/reviews" element={<ReviewsPage />} />
        <Route path="/helpers" element={<HelpersPage />} />

        {/* <Route path="/register" element={<RegisterPage />} /> */}

        {/* Test routes */}
        <Route path="/helper/signup" element={<EmployeeSignUpPage />} />
        <Route path="/helper/login" element={<EmployeeLoginPage />} />

        <Route path="/helper/create-account" element={<CreateEmployeePage />} />

        <Route
          path="/dashboard"
          element={
            <SnackbarProvider>
              <DashboardPage />
            </SnackbarProvider>
          }
        />

        {/* Redirects */}
        <Route path="/about-us" element={<Navigate to="/about" replace />} />
        <Route
          path="/membership"
          element={<Navigate to="/pricing" replace />}
        />
        <Route
          path="/household-help"
          element={<Navigate to="/senior-help" replace />}
        />
        <Route
          path="/services"
          element={<Navigate to="/senior-services" replace />}
        />
        <Route
          path="/job-openings/link-helper"
          element={<Navigate to="/hiring" replace />}
        />

        {/* Redirect if page not found */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
