import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSession } from "../../providers/SessionProvider";

const CloseConfirmation = ({
  modalTitle: title = "Are you sure?",
  memberId = null,
  modalBody:
    body = "Are you sure you want to close? Unsaved changes will be lost",
  confirmText = "Yes",
  cancelText = "Cancel",
  onConfirm = () => {},
  buttonColor = "primary",
  onClose,
}) => {
  const { createLog = () => {} } = useSession() || {};

  const handleConfirm = () => {
    onConfirm();
    onClose();
    if (memberId) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Closed out.`,
        logType: "activity",
      });
    }
  };

  return (
    <>
      <IconButton onClick={onClose} sx={{ mr: "auto", mt: 2, ml: 2, mb: 2 }}>
        <Close />
      </IconButton>
      <DialogContent>
        <Typography variant="h6">{title}</Typography>
        <Typography>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "flex-end" },
            width: "100%",
            gap: 1,
          }}
        >
          <Button
            disableElevation
            sx={{
              height: "56px",
              textTransform: "none",
              width: { xs: "100%", sm: "200px" },
            }}
            variant="outlined"
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button
            disableElevation
            sx={{
              height: "56px",
              textTransform: "none",
              width: { xs: "100%", sm: "200px" },
            }}
            variant="contained"
            color={buttonColor}
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default CloseConfirmation;
