import { Cancel, Search } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
} from "@mui/material";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import _ from "lodash"; // Ensure lodash is installed for debounce functionality
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

function EmployeeSearchBar({
  clearOnSelect = false,
  onEmployeeSelect = () => {},
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionCache, setSessionCache] = useState({});

  const handleChange = (event) => {
    setSearchTerm(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
  };

  useEffect(() => {
    console.log("running useEffect");
    if (searchTerm === "") {
      // Aggregate all unique employees from the cache.
      const allCachedEmployees = Object.values(sessionCache).flat();
      const uniqueCachedEmployeesMap = {};

      allCachedEmployees.forEach((employee) => {
        uniqueCachedEmployeesMap[employee.id] = employee;
      });

      const uniqueCachedEmployees = Object.values(uniqueCachedEmployeesMap);

      setEmployees(uniqueCachedEmployees);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setEmployees(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchEmployees, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  // Helper function remains the same:
  const getResultsForField = async (field, term) => {
    const q = query(
      collection(db, "employees"),
      where(field, ">=", term),
      where(field, "<=", term + "\uf8ff"),
      limit(10)
    );
    const snapshot = await getDocs(q);
    const results = {};
    snapshot.forEach((doc) => {
      results[doc.id] = { id: doc.id, ...doc.data() };
    });
    return results;
  };

  // Updated searchEmployees function to scale to n tokens:
  const searchEmployees = async (searchText) => {

    if (!searchText.trim()) {
      setEmployees([]);
      return;
    }

    setLoading(true);
    const tokens = searchText.trim().split(/\s+/);

    console.log("searchEmployees - tokens: ", tokens);


    // For each token, get union results from both fields.
    const unionResultsForTokens = await Promise.all(
      tokens.map(async (token) => {
        const [firstResults, lastResults] = await Promise.all([
          getResultsForField("firstName", token),
          getResultsForField("lastName", token),
        ]);
        return { ...firstResults, ...lastResults };
      })
    );

    // Intersect the results across all tokens.
    const intersected = unionResultsForTokens.reduce((acc, curr) => {
      if (!acc) return curr;
      return Object.keys(acc).reduce((result, id) => {
        if (curr[id]) result[id] = acc[id];
        return result;
      }, {});
    }, null);

    const results = intersected ? Object.values(intersected) : [];
    setEmployees(results);
    setSessionCache((prevState) => ({ ...prevState, [searchText]: results }));
    setLoading(false);
  };

  const handleListItemClick = (employee) => {
    // Call the callback function passed through props
    onEmployeeSelect(employee);
    if (clearOnSelect) {
      setSearchTerm("");
      setEmployees([]);
      setSessionCache({});
    }
  };

  const renderEmployeeList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return employees.map((employee) => (
      <ListItemButton
        key={employee.id}
        onClick={() => handleListItemClick(employee)}
        sx={{ borderRadius: "15px" }}
      >
        <Avatar sx={{ marginRight: 2 }} src={employee.avatarUrl}></Avatar>
        <ListItemText
          primary={`${employee.firstName} ${employee.lastName}`}
          // secondary={`${employee.location.city}, ${employee.location.state}`} TODO replace with their current area location
        />
        {/* <Chip label={employee.status} /> */}
      </ListItemButton>
    ));
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for a helper..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearchTerm("")}
                  edge="end"
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <List>{renderEmployeeList()}</List>
    </>
  );
}

export default EmployeeSearchBar;
