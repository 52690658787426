import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";

function ContactDetails() {
  const theme = useTheme();
  const contactInfo = [
    {
      label: "Washington",
      phone: "(360) 336-6344",
      tel: "+13603366344",
    },
    {
      label: "Idaho",
      phone: "(208) 279-8808",
      tel: "+12082798808",
    },
    {
      label: "Oregon",
      phone: "(971) 717-3263",
      tel: "+19717173263",
    },
    {
      label: "California",
      phone: "(619) 604-6173",
      tel: "+16196046173",
    },
    {
      label: "All other states",
      phone: "1-(833) 433-0363",
      tel: "+18334330363",
    },
  ];

  return (
    <>
      {contactInfo.map(({ label, phone, tel }, index) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
          key={tel}
        >
          <Typography variant="body1" color="text.secondary">
            {label}:
          </Typography>
          <Typography variant="body1" fontWeight="800">
            <a
              href={`tel:${tel}`}
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              {phone}
            </a>
          </Typography>
        </Box>
      ))}

      <Divider sx={{ my: 2 }} />
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        General questions:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          info@ourLinkedLives.com
        </a>
      </Typography>
      {/* <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Support:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:support@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          support@ourLinkedLives.com
        </a>
      </Typography> */}
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Billing:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          billing@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Scheduling
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          scheduling@ourLinkedLives.com
        </a>
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color={"text.secondary"}>
        Hiring
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href="mailto:info@ourLinkedLives.com"
          style={{
            color: theme.palette.primary.main,
          }}
        >
          hiring@ourLinkedLives.com
        </a>
      </Typography>
      {/* <Typography variant="body1">
                Feel free to reach out anytime. We're here to help!
              </Typography> */}
    </>
  );
}

export default ContactDetails;
