import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { Box, DialogContent, ListItemButton } from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  query,
  startAfter,
  where,
  orderBy,
} from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import { useSession } from "../../providers/SessionProvider";
import HelperHighlight from "../ads/HelperHighlight";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import EmployeeCardV2 from "../employee/EmployeeCardV2";
import HelperHighlightSkeleton from "./HelperHighlightSkeleton";

const EmployeeCard = ({ employee }) => {
  return (
    <HelperHighlight
      helperData={employee}
      showSkills={false}
      showLocation={false}
      autoHeight={true}
      autoWidth={true}
      fontScale={1}
      bioLineClamp={3}
    />
  );
};

const HelperListPublic = () => {
  const [employees, setEmployees] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [open, setOpen] = useState(false);
  const { logSessionActivity = () => {} } = useSession();

  const initialLoadCount = 5;
  const pageSize = 3;

  const fetchEmployees = async (isInitial = false) => {
    if (loading && employees?.length > 0) return;
    setLoading(true);

    // await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      let q;
      if (isInitial) {
        q = query(
          collection(db, "employees"),
          where("status", "==", "active"),
          orderBy("admin.lastActive", "desc"),
          limit(initialLoadCount)
        );
      } else {
        q = query(
          collection(db, "employees"),
          where("status", "==", "active"),
          orderBy("admin.lastActive", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      setEmployees((prev) => [...prev, ...fetchedEmployees]);
      if (
        querySnapshot.docs.length < (isInitial ? initialLoadCount : pageSize)
      ) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployees(true);
  }, []);

  // Intersection observer to detect when the sentinel div is in view
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchEmployees();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Temporary card click function
  const handleCardClick = (employee) => {
    console.log("Employee card clicked:", employee);
    setSelectedEmployeeId(employee?.id);
    setOpen(true);
    logSessionActivity(
      `Clicked on ${employee?.firstName} ${employee?.lastName}'s helper profile.`
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {employees?.map((employee) => (
          <ListItemButton
            elevation={0}
            onClick={() => handleCardClick(employee)}
            variant="outlined"
            sx={{
              position: "relative",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "visible",
              borderRadius: "15px",
              width: "100%",
              maxWidth: "600px",
              p: 0,
            }}
          >
            <KeyboardArrowRightRounded
              color="primary"
              sx={{
                position: "absolute",
                top: "12px",
                right: "8px",
                fontSize: "32px",
              }}
            />
            <EmployeeCard key={employee.id} employee={employee} />
          </ListItemButton>
        ))}
        {loading &&
          [...Array(3)].map((_, index) => <HelperHighlightSkeleton />)}
        <div ref={lastElementRef} />
        <div style={{height: "24px"}} />
      </Box>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title={`Helper details`}
        width="600px"
      >
        <DialogContent sx={{ mx: 0, px: 0 }}>
          {selectedEmployeeId ? (
            <EmployeeCardV2
              userRole={"public"}
              employeeId={selectedEmployeeId}
              memberId={null}
              onClose={() => setOpen(false)}
              showBookingButton={false}
            />
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default HelperListPublic;
