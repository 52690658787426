import {
  ContentCopy,
  ExpandMore,
  StarOutlineRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  Accordion,
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  Fade,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import { db, functions, model } from "../firebase";
import { useSession } from "../providers/SessionProvider";
import { sendSMSClient } from "../services/communicationServices";
import DateTimeListItem from "./visit/items/DateTimeListItem";
import HelpersListItem from "./visit/items/HelpersListItem";

// import { touchRippleClasses } from "@mui/core";

const reviewSentimentPrompt = `
You are an AI that evaluates the quality of customer review texts. Analyze the review provided and assign a rating based solely on its content:
0: Negative - the review is negative or has bad sentiment.
1: Average – The review is brief, minimal, unemotional. Factual or only several words. Minimal words.
2: Great – The review offers clear details and helpful insights. Positive emotions. Personalized message.
3: Excellent – The review is comprehensive, detailed, and highly informative. Strong positive emotions. Personalized message. Words that envoke a smile. Expressive. Excited.
Return only the number (1, 2, or 3) without any additional commentary.
`;

// Add this utility function (e.g., right after your component imports)
const parseRating = (response = "") => {
  // 1. Try to directly parse an integer.
  let parsed = parseInt(response, 10);
  if ([0, 1, 2, 3].includes(parsed)) return parsed;

  // 2. Use regex to extract a digit from extra text.
  const match = response?.match(/(\d)/);
  if (match && [0, 1, 2, 3].includes(parseInt(match[0], 10))) {
    return parseInt(match[0], 10);
  }

  // 3. Map text-based numbers to digits.
  const mapping = { zero: 0, one: 1, two: 2, three: 3 };
  const lowerResponse = response.toLowerCase();
  for (const key in mapping) {
    if (lowerResponse.includes(key)) return mapping[key];
  }

  // Default to average rating if parsing fails.
  return 1;
};

const ReviewVisitMember = ({
  visit,
  visitId,
  handleClose,
  stars = null,
  keyParam = null,
}) => {
  const [rating, setRating] = useState(stars);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation
  const timeZoneId = visit?.timeZoneId;
  const navigate = useNavigate();

  const { createLog = () => {} } = useSession() || {};

  // Inside your component, add these new state variables:
  const [sentimentValue, setSentimentValue] = useState(null);
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [googleReview, setGoogleReview] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setRating(stars);
  }, [stars]);

  // Utility functions for date and time formatting
  const formatDate = (date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  const formatEmployeeNames = (employees) => {
    if (!employees || Object.keys(employees).length === 0) return;

    // Get accepted employees' names
    const names = Object.values(employees)
      .filter((emp) => emp?.responseStatus === "accepted")
      .map((emp) => emp?.displayName?.slice(0, -3));

    // Format names into a natural language list
    if (names.length === 0) return "";
    if (names.length === 1) return names[0];
    return (
      names.slice(0, -1).join(", ") +
      (names.length > 1 ? " and " : "") +
      names.slice(-1)
    );
  };

  const employeeNames = formatEmployeeNames(visit?.employees);

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    // [`& .${touchRippleClasses.rippleVisible}`]: {
    //   color: theme.palette.primary.main, // This sets the ripple color
    //   opacity: 0.6,
    // },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
  }));

  const handleSubmit = async () => {
    setLoading(true); // Start the loading state

    createLog({
      collectionName: "members",
      idsArr: [visit?.member],
      summary: `${visit?.displayName} left a ${rating}-star review for ${employeeNames}: ${review}`,
      logType: "activity",
    });

    // Reference to the visit document
    const visitDocRef = doc(db, "visits", visitId);

    let sentiment = null;

    // If there is review text, determine the text sentiment
    if (review) {
      const prompt = `${reviewSentimentPrompt}\n\n### Review Text:\n${review}`;
      const result = await model.generateContent(prompt);

      const response = result.response;

      // Parse and process the response
      const text = response.text();

      console.log("ReviewVisitMember - text: ", text);
      sentiment = parseRating(text);
      setSentimentValue(sentiment);
    }

    console.log("ReviewVisitMember - sentiment: ", sentiment);

    // If we have a key parameter, then we are calling this from public
    if (keyParam) {
      try {
        const submitReview = httpsCallable(functions, "submitReview");
        submitReview({
          visitId,
          rating,
          review,
          sentiment,
          key: keyParam,
        }).catch((error) => {
          console.error("Error submitting review:", error);
        });
        if (sentiment === 3) {
          sendSMSClient({
            phone: "3603366344",
            body: `${visit?.displayName} left an excellent review`,
          });

          handleFacebookPost();
        }

        setLoading(false);
        setShowConfirmation(true);
      } catch (error) {
        console.error("Error submitting review:", error);
        setLoading(false);
      }
    } else {
      try {
        await updateDoc(visitDocRef, {
          rating,
          review,
          sentiment,
        });

        // Send the review to the helper, apply boost.
        const handleReview = httpsCallable(functions, "handleReview");
        handleReview({
          employees: visit?.employees,
          memberName: visit?.displayName,
          rating,
          review,
          // sentiment, - not needed
          visitTags: visit?.tags,
        });
        if (sentiment === 3) {
          sendSMSClient({
            phone: "3603366344",
            body: `${visit?.displayName} left an excellent review`,
          });

          handleFacebookPost();
        }

        console.log("Document successfully updated!");
        setLoading(false); // Stop the loading state
        setShowConfirmation(true); // Show the confirmation
      } catch (error) {
        console.error("Error updating document: ", error);
        setLoading(false); // Stop the loading if there's an error
      }
    }
  };

  // Fire and forget post to fb if sentiment is 3

  const handleFacebookPost = async () => {
    const facebookReviewPostHandlerV2 = httpsCallable(
      functions,
      "facebookReviewPostHandlerV2"
    );
    try {
      const result = await facebookReviewPostHandlerV2({ reviewId: visitId });
      console.log("Facebook review post created:", result.data);
    } catch (error) {
      console.error("Failed to post Facebook review:", error.message);
    }
  };

  // Also inside your component, add these functions:

  const handleCopyAndOpenGoogle = () => {
    createLog({
      collectionName: "members",
      idsArr: [visit?.member],
      summary: `${visit?.displayName} opened the Google Review link`,
      logType: "activity",
    });

    sendSMSClient({
      phone: "3603366344",
      body: `${visit?.displayName} opened the Google Review link`,
    });

    navigator.clipboard.writeText(review);
    setCopiedAlert(true);
    window.open("https://g.page/r/Cday7ZBCdznQEAE/review", "_blank");
    setGoogleReview(true);
  };

  const handleCopy = () => {
    createLog({
      collectionName: "members",
      idsArr: [visit?.member],
      summary: `${visit?.displayName} copied the review body`,
      logType: "activity",
    });

    navigator.clipboard.writeText(review);
    setCopiedAlert(true);
  };

  // useEffect(() => {
  //   if (showConfirmation) {
  //     const timer = setTimeout(() => {
  //       setShowConfirmation(false); // Hide the confirmation
  //       handleClose(); // Close the entire component
  //     }, 4000); // 4 seconds delay
  //     return () => clearTimeout(timer); // Clear timer if component is unmounted
  //   }
  // }, [showConfirmation, handleClose]);

  return (
    <>
      <DialogContent>
        {/* Centered Rating Section */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Ensure it spans the full width
              gap: 2,
              minHeight: "300px",
              marginY: 2,
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : showConfirmation ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "300px",
              // width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
              marginX: "32px",
            }}
          >
            <ConfirmationBurst size="xlarge" />
            <Typography variant="h6" marginTop={2}>
              Received
            </Typography>
            {sentimentValue != 3 && (
              <Typography>
                Thanks for taking the time to let us know how we're doing. We're
                always working to provide the best service for you!
              </Typography>
            )}
            {sentimentValue === 3 && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {!googleReview ? (
                  <Typography variant="body2">
                    Thank you so much, your review is wonderful! Do you have a
                    minute to share it on Google Reviews? This really helps
                    others discover us online.
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Thanks for sharing your kind words on Google! Your support
                    helps others discover Linked Lives and means so much to us.
                  </Typography>
                )}
                {!googleReview && (
                  <>
                    <TextField
                      helperText={`${
                        isMobile ? "Tap" : "Click"
                      } to copy and then paste in Google review`}
                      onClick={handleCopy}
                      value={review}
                      fullWidth
                      multiline
                      maxRows={4}
                      sx={{
                        cursor: "pointer",
                        "& .MuiInputBase-root": {
                          cursor: "pointer",
                        },
                        "& .MuiInputBase-input": {
                          cursor: "pointer",
                        },
                        "& .MuiOutlinedInput-input": {
                          cursor: "pointer",
                        },
                        "& .MuiInputBase-inputMultiline": {
                          cursor: "pointer",
                        },
                      }}
                      // InputProps={{
                      //   endAdornment: (
                      //     <Button
                      //       variant="outlined"
                      //       sx={{ textTransform: "none" }}
                      //     >
                      //       Copy
                      //     </Button>
                      //   ),
                      // }}
                    />
                    <Collapse in={copiedAlert}>
                      <Alert severity="success" icon={<ContentCopy />}>
                        Review copied to clipboard!
                      </Alert>
                    </Collapse>
                  </>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <>
            {/* Visit Details Accordion */}
            <Accordion disableGutters elevation={0}>
              <AccordionSummary
                sx={{ marginBottom: "16px" }}
                expandIcon={<ExpandMore color="primary" />}
              >
                <Typography color="primary">Visit Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DateTimeListItem visitData={visit} userRole={"member"} />
                <HelpersListItem
                  visitData={visit}
                  memberId={visit?.member}
                  userRole={"member"}
                  onlyAccepted={true}
                />
              </AccordionDetails>
            </Accordion>
            <Box
              sx={{
                display: "flex",
                position: "sticky",
                top: isMobile ? "32vh" : undefined,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%", // Ensure it spans the full width
                gap: 2,
                marginY: 2,
              }}
            >
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" align="center">
                  How was your visit?
                </Typography>
                <Typography variant="center">with {employeeNames}</Typography>
              </Box>

              {/* Star Rating */}
              <Rating
                size="large"
                value={rating}
                name="visit-rating"
                icon={<StarRounded fontSize="inherit" />}
                emptyIcon={<StarOutlineRounded fontSize="inherit" />}
                sx={{ fontSize: "3rem" }} // Adjust the size as needed
                onChange={(event, newRating) => {
                  setRating(newRating);
                }}
                precision={1}
              />
              {/* Comment Section */}
              <Fade in={!!rating}>
                <TextField
                  label="Leave a comment (optional)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </Fade>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: googleReview ? "column-reverse" : "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          {sentimentValue === 3 && showConfirmation && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                mb: 1,
              }}
            >
              <Button
                variant={!googleReview ? "contained" : "text"}
                onClick={handleCopyAndOpenGoogle}
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "60px",
                }}
              >
                {googleReview ? "Re-open Google Review" : "Open Google Review"}
              </Button>
              {/* <Button
                variant="outlined"
                onClick={handleOpenGoogleReview}
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "48px",
                }}
              >
                Go to Google Review
              </Button> */}
            </Box>
          )}
          <Button
            disabled={!rating || loading} // Disable the button if loading
            sx={{
              width: "100%",
              height: "60px",
              textTransform: "none",
            }}
            onClick={
              showConfirmation
                ? keyParam
                  ? () => navigate("/dashboard")
                  : handleClose
                : handleSubmit
            }
            variant={
              sentimentValue === 3 && showConfirmation && !googleReview
                ? "text"
                : "contained"
            }
            color="primary"
          >
            {showConfirmation
              ? keyParam
                ? "Go to Dashboard"
                : sentimentValue === 3 && !googleReview
                ? "Skip and Close"
                : "Close"
              : "Submit review"}
          </Button>
          {!showConfirmation && (
            <Typography
              variant="caption"
              color={"text.secondary"}
              align="center"
              sx={{ mt: 1 }}
            >
              {keyParam &&
                "To turn off review notifications, go to your dashboard settings. "}
              Your rating, review, and task description will be public.
            </Typography>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default ReviewVisitMember;
