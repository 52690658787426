import { Box, DialogContent, Skeleton, Typography } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import NotificationsTab from "../../dashboard/employee/tabs/NotificationsTab";
import { db } from "../../firebase";
import useEmployeeData from "../../hooks/useEmployeeData";
import { useEmployee } from "../../providers/EmployeeProvider";
import { useMember } from "../../providers/MemberProvider";
import { useSession } from "../../providers/SessionProvider";
import {
  getCurrentArea,
  getEmployeeStatus,
} from "../../services/locationServices";
import CardTabs from "../crm/tabs/CardTabs";
import ProgressTab from "../progress/ProgressTab";
import RatingSummary from "../ratings/RatingSummary";
import VisitReviewList from "../reviews/VisitReviewList";
import ServiceAreasList from "../serviceArea/ServiceAreasList";
import TaskChips from "../tasks/TaskChips";
import AboutMe from "./AboutMe";
import ApplicationInfo from "./ApplicationInfo";
import EmployeeBio from "./EmployeeBio";
import EmployeeInfo from "./EmployeeInfo";
import EmployeeSchedule from "./EmployeeSchedule";
import ServiceMap from "./ServiceMap";
import TimelineCompact from "./TimelineCompact";

const EmployeeCardV2 = ({
  employeeId = "",
  memberId = "", // used for following the helper
  userRole = "member",
  showBookingButton = true,
  // coordinates = null,
  onClose = () => {},
}) => {
  const [realTimeMemberPrivateData, setRealTimeMemberPrivateData] =
    useState(null);

  const {
    employeeData = {},
    employeePrivateData = {},
    employeeAdminData = {},
    visitsData,
    loading,
  } = useEmployeeData(employeeId);

  // Call hooks and provide default values
  const { employeeData: selfEmployeeData = {} } = useEmployee() || {}; // Default to an empty object if undefined
  const { memberPrivateData = {} } = useMember() || {}; // Default to an empty object if undefined
  const { createLog = () => {} } = useSession() || {};

  console.log("EmployeeCardV2 - memberPrivateData:", memberPrivateData);

  // Add snapshot listener for "membersPrivate" if `memberPrivateData` is undefined
  useEffect(() => {
    if (!memberId || Object.keys(memberPrivateData).length > 0) return;

    const memberDocRef = doc(db, "membersPrivate", memberId);

    const unsubscribe = onSnapshot(
      memberDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setRealTimeMemberPrivateData(snapshot.data());
        } else {
          console.warn(`No member data found for memberId: ${memberId}`);
          setRealTimeMemberPrivateData({}); // Default to empty object
        }
      },
      (error) => {
        console.error("Error listening to memberPrivateData:", error);
      }
    );

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  const handleLogTabChange = (newValue) => {
    if (userRole === "member" && memberId) {
      console.log("testing in here");
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Viewed ${employeeData?.firstName}'s "${tabs[newValue]?.label}" tab.`,
        logType: "activity",
      });
    }
  };

  // Use realTimeMemberPrivateData if memberPrivateData is undefined
  const effectiveMemberPrivateData =
    Object.keys(memberPrivateData).length > 0
      ? memberPrivateData
      : realTimeMemberPrivateData || {};

  const { areaKey = "", areaData = {} } =
    getCurrentArea(employeeData?.areas) || {};

  // Determine userName based on userRole
  // Determine coordinates based on userRole
  const coordinates =
    userRole === "employee" || userRole === "admin"
      ? {
          lat: getCurrentArea(selfEmployeeData?.areas)?.areaData?.lat || null,
          lng: getCurrentArea(selfEmployeeData?.areas)?.areaData?.lng || null,
        }
      : userRole === "member"
      ? {
          lat: memberPrivateData?.location?.lat || null,
          lng: memberPrivateData?.location?.lng || null,
        }
      : { lat: null, lng: null }; // Default if no valid role

  console.log("coordinates: ", coordinates);
  console.log("employeeData: ", employeeData);
  console.log("visitsData: ", visitsData);

  if (loading || employeeData === null) {
    return (
      <Box px={2} py={3}>
        {[...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={40 + index * 10} // each item slightly taller
            sx={{ mb: 2, borderRadius: "10px" }}
          />
        ))}
      </Box>
    );
  }

  const {
    inArea = false,
    futureAvailability = 0,
    end,
    start,
  } = getEmployeeStatus(
    coordinates?.lat,
    coordinates?.lng,
    employeeData.areas,
    employeeData.availability
  );

  const { ratings = {} } = employeePrivateData || {};

  const { ratingsHistogram = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } } = ratings || {};

  // Add these lines right after the ratings destructuring:
  const totalReviews = Object.values(ratingsHistogram).reduce(
    (acc, count) => acc + count,
    0
  );

  // Define the structure to map tabs to components
  const tabs = [
    // userRole === "admin"
    //   ? {
    //       label: "Bounds",
    //       component: <BoundsCover bounds={areaData?.bounds} />,
    //     }
    //   : null,
    {
      label: "My Background",
      component: <AboutMe employeeData={employeeData} />,
    },
    totalReviews > 0
      ? {
          label: "Reviews",
          component: (
            <>
              <RatingSummary ratings={employeeData?.ratings} />
              <div style={{ height: "24px" }} />
              <VisitReviewList
                employeeId={employeeId}
                STAR_MIN={4}
                maxWidth="auto"
              />
            </>
          ),
        }
      : null,
    userRole === "admin"
      ? {
          label: "Application",
          component: (
            <ApplicationInfo employeePrivateData={employeePrivateData} />
          ),
        }
      : null,
    userRole !== "public"
      ? {
          label: "Service Map",
          component: (
            <ServiceMap
              areaData={areaData}
              areaKey={areaKey}
              areas={employeeData?.areas}
              employeeId={employeeId}
              userRole={userRole}
            />
          ),
        }
      : null,
    userRole === "employee" || userRole === "admin"
      ? {
          label: "Timeline",
          component: (
            <TimelineCompact
              areaData={areaData}
              areas={employeeData?.areas}
              employeeId={employeeId}
              userRole={userRole}
            />
          ),
        }
      : null,
    userRole === "admin"
      ? {
          label: "Progress",
          component: (
            <ProgressTab
              showHeader={false}
              progress={employeePrivateData?.progress}
              currentLevel={employeeData?.currentLevel}
              employeeId={employeeId}
              employeeName={`${employeeData?.firstName} ${employeeData?.lastName}`}
              avatarUrl={employeeData?.avatarUrl}
              hourlyRate={employeeAdminData?.hourlyRate}
            />
          ),
        }
      : null,
    userRole === "admin"
      ? {
          label: "Notifications",
          component: (
            <NotificationsTab
              employeeId={employeeId}
              initialNotifications={employeePrivateData?.notifications}
            />
          ),
        }
      : null,
    userRole === "employee" || userRole === "admin"
      ? {
          label: "Schedule",
          component: (
            <EmployeeSchedule
              employeeData={employeeData}
              visitsData={visitsData}
              userRole={userRole}
            />
          ),
        }
      : null,
    userRole === "admin" || userRole === "employee"
      ? {
          label: "Service Areas",
          component: (
            <Box
              sx={{
                gap: 1,
                backgroundColor: "#F5F5F5",
                p: 2,
                mt: 1,
                px: 0,
                borderRadius: "15px",
                boxSizing: "border-box",
              }}
            >
              <ServiceAreasList
                titleVariant={"body1"}
                employeeData={employeeData}
                employeeId={employeeId}
              />
            </Box>
          ),
        }
      : null,
  ].filter(Boolean);

  return (
    <>
      <DialogContent>
        {/* <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <strong>In Area:</strong> {inArea ? "Yes" : "No"}
          </Typography>
          <Typography variant="body1">
            <strong>Start:</strong>{" "}
            {start ? start.toFormat("MMM d, yyyy") : "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>End:</strong> {end ? end.toFormat("MMM d, yyyy") : "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Total Future Hours:</strong> {futureAvailability.toFixed(0)}{" "}
            hours
          </Typography>
        </Box> */}
        <EmployeeInfo
          employeeData={employeeData}
          employeePrivateData={employeePrivateData}
          memberPrivateData={effectiveMemberPrivateData}
          memberId={memberId}
          userRole={userRole}
          inArea={inArea}
          futureAvailability={futureAvailability}
          serviceStart={start}
          serviceEnd={end}
          employeeId={employeeId}
          areaData={areaData}
          onClose={onClose}
          showBookingButton={showBookingButton}
        />
        <div style={{ height: "16px" }} />
        <EmployeeBio employeeData={employeeData} />
        <Typography variant="body2" sx={{ pl: { xs: 0, sm: 2 }, pb: 1 }}>
          <strong>My skills</strong>
        </Typography>
        <TaskChips tasks={employeeData.tasks} />
        <div style={{ height: "16px" }} />
        <CardTabs
          tabs={tabs}
          memberId={memberId}
          userRole={userRole}
          onChange={handleLogTabChange}
        />
      </DialogContent>
    </>
  );
};

export default EmployeeCardV2;
