// LazyEmployeeCard.js
import { Box, Skeleton } from "@mui/material";
import React, { Suspense } from "react";

const EmployeeCardV2 = React.lazy(() => import("../employee/EmployeeCardV2"));

const LazyEmployeeCard = ({
  employeeId,
  userRole = "member",
  memberId = "",
  onClose,
  showBookingButton = true,
}) => {
  return (
    <>
      <Suspense
        fallback={
          <Box px={2} py={3}>
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={40 + index * 10} // each item slightly taller
                sx={{ mb: 2, borderRadius: "10px" }}
              />
            ))}
          </Box>
        }
      >
        <EmployeeCardV2
          userRole={userRole}
          employeeId={employeeId}
          memberId={memberId}
          onClose={onClose}
          showBookingButton={showBookingButton}
        />
      </Suspense>
    </>
  );
};

export default LazyEmployeeCard;
