import { Edit, FiberManualRecord } from "@mui/icons-material";
import { Box, ListItemButton, Typography } from "@mui/material";
import React from "react";
import GoogleMapsDisplay from "../../dashboard/employee/tabs/GoogleMapsDisplay";

const ServiceAreaListItem = ({
  isActive = false,
  areaKey,
  area,
  handleItemClick,
  employeeData,
}) => {
  console.log("ServiceAreaListItem - areaKey: ", areaKey);

  return (
    <>
      <ListItemButton
        onClick={() => handleItemClick(areaKey)}
        sx={{
          p: { xs: 2, sm: 2 },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F5F5F5",
          borderRadius: "15px",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {isActive && <FiberManualRecord color="success" sx={{fontSize: "16px"}} />}
            <Typography variant="body1" fontWeight={"bold"}>
              {`${area.city}, ${area.state}`}
            </Typography>
          </Box>

          <Edit />
        </Box>

        <Box sx={{ width: "100%", height: { xs: "100%" }, mt: 1 }}>
          {area?.bounds?.length > 0 && (
            <GoogleMapsDisplay
              buttonText={"Save"}
              employeeGeohash={area?.geohash6}
              initialBounds={area?.bounds || []}
              desktopHeight={"200px"}
              mobileHeight={"150px"}
              initialCenter={{
                lat: area?.lat || "",
                lng: area?.lng || "",
              }}
            />
          )}
        </Box>
      </ListItemButton>
    </>
  );
};

export default ServiceAreaListItem;
