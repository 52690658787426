import {
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { db } from "../firebase";
import { getEmployeeStatus } from "../services/locationServices";
import { calculateVisits, getMemberStatus } from "../services/memberServices";
import { useMember } from "./MemberProvider";

const HelpersContext = createContext();

const HelpersProvider = ({ lat, lng, children }) => {
  const [employeesData, setEmployeesData] = useState({});
  const [loadingEmployees, setLoadingEmployees] = useState(true);

  const [visitsData, setVisitsData] = useState({});
  const [loadingVisits, setLoadingVisits] = useState(true);

  const [availabilityData, setAvailabilityData] = useState({});
  const [loadingAvailability, setLoadingAvailability] = useState(true);

  const [slotsData, setSlotsData] = useState({});
  const [loadingSlots, setLoadingSlots] = useState(true);

  const [serviceStatus, setServiceStatus] = useState(null);
  const [loadingServiceStatus, setLoadingServiceStatus] = useState(true);

  const timeZoneId = "America/Los_Angeles";

  const { memberPrivateData = {} } = useMember() || {};

  // Memoize effectiveLat and effectiveLng
  const effectiveLat = useMemo(
    () => lat ?? memberPrivateData?.location?.lat,
    [lat, memberPrivateData?.location?.lat]
  );
  const effectiveLng = useMemo(
    () => lng ?? memberPrivateData?.location?.lng,
    [lng, memberPrivateData?.location?.lng]
  );

  console.log("HelpersProvider - effectiveLat: ", effectiveLat);
  console.log("HelpersProvider - effectiveLng: ", effectiveLng);

  // Using a ref to store current unsubscribe functions to avoid triggering effect updates
  const unsubscribesRef = useRef([]);

  // Track existing employee visit listeners outside the useEffect
  const employeeVisitListeners = useRef(new Set());

  function convertTimestampToDateString(timestamp) {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString();
    } else if (timestamp instanceof Date) {
      return timestamp.toLocaleString();
    }
    return "Invalid timestamp";
  }

  console.log("loadingEmployees: ", loadingEmployees);
  console.log("loadingVisits: ", loadingVisits);
  console.log("loadingAvailability: ", loadingAvailability);
  console.log("loadingSlots: ", loadingSlots);
  console.log("loadingServiceStatus: ", loadingServiceStatus);

  // Function to reset unsubscribesRef and employeeVisitListeners
  const resetRefs = () => {
    // Unsubscribe from all current listeners
    if (Array.isArray(unsubscribesRef.current)) {
      unsubscribesRef.current.forEach((entry) => {
        if (entry && typeof entry.unsubscribe === "function") {
          try {
            entry.unsubscribe(); // Safely call unsubscribe
          } catch (error) {
            console.error("Error during unsubscribe:", error);
          }
        } else {
          console.warn("Invalid entry in unsubscribesRef:", entry);
        }
      });
      unsubscribesRef.current = []; // Reset to empty array
    } else {
      console.warn(
        "unsubscribesRef.current is not an array:",
        unsubscribesRef.current
      );
    }

    // Clear the employeeVisitListeners Set
    if (employeeVisitListeners.current instanceof Set) {
      employeeVisitListeners.current.clear(); // Clear all stored listeners
    } else {
      console.warn(
        "employeeVisitListeners.current is not a Set:",
        employeeVisitListeners.current
      );
    }
  };

  useEffect(() => {
    // Unsubscribe from any previous listeners
    unsubscribesRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribesRef.current = [];

    if (!effectiveLat || !effectiveLng) return; // Exit early if no valid coordinates

    setLoadingEmployees(true);

    const geohash5 = geohashForLocation([effectiveLat, effectiveLng], 5); // Generate geohash

    // Firestore query for active employees in the geohash area
    const q = query(
      collection(db, "employees"),
      where("status", "==", "active"),
      where("geohash5Arr", "array-contains", geohash5)
    );

    // Subscribe to Firestore changes
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedEmployeesData = {};

      querySnapshot.forEach((doc) => {
        const employeeData = doc.data();
        const { inArea, start, end } = getEmployeeStatus(
          effectiveLat,
          effectiveLng,
          employeeData.areas,
          employeeData.availability
        );

        console.log(`getEmployeeStatus - inArea: ${inArea} start: ${start} end: ${end}`);

        if (
          inArea 
          && typeof start !== "undefined"
          && typeof end !== "undefined"
        ) {
          updatedEmployeesData[doc.id] = {
            ...employeeData,
            startDate: start,
            endDate: end,
          };
        }
      });

      // Update employeesData state
      setEmployeesData(updatedEmployeesData);
      setLoadingEmployees(false);
    });

    // Store unsubscribe function
    unsubscribesRef.current.push(unsubscribe);

    // Cleanup function to unsubscribe from all listeners
    return () => {
      if (unsubscribesRef.current.length > 0) {
        unsubscribesRef.current.forEach((unsubscribe) => {
          if (typeof unsubscribe === "function") {
            unsubscribe(); // Ensure valid function call
          }
        });
        unsubscribesRef.current = [];
      }
      // Reset all data when lat lng changes
      setEmployeesData({});
      setVisitsData({});
      setAvailabilityData({});
      setSlotsData({});
      resetRefs();
      setLoadingServiceStatus(true);
    };
  }, [effectiveLat, effectiveLng]); // Re-run only when lat or effectiveLng changes

  // Function to subtract windows from visits
  function subtractWindowsFromVisits(
    windows,
    visits,
    sequentialVisits,
    limitDrivingRadiusMiles
  ) {
    const adjustedWindows = [];
    let visitIndex = 0;
    let windowIndex = 0;

    // Convert objects to arrays and sort them
    const windowsArray = Object.values(windows).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );
    const visitsArray = Object.values(visits).sort(
      (a, b) => a.start.toMillis() - b.start.toMillis()
    );

    console.log("Windows:");
    windowsArray.forEach((window, index) => {
      console.log(`Window ${index + 1}`);
      console.log(`  Start: ${convertTimestampToDateString(window.start)}`);
      console.log(`  End: ${convertTimestampToDateString(window.end)}`);
    });

    console.log("Visits:");
    visitsArray.forEach((visit, index) => {
      console.log(`Visit ${index + 1}`);
      console.log(`  Start: ${convertTimestampToDateString(visit.start)}`);
      console.log(`  End: ${convertTimestampToDateString(visit.end)}`);
    });

    while (windowIndex < windowsArray.length) {
      const window = windowsArray[windowIndex];

      if (!window.hasOwnProperty("sequentialVisits")) {
        window.sequentialVisits = sequentialVisits; // Replace with the desired default value
      }

      if (!window.hasOwnProperty("limitDrivingRadiusMiles")) {
        window.limitDrivingRadiusMiles = limitDrivingRadiusMiles; // Replace with the desired default value
      }

      if (visitIndex < visitsArray.length) {
        const visit = visitsArray[visitIndex];

        if (visit.end.toMillis() <= window.start.toMillis()) {
          // Visit ends before window starts, move to next visit
          visitIndex++;
        } else if (visit.start.toMillis() >= window.end.toMillis()) {
          // Visit starts after window ends, add whole window to adjustedWindows
          adjustedWindows.push(window);
          windowIndex++;
        } else {
          // Visit is within the window. Split the window.
          if (window.start.toMillis() < visit.start.toMillis()) {
            adjustedWindows.push({
              ...window, // Spread all existing properties from the window object
              start: window.start,
              end: visit.start,
              endGeohash6: visit.geohash6,
              // ...(window.startGeohash6 && {
              //   startGeohash6: window.startGeohash6,
              // }),
            });
          }
          // Set the start of the window to the end of the visit for the next loop iteration
          // If the adjusted window has zero length or is negative, skip to the next window
          if (window.end.toMillis() > visit.end.toMillis()) {
            window.startGeohash6 = visit.geohash6;
            window.start = visit.end;
            visitIndex++;
          } else {
            windowIndex++;
          }
        }
      } else {
        // No more visitsArray to check against, just add the remaining windowsArray
        adjustedWindows.push(window);
        windowIndex++;
      }
    }

    // console.log("adjusted windows:");
    // adjustedWindows.forEach((window) => {
    //   console.log("-------");
    //   console.log("Start:", window.start.toDate().toLocaleString());
    //   console.log("End:", window.end.toDate().toLocaleString());
    //   console.log("startGeohash6:", window.startGeohash6);
    //   console.log("endGeohash6:", window.endGeohash6);
    // });

    return adjustedWindows;
  }

  // Function to pad visits to give travel time
  function padVisits(visits, paddingMinutes) {
    return Object.entries(visits).reduce((acc, [id, visit]) => {
      if (!visit.start || !visit.end) {
        return acc;
      }

      acc[id] = {
        start: new Date(
          visit.start.toDate().getTime() - paddingMinutes * 60 * 1000
        ),
        end: new Date(
          visit.end.toDate().getTime() + paddingMinutes * 60 * 1000
        ),
        geohash6: visit.location.geohash,
      };

      // Convert back to Firebase Timestamp
      acc[id].start = Timestamp.fromMillis(acc[id].start);
      acc[id].end = Timestamp.fromMillis(acc[id].end);

      return acc;
    }, {});
  }

  // Fetch  visits independently
  useEffect(() => {
    if (loadingEmployees) return;

    if (!employeesData || Object.keys(employeesData).length === 0) {
      setVisitsData({});
      setLoadingVisits(false);
      console.log("HelpersProvider - Early return no employees found");
      return;
    }

    // Track the promises of all snapshots
    const visitFetchPromises = [];

    Object.entries(employeesData)
      .slice(0, 20) // Limit to the first 20 employees for performance
      .forEach(([employeeId]) => {
        if (employeeVisitListeners.current.has(employeeId)) return; // Skip if listener already exists

        setLoadingVisits(true);

        employeeVisitListeners.current.add(employeeId); // Mark listener as set up

        const startOfDay = moment().startOf("day").toDate();

        const visitsQuery = query(
          collection(db, "visits"),
          where("employeeArr", "array-contains", employeeId), // assumes that must be needsAction or accepted to be present in employeeArr. IS this true?
          where("status", "==", "confirmed"),
          where("start", ">=", startOfDay), // Fetch visits from today onward
        );

        // Add a snapshot listener for visits
        // Create a promise for the snapshot listener
        const visitFetchPromise = new Promise((resolve, reject) => {
          const unsubscribe = onSnapshot(
            visitsQuery,
            (snapshot) => {
              const visits = snapshot.docs.reduce((acc, doc) => {
                acc[doc.id] = doc.data();
                return acc;
              }, {});

              console.log("FETCHING VISITS BC CHANGED")

              console.log("visits: ", visits)

              // Update visitsData state, grouped by employeeId
              setVisitsData((prevData) => ({
                ...prevData,
                [employeeId]: visits,
              }));
              resolve(); // Mark the fetch as completed
            },
            (error) => {
              console.error("Error fetching visits for", employeeId, error);
              reject(error); // Handle errors
            }
          );

          // Store the unsubscribe function for cleanup
          unsubscribesRef.current.push({ employeeId, unsubscribe });
        });

        // Add the promise to the list
        visitFetchPromises.push(visitFetchPromise);
      });

    // Wait for all promises to resolve
    Promise.all(visitFetchPromises)
      .then(() => {
        setLoadingVisits(false); // All visits loaded
      })
      .catch((error) => {
        console.error("Error loading visits:", error);
        setLoadingVisits(false); // Ensure loading state is turned off even in case of errors
      });

    // Cleanup function
    return () => {
      if (Array.isArray(unsubscribesRef.current)) {
        // Filter out listeners for employees no longer in employeesData
        const employeesToRemove = unsubscribesRef.current.filter(
          (entry) =>
            entry && entry.employeeId && !employeesData[entry.employeeId] // Ensure entry and employeeId exist
        );

        employeesToRemove.forEach((entry) => {
          if (entry && typeof entry.unsubscribe === "function") {
            try {
              entry.unsubscribe(); // Safely call unsubscribe
              employeeVisitListeners.current.delete(entry.employeeId); // Remove from active listeners
            } catch (error) {
              console.error(
                `Error unsubscribing for employeeId: ${entry.employeeId}`,
                error
              );
            }
          } else {
            console.warn(
              `Invalid entry or unsubscribe function for employeeId: ${entry?.employeeId}`,
              entry
            );
          }
        });

        // Retain listeners for current employees
        unsubscribesRef.current = unsubscribesRef.current.filter(
          (entry) =>
            entry && entry.employeeId && employeesData[entry.employeeId] // Ensure valid entry and employeeId exists in employeesData
        );
      } else {
        console.warn(
          "unsubscribesRef.current is not an array. Cleanup skipped.",
          unsubscribesRef.current
        );
      }
    };
  }, [employeesData, loadingEmployees]);

  useEffect(() => {
    console.log("Calculating employee availability...");

    if (loadingVisits || loadingEmployees) return;

    // Compute updated availability data
    const computeAvailabilityData = () => {
      const updatedAvailabilityData = {};

      Object.entries(employeesData).forEach(([employeeId, employeeData]) => {
        const employeeVisits = visitsData[employeeId]; // Get visits for this employee
        if (!employeeData.availability || !employeeVisits) return; // Skip if data is incomplete

        const {
          bookingDaysInAdvance = 0, // default to 0
          isAvailableToday,
          sequentialVisits,
          limitDrivingRadiusMiles,
          avatarUrl,
          firstName,
          lastName,
          startDate = null,
          endDate = null,
          availability: { windows = {} } = {},
        } = employeeData;

        console.log("HelpersProvider - windows: ", windows);

        // Convert availability windows to Firestore Timestamps
        const tempWindows = Object.entries(windows).reduce(
          (acc, [key, window]) => {
            acc[key] = {
              ...window,
              start: new Timestamp(
                window.start.seconds,
                window.start.nanoseconds
              ),
              end: new Timestamp(window.end.seconds, window.end.nanoseconds),
            };
            return acc;
          },
          {}
        );

        // Pad visits and calculate available windows
        const paddedVisits = padVisits(employeeVisits, 30); // Pad visits
        const availableWindows = subtractWindowsFromVisits(
          tempWindows,
          paddedVisits,
          sequentialVisits,
          limitDrivingRadiusMiles
        );

        // Add to updated availability data
        updatedAvailabilityData[employeeId] = {
          availableWindows,
          bookingDaysInAdvance,
          sequentialVisits,
          limitDrivingRadiusMiles,
          isAvailableToday,
          avatarUrl,
          firstName,
          lastName,
          startDate,
          endDate,
        };
      });

      return updatedAvailabilityData;
    };

    setLoadingAvailability(true);

    // Update availability data
    const updatedData = computeAvailabilityData();
    setAvailabilityData(updatedData);
    setLoadingAvailability(false);
  }, [employeesData, loadingEmployees, visitsData, loadingVisits]);

  useEffect(() => {
    if (loadingAvailability) return;

    if (!availabilityData || Object.keys(availabilityData).length === 0) {
      setSlotsData({});
      setLoadingSlots(false);
      console.log("HelpersProvider - No availabiliity data found");
      return;
    }

    const calculateSlots = async () => {
      try {
        console.log("Calculating slots...");
        // setLoadingSlots(true);

        // Generate geohash
        const geohash = geohashForLocation([effectiveLat, effectiveLng]);

        // Await the calculateVisits function
        const calculatedSlots = await calculateVisits(
          availabilityData,
          timeZoneId,
          geohash
        );

        // Update state with the computed slots data
        setSlotsData(calculatedSlots);

        console.log("Slots data set, setting loadingSlots to false...");

        setLoadingSlots(false);
      } catch (error) {
        console.error("Error calculating slots:", error);
      }
    };

    // Invoke the async function
    calculateSlots();
  }, [availabilityData, loadingAvailability]);

  // Calculate the status for the lat / lng
  useEffect(() => {
    if (!effectiveLat || !effectiveLng || loadingSlots) return; // Exit early if no valid coordinates

    const totalAvailableHours = calculateTotalAvailability();
    console.log("HelpersProvider - totalAvailableHours: ", totalAvailableHours);

    // Calculate and add total availability to each employee in employeesData
    const employeesWithAvailability = Object.entries(employeesData).reduce(
      (acc, [employeeId, employee]) => {
        const totalAvailableHours = calculateTotalAvailability({
          employeeId, // Use the employeeId from the map
        });

        const nextAvailableDate = getNextAvailableDate({ employeeId });

        acc[employeeId] = {
          ...employee,
          totalAvailableHours, // Add the calculated total availability
          nextAvailableDate,
        };
        return acc;
      },
      {}
    );

    console.log(
      "HelpersProvider - employeesWithAvailability: ",
      employeesWithAvailability
    );

    // Pass the updated employees data to the status function
    const status = getMemberStatus(employeesWithAvailability, {
      lat: effectiveLat,
      lng: effectiveLng,
    });

    console.log("HelpersProvider - status: ", status);

    // Set the service status
    setServiceStatus({ ...status, totalAvailableHours });
    setLoadingServiceStatus(false);
  }, [slotsData, loadingSlots]);

  const getNextAvailableDate = ({
    employeeId = null,
    frequency = 0,
    duration = 2,
  } = {}) => {
    // Ensure slotsData is available
    if (!slotsData || !Array.isArray(slotsData) || slotsData.length === 0) {
      console.error("No slots data available");
      return null;
    }

    const now = DateTime.now();

    // Filter and sort slots to find the next available date
    const nextSlot = slotsData
      .filter((slot) => {
        const slotDuration = slot.endDate.diff(slot.startDate, "hours").hours;

        return (
          slotDuration >= duration && // Ensure the slot meets the duration requirement
          slot.startDate >= now && // Slot starts in the future
          (frequency === 0 || slot.frequency.includes(frequency)) && // Match frequency
          (employeeId === null || slot.employeeIds.includes(employeeId)) // Check employeeId if provided
        );
      })
      .sort((a, b) => a.startDate - b.startDate) // Sort by startDate
      .shift(); // Get the first valid slot

    return nextSlot ? nextSlot.startDate : null;
  };

  // TODO: This sums all durations, which gives over representative availability.
  // Not sure if ripple effect downstream though
  const calculateTotalAvailability = ({
    employeeId = null,
    numEmployees = 1,
    duration = 2,
    frequency = 0,
    startDate = null,
    endDate = null,
  } = {}) => {
    // Ensure slotsData is available and is a valid array
    if (!slotsData || !Array.isArray(slotsData) || slotsData.length === 0) {
      console.error("No slots data available");
      return 0;
    }

    // Filter and calculate total availability from slots
    return slotsData
      .filter((slot) => {
        const slotDuration = slot.endDate.diff(slot.startDate, "hours").hours;

        return (
          slotDuration >= duration && // Ensure the slot duration meets the requirement
          (frequency === 0 || slot.frequency.includes(frequency)) && // Match frequency
          (!startDate || slot.startDate >= startDate) && // Match startDate range
          (!endDate || slot.endDate <= endDate) && // Match endDate range
          (employeeId === null || slot.employeeIds.includes(employeeId)) && // Check if employeeId is present
          slot.employeeIds.length === numEmployees // Match the required number of employees
        );
      })
      .reduce((total, slot) => {
        const slotDuration = slot.endDate.diff(slot.startDate, "hours").hours;
        return total + Math.min(slotDuration, duration); // Add the valid portion of the slot duration
      }, 0);
  };

  console.log("HelpersProvider - employeesData: ", employeesData);
  console.log("HelpersProvider - visitsData: ", visitsData);
  console.log("HelpersProvider - availabilityData: ", availabilityData);
  console.log("HelpersProvider - slotsData: ", slotsData);
  console.log("HelpersProvider - loadingSlots: ", loadingSlots);

  return (
    <HelpersContext.Provider
      value={{
        visitsData,
        slotsData,
        employeesData,
        loadingSlots,
        serviceStatus,
        loadingServiceStatus,
        getNextAvailableDate,
        calculateTotalAvailability,
      }}
    >
      {children}
    </HelpersContext.Provider>
  );
};

export default HelpersProvider;

export const useHelpers = () => {
  return useContext(HelpersContext);
};
