import {
  NotificationAdd,
  NotificationsActiveOutlined,
  NotificationsOff,
} from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { db, messaging } from "../../firebase";
import { useAuth } from "../../providers/AuthProvider";

const EnableNotificationsButton = () => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const [notificationStatus, setNotificationStatus] = useState("checking");

  const isMobile = useMediaQuery("(max-width:600px)");

  const isStandalone =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone;

  useEffect(() => {
    // Check the current notification permission status
    if (Notification?.permission === "granted") {
      setNotificationStatus("enabled");
    } else if (Notification?.permission === "denied") {
      setNotificationStatus("blocked");
    } else {
      setNotificationStatus("default");
    }
  }, []);

  // Early return if we are not installed
  if (!isStandalone || !isMobile) {
    return null;
  }

  const handleEnableNotifications = async () => {
    const token = await handleRequestNotifications();
    if (token) {
      // Store token in Firestore under the user's document
      await setDoc(
        doc(db, "employeesPrivate", userId),
        { fcmToken: token },
        { merge: true }
      );
      setNotificationStatus("enabled");
    }
  };

  const handleRequestNotifications = async () => {
    try {
      console.log("Requesting notification permission...");

      const permission = await Notification.requestPermission();

      if (permission === "denied") {
        console.warn(
          "You blocked notifications from Summit. Please enable them in settings."
        );
        alert(
          "You blocked notifications from Summit. To enable them, go to Settings > Notifications > Summit."
        );
        return null;
      }

      if (permission !== "granted") {
        console.log("Notification permission not granted.");
        alert(
          "You did not grant access to notifications from Summit. To enable them, go to Settings > Notifications > Summit."
        );
        return null;
      }

      console.log("Notification permission granted.");

      // Attempt to get the FCM token
      return getToken(messaging, {
        vapidKey:
          "BLa-Fyoxa6OX995BxsbtUYXbDLMicSzSijE4PhExVvCw1pujSn-dp6AnUlX80mBII2ig61Ts74XwtPdOk08-mjQ",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("FCM Token:", currentToken);
            return currentToken;
          } else {
            console.warn(
              "No FCM token available. User needs to allow notifications."
            );
            alert(
              "Notifications are currently blocked. Enable them in Settings → Notifications → Your PWA."
            );
            return null;
          }
        })
        .catch((err) => {
          console.error("Error retrieving token:", err);
          alert(`Error enabling notifications. Please try again later. ${err}`);
          return null;
        });
    } catch (error) {
      console.error("Error requesting permission:", error);
      alert("An unexpected error occurred. Please try again.");
      return null;
    }
  };

  return (
    <Button
      sx={{ my: 1, textTransform: "none", width: "100%", height: "56px" }}
      onClick={handleEnableNotifications}
      disabled={notificationStatus === "enabled"}
      variant={notificationStatus === "default" ? "contained" : "outlined"}
      color={notificationStatus === "blocked" ? "error" : "primary"}
      startIcon={
        notificationStatus === "enabled" ? (
          <NotificationsActiveOutlined />
        ) : notificationStatus === "blocked" ? (
          <NotificationsOff />
        ) : (
          <NotificationAdd />
        )
      }
    >
      {notificationStatus === "enabled"
        ? "Push notifications enabled"
        : notificationStatus === "blocked"
        ? "Push notifications blocked"
        : "Enable push notifications"}
    </Button>
  );
};

export default EnableNotificationsButton;
