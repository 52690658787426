import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Check, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { PRICING_PLANS } from "../../pages/public/sections/PricingCards";
import { REVIEW_COUNT } from "../../services/variableServices";

function PlansRadioGroup({
  onClick,
  selectedPlan,
  shrink = false,
  showOnlySelected = false,
  showCheckmark = true,
}) {
  const theme = useTheme();
  const [reviewCount, setReviewCount] = useState(REVIEW_COUNT);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchReviewCount = async () => {
    try {
      const reviewQuery = query(
        collection(db, "visits"),
        where("rating", "==", 5)
      );
      const snapshot = await getCountFromServer(reviewQuery);
      setReviewCount(snapshot.data().count);
    } catch (error) {
      console.error("Error fetching review count:", error);
      // Optionally handle the error (e.g., set a default value or display a message)
    }
  };

  useEffect(() => {
    fetchReviewCount();
  }, []);

  // Determine which plans to render
  // Determine which plans to render
  const plansToRender =
  showOnlySelected &&
  selectedPlan?.tier !== null &&
  selectedPlan?.tier !== undefined
    ? PRICING_PLANS.filter(
        (plan) =>
          String(plan?.tier) === String(selectedPlan?.tier) && plan.tier !== 1
      )
    : PRICING_PLANS.filter((plan) => plan.tier !== 1);


  console.log("plansToRender: ", plansToRender);

  const renderBenefits = (benefits, isSelected) => {
    return (
      <List dense={true}>
        {benefits.map((benefit, idx) => (
          <ListItem key={idx} sx={{ px: 0, alignItems: "flex-start" }}>
            <Check
              color="primary" //{isSelected ? "vibrant" : "primary"}
              sx={{ mr: 1, opacity: 1 }}
            />

            <Typography variant="body1">{benefit}</Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <RadioGroup
      row
      value={selectedPlan?.tier}
      // onChange={(e) => onClick(e.target.value)}
      sx={{
        gap: 2,
        flexDirection: { xs: "column-reverse", sm: "row" },
        flexWrap: "nowrap",
        mx: -1,
        width: "auto",
        "& .MuiFormGroup-root": {
          flexDirection: { xs: "column-reverse", sm: "row" },
          flexWrap: "nowrap",
          display: "flex",
        },
      }}
    >
      {plansToRender.map((plan) => {
        const isSelected = String(selectedPlan?.tier) === String(plan?.tier);
        return (
          <FormControlLabel
            key={plan?.tier}
            value={plan?.tier}
            componentsProps={{ label: { style: { width: "100%" } } }}
            control={<Radio sx={{ display: "none" }} />}
            sx={{
              m: 0,
              width: "100%",
              ".MuiFormControlLabel-label": {
                width: "100%",
              },
            }}
            label={
              <Card
                onClick={() => onClick(plan)} // <-- Add this line
                variant="outlined"
                sx={{
                  p: 2,
                  flexGrow: 1,
                  gap: 1,
                  cursor: "pointer",
                  position: "relative", // <-- added
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  boxSizing: "border-box",
                  // backgroundColor: "rgba(0, 0, 0, 0.02)",
                  // backgroundColor: isSelected
                  //   ? theme.palette.primary.darkest
                  //   : "white",
                  // color: isSelected ? "white" : "black",
                  // boxShadow: isSelected
                  //   ? "0px 4px 8px rgba(0,0,0,0.2)"
                  //   : "none",
                  borderWidth: "2px",
                  borderColor: isSelected
                    ? theme.palette.primary.main
                    : "rgba(0, 0, 0, 0.12)",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: { xs: "none", sm: "translateY(-4px)" },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/* Chip title block */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* Chip title block */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      {!(plan.tier === 0 && isMobile) && (
                        <Chip
                          size="large"
                          variant="outlined"
                          color="success"
                          label={"Best value"}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            borderWidth: "2px",
                            borderRadius: "5px",
                            backgroundColor: "#C7F7C7",
                            borderColor: "#51BC51",
                            color: "#136C13",
                            visibility: plan.tier === 0 ? "hidden" : "visible",
                          }}
                        />
                      )}
                      <Typography variant="h5" fontSize={"28px"}>
                        {plan.chip}
                      </Typography>
                    </Box>

                    {/* Review block */}
                    {plan.tier !== 0 && (
                      <Box
                        // onClick={() => navigate("/reviews")}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: 0,
                          cursor: "pointer",
                          "&:hover": {
                            color: "white",
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Rating
                          value={5}
                          readOnly
                          precision={0.5}
                          sx={{
                            "& .MuiRating-icon": {
                              fontSize: "24px", // Smaller icon size if needed
                              margin: "0px -1.5px", // Reduce horizontal spacing
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            textDecoration: "none",
                            // color: "white",
                          }}
                        >
                          {reviewCount} reviews
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                      // borderColor: isSelected
                      //   ? "rgba(255, 255, 255, 0.12)"
                      //   : "rgba(0, 0, 0, 0.12)",
                    }}
                  />
                  <Collapse in={!shrink}>
                    {renderBenefits(plan.benefits, isSelected)}
                  </Collapse>
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                      // borderColor: isSelected
                      //   ? "rgba(255, 255, 255, 0.12)"
                      //   : "rgba(0, 0, 0, 0.12)",
                    }}
                  />
                  {/* Tiitle rate stack */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "column" },
                        justifyContent: "flex-start", //"space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontSize="24px"
                        fontWeight="800"
                        align="center"
                        sx={{ mt: 1 }}
                      >
                        ${plan.hourlyRate}
                        <Typography
                          component="span"
                          fontSize="24px"
                          fontWeight="400"
                          sx={{ opacity: 0.65 }}
                        >
                          {" "}
                          / hour
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body1"
                        fontSize="16px"
                        align="center"
                        sx={{ opacity: 0.75 }}
                      >
                        {plan.monthlyString}
                      </Typography>
                    </Box>
                    {!isSelected ? (
                      <Button
                        disableElevation
                        // onClick={() => onClick(plan)}
                        variant="contained"
                        color={"primary"}
                        sx={{
                          textTransform: "none",
                          borderRadius: "5px",
                          // height: "100%",
                          // color: "revert",
                        }}
                        endIcon={<KeyboardArrowRight />}
                      >
                        Select
                      </Button>
                    ) : (
                      <Box
                        sx={{
                          p: "6px 16px",
                          backgroundColor: theme.palette.primary.lightest,
                          boxSizing: "border-box",
                          borderRadius: "5px",

                        }}
                      >
                        <Typography color={"primary"}> Your plan </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Card>
            }
          />
        );
      })}
    </RadioGroup>
  );
}

export default PlansRadioGroup;
