import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { debounce } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";

const CallsContext = createContext();

const INITIAL_FETCH = 10;
const PAGE_SIZE = 5;

const now = new Date();

export const testLogs = [
  {
    id: "1",
    isRead: false,
    call: {
      caller: { displayName: null, phone: "123-456-7890" },
      receiver: { displayName: "Bob Smith", phone: "987-654-3210" },
      direction: "inbound",
      statusHistory: [
        {
          status: "ringing",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 10000)), // 5 seconds ago
          displayName: "Bob Smith",
          userRole: "member",
          id: "abc123",
        },
        {
          status: "answered",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 2000)), // 3 seconds ago
          displayName: "Bob Smith",
        },
        {
          status: "ringing",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 10000)), // 7 seconds ago
          displayName: "3607081269",
        },
        {
          status: "no-answer",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 4000)), // 4 seconds ago
          displayName: "3607081269",
        },
      ],
      callDuration: 120,
    },
    madeContact: true,
    hasVoicemail: true,
    voicemail: {
      recordingUrl: "https://example.com/audio1.mp3",
      transcriptionText: "Hello, please call me back.",
    },
  },
  {
    id: "2",
    isRead: true,
    call: {
      caller: { displayName: "Charlie Liken", phone: "555-555-5555" },
      receiver: { displayName: "Dana", phone: "444-444-4444" },
      direction: "outbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Dana" },
        {
          status: "completed",
          timestamp: Timestamp.now(),
          displayName: "Dana",
        },
      ],
      callDuration: 0,
    },
    madeContact: false,
    voicemail: null,
  },
  {
    id: "3",
    isRead: true,
    call: {
      caller: { displayName: "Eve", phone: "111-222-3333" },
      receiver: { displayName: "Frank", phone: "333-222-1111" },
      direction: "inbound",
      statusHistory: [
        { status: "missed", timestamp: Timestamp.now(), displayName: "Frank" },
      ],
      callDuration: 0,
    },
    madeContact: false,
    hasVoicemail: true,
    voicemail: {
      recordingUrl: "https://example.com/audio3.mp3",
      transcriptionText: "I missed your call.",
    },
  },
  {
    id: "4",
    isRead: false,
    call: {
      caller: { displayName: "Grace", phone: "222-333-4444" },
      receiver: { displayName: "Heidi", phone: "444-333-2222" },
      direction: "inbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Heidi" },
        {
          status: "answered",
          timestamp: Timestamp.now(),
          displayName: "Heidi",
        },
      ],
      callDuration: 180,
    },
    madeContact: true,
    voicemail: null,
  },
  {
    id: "5",
    isRead: false,
    call: {
      caller: { displayName: "Ivan", phone: "777-888-9999" },
      receiver: { displayName: "Judy", phone: "999-888-7777" },
      direction: "outbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Judy" },
        { status: "answered", timestamp: Timestamp.now(), displayName: "Judy" },
      ],
      callDuration: 90,
    },
    madeContact: true,
    voicemail: null,
  },
  {
    id: "6",
    isRead: false,
    call: {
      caller: { displayName: "Kevin", phone: "123-123-1234" },
      receiver: { displayName: "Laura", phone: "432-432-4321" },
      direction: "inbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Laura" },
        {
          status: "answered",
          timestamp: Timestamp.now(),
          displayName: "Laura",
        },
      ],
      callDuration: 150,
    },
    madeContact: true,
    hasVoicemail: true,
    voicemail: {
      recordingUrl: "https://example.com/audio6.mp3",
      transcriptionText: "Please call me back asap.",
    },
  },
  {
    id: "7",
    isRead: false,
    call: {
      caller: { displayName: "Mallory", phone: "222-555-6666" },
      receiver: { displayName: "Oscar", phone: "666-555-2222" },
      direction: "inbound",
      statusHistory: [
        { status: "missed", timestamp: Timestamp.now(), displayName: "Oscar" },
      ],
      callDuration: 0,
    },
    madeContact: false,
    voicemail: null,
  },
  {
    id: "8",
    isRead: false,
    call: {
      caller: { displayName: "Peggy", phone: "333-444-5555" },
      receiver: { displayName: "Trent", phone: "555-444-3333" },
      direction: "inbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Trent" },
        {
          status: "answered",
          timestamp: Timestamp.now(),
          displayName: "Trent",
        },
      ],
      callDuration: 200,
    },
    madeContact: true,
    hasVoicemail: true,
    voicemail: {
      recordingUrl: "https://example.com/audio8.mp3",
      transcriptionText: "I will call back later.",
    },
  },
  {
    id: "9",
    isRead: false,
    call: {
      caller: { displayName: "Victor", phone: "444-555-6666" },
      receiver: { displayName: "Wendy", phone: "666-555-4444" },
      direction: "outbound",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), displayName: "Wendy" },
        {
          status: "no-answer",
          timestamp: Timestamp.now(),
          displayName: "Wendy",
        },
      ],
      callDuration: 0,
    },
    madeContact: false,
    voicemail: null,
  },
  {
    id: "10",
    isRead: false,
    call: {
      caller: { displayName: "Xavier", phone: "777-888-0000" },
      receiver: { displayName: "Yvonne", phone: "000-888-7777" },
      direction: "inbound",
      statusHistory: [
        {
          status: "ringing",
          timestamp: Timestamp.now(),
          displayName: "Yvonne",
        },
        {
          status: "answered",
          timestamp: Timestamp.now(),
          displayName: "Yvonne",
        },
      ],
      callDuration: 110,
    },
    madeContact: true,
    hasVoicemail: true,
    voicemail: {
      recordingUrl: "https://example.com/audio10.mp3",
      transcriptionText: "Call me back when you can.",
    },
  },
];

export const CallsProvider = ({ children }) => {
  const [allCalls, setAllCalls] = useState([]);
  const [missedCalls, setMissedCalls] = useState([]);
  const [voicemailCalls, setVoicemailCalls] = useState([]);
  const [lastDoc, setLastDoc] = useState({
    all: null,
    missed: null,
    voicemail: null,
  });
  const [hasMore, setHasMore] = useState({
    all: true,
    missed: true,
    voicemail: true,
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterType, setFilterType] = useState("missed");
  const [unreadMissedCount, setUnreadMissedCount] = useState(0);
  const [unreadVoicemailCount, setUnreadVoicemailCount] = useState(0);

  const mergeCalls = (prev, newCalls) => {
    // Ensure the newest data is kept
    const merged = [...prev, ...newCalls];

    return Array.from(
      new Map(merged.map((call) => [call.id, call])).values()
    ).sort(
      (a, b) => (b.created?.toMillis() || 0) - (a.created?.toMillis() || 0)
    );
  };

  const fetchUnreadCounts = debounce(async () => {
    try {
      const callsRef = collection(db, "logs");

      // Missed Calls Count
      const missedQuery = query(
        callsRef,
        where("logType", "==", "call"),
        where("madeContact", "==", false),
        where("isRead", "==", false),
        where("hasVoicemail", "==", false)
      );
      const missedSnapshot = await getCountFromServer(missedQuery);
      setUnreadMissedCount(missedSnapshot.data().count);

      // Voicemail Calls Count
      const voicemailQuery = query(
        callsRef,
        where("logType", "==", "call"),
        where("hasVoicemail", "==", true),
        where("isRead", "==", false)
      );
      const voicemailSnapshot = await getCountFromServer(voicemailQuery);
      setUnreadVoicemailCount(voicemailSnapshot.data().count);
    } catch (error) {
      console.error("Error fetching unread call counts:", error);
    }
  }, 300);

  useEffect(() => {
    fetchUnreadCounts();
  }, []);

  useEffect(() => {
    const callsRef = collection(db, "logs");

    const fetchCalls = (queryRef, stateSetter, type) => {
      return onSnapshot(queryRef, (snapshot) => {
        let addedCalls = [];
        let modifiedCalls = [];
        let removedCalls = [];

        snapshot.docChanges().forEach((change) => {
          const call = { id: change.doc.id, ...change.doc.data() };

          if (change.type === "added") {
            addedCalls.push(call);
          } else if (change.type === "modified") {
            modifiedCalls.push(call);
          } else if (change.type === "removed") {
            removedCalls.push(call);
          }
        });

        // Update state based on changes
        stateSetter((prev) => {
          // Remove calls that were deleted
          let updatedCalls = prev.filter(
            (call) => !removedCalls.some((r) => r.id === call.id)
          );

          // Update modified calls if they exist in prev
          updatedCalls = updatedCalls.map((call) => {
            const modifiedCall = modifiedCalls.find((m) => m.id === call.id);
            return modifiedCall ? modifiedCall : call;
          });

          // Add new calls and merge (prevent duplicates)
          return mergeCalls(updatedCalls, addedCalls);
        });

        // Handle pagination updates
        setHasMore((prev) => ({
          ...prev,
          [type]: snapshot.docs.length === INITIAL_FETCH,
        }));

        // Keep track of the last document for pagination
        if (snapshot.docs.length > 0) {
          setLastDoc((prev) =>
            prev[type]
              ? prev
              : { ...prev, [type]: snapshot.docs[snapshot.docs.length - 1] }
          );
        }

        fetchUnreadCounts();
      });
    };

    const allQuery = query(
      callsRef,
      where("logType", "==", "call"),
      orderBy("created", "desc"),
      limit(INITIAL_FETCH)
    );
    const missedQuery = query(
      callsRef,
      where("logType", "==", "call"),
      where("madeContact", "==", false),
      where("isRead", "==", false),
      where("hasVoicemail", "==", false),
      orderBy("created", "desc"),
      limit(INITIAL_FETCH)
    );
    const voicemailQuery = query(
      callsRef,
      where("logType", "==", "call"),
      where("hasVoicemail", "==", true),
      where("isRead", "==", false),
      orderBy("created", "desc"),
      limit(INITIAL_FETCH)
    );

    const unsubscribeAll = fetchCalls(allQuery, setAllCalls, "all");
    const unsubscribeMissed = fetchCalls(missedQuery, setMissedCalls, "missed");
    const unsubscribeVoicemail = fetchCalls(
      voicemailQuery,
      setVoicemailCalls,
      "voicemail"
    );

    return () => {
      unsubscribeAll();
      unsubscribeMissed();
      unsubscribeVoicemail();
    };
  }, []);

  const fetchMoreCalls = async () => {
    if (loadingMore || !hasMore[filterType] || !lastDoc[filterType]) return;
    setLoadingMore(true);

    try {
      const callsRef = collection(db, "logs");

      let q = query(
        callsRef,
        where("logType", "==", "call"),
        orderBy("created", "desc"),
        startAfter(lastDoc[filterType]),
        limit(PAGE_SIZE)
      );

      if (filterType === "missed") {
        q = query(
          q,
          where("madeContact", "==", false),
          where("isRead", "==", false),
          where("hasVoicemail", "==", false)
        );
      } else if (filterType === "voicemail") {
        q = query(
          q,
          where("hasVoicemail", "==", true),
          where("isRead", "==", false)
        );
      }

      const snapshot = await getDocs(q);
      const newCalls = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (filterType === "missed") {
        setMissedCalls((prev) => mergeCalls(prev, newCalls));
      } else if (filterType === "voicemail") {
        setVoicemailCalls((prev) => mergeCalls(prev, newCalls));
      } else {
        setAllCalls((prev) => mergeCalls(prev, newCalls));
      }

      setHasMore((prev) => ({
        ...prev,
        [filterType]: snapshot.docs.length === PAGE_SIZE,
      }));
      setLastDoc((prev) => ({
        ...prev,
        [filterType]: snapshot.docs[snapshot.docs.length - 1],
      }));
    } catch (error) {
      console.error("Error fetching more calls:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const toggleCallReadStatus = async (call, markAsRead) => {
    try {
      // Update allCalls state
      setAllCalls((prev) =>
        prev.map((c) => (c.id === call.id ? { ...c, isRead: markAsRead } : c))
      );

      if (markAsRead) {
        // If marking as read, REMOVE from missedCalls or voicemailCalls
        setMissedCalls((prev) => prev.filter((c) => c.id !== call.id));
        setVoicemailCalls((prev) => prev.filter((c) => c.id !== call.id));

        // Update unread counts
        if (!call.hasVoicemail) {
          setUnreadMissedCount((prev) => Math.max(prev - 1, 0));
        } else {
          setUnreadVoicemailCount((prev) => Math.max(prev - 1, 0));
        }
      } else {
        // If marking as unread, INSERT back into missedCalls or voicemailCalls without duplicates
        setMissedCalls((prev) =>
          call.hasVoicemail
            ? prev // Ignore, this is for voicemail
            : prev.some((c) => c.id === call.id)
            ? prev
            : [...prev, { ...call, isRead: false }]
        );

        setVoicemailCalls(
          (prev) =>
            call.hasVoicemail
              ? prev.some((c) => c.id === call.id)
                ? prev
                : [...prev, { ...call, isRead: false }]
              : prev // Ignore, this is for missed calls
        );

        // Update unread counts
        if (!call.hasVoicemail) {
          setUnreadMissedCount((prev) => prev + 1);
        } else {
          setUnreadVoicemailCount((prev) => prev + 1);
        }
      }

      const callRef = doc(db, "logs", call.id);
      await updateDoc(callRef, { isRead: markAsRead });
    } catch (error) {
      console.error("Error updating call read status:", error);
    }
  };

  return (
    <CallsContext.Provider
      value={{
        allCalls,
        missedCalls,
        voicemailCalls,
        unreadMissedCount,
        unreadVoicemailCount,
        filterType,
        setFilterType,
        fetchMoreCalls,
        loadingMore,
        hasMore,
        toggleCallReadStatus,
      }}
    >
      {children}
    </CallsContext.Provider>
  );
};

export const useCalls = () => useContext(CallsContext);
