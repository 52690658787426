import { Verified } from "@mui/icons-material";
import { Box, Skeleton } from "@mui/material";
import React from "react";

const HelperHighlightSkeleton = ({ fontScale = 1 }) => {
  return (
    <Box
      sx={{
        position: "relative",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 8px 8px 0px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "visible",
        borderRadius: "15px",
        width: "100%",
        maxWidth: "600px",
        p: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 0,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {/* Avatar and details */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Skeleton
            variant="circular"
            sx={{ width: 120 * fontScale, height: 120 * fontScale }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Skeleton width={140} height={28 * fontScale} />
              <Verified
                color="primary"
                sx={{ fontSize: `${20 * fontScale}px`, fontWeight: "700" }}
              />
            </Box>
            <Skeleton width={100} height={20 * fontScale} />
            <Skeleton width={80} height={20 * fontScale} />
          </Box>
        </Box>

        {/* About me section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 2,
            backgroundColor: "#F5F5F5",
            p: 2,
            borderRadius: "15px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Skeleton width={80} height={20 * fontScale} />
          <Skeleton width="100%" height={18 * fontScale} />
          <Skeleton width="95%" height={18 * fontScale} />
          <Skeleton width="98%" height={18 * fontScale} />
          <Skeleton width="93%" height={18 * fontScale} />
          <Skeleton width="80%" height={18 * fontScale} />
        </Box>
      </Box>
    </Box>
  );
};

export default HelperHighlightSkeleton;
