import { DeleteForever } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  arrayUnion,
  doc,
  getDoc,
  increment,
  runTransaction,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { db, functions } from "../../firebase"; // Import your Firebase configuration
import { useDrawer } from "../../providers/DrawerProvider";
import { sendSMSClient } from "../../services/communicationServices";
import { applyBoost } from "../../services/progressServices";
import CloseConfirmation from "../dialogs/CloseConfirmation";

const ChangeEmployeeStatus = ({
  employeeId,
  currentStatus,
  employeePrivateData,
  onClose = () => {},
}) => {
  const [status, setStatus] = useState(currentStatus);
  const [loading, setLoading] = useState(false);
  const { openDrawer } = useDrawer();

  const {
    firstName = "",
    lastName = "",
    setup: {
      merch: {
        reimbursed = false,
        total_price = 0,
        total_shipping = 0,
        total_tax = 0,
      } = {},
    },
  } = employeePrivateData || {};

  const roundedPrice =
    Math.round(((total_price + total_shipping + total_tax) / 100) * 100) / 100;

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Firestore update object
      const updates = { status: status };

      if (currentStatus === "applicant" && status === "interview") {
        sendSMSClient({
          phone: employeePrivateData?.phone,
          body: `Linked Lives: Hi ${employeePrivateData?.firstName}, your application has been approved! Please check the app for the next step.`,
        });
        updates["setup.interviewStartDate"] = Timestamp.now();
      }

      if (currentStatus === "interview" && status === "onboarding") {
        sendSMSClient({
          phone: employeePrivateData?.phone,
          body: `Linked Lives: Hi ${employeePrivateData?.firstName}, you passed the interview! Please check the app for the next step.`,
        });
        updates["setup.onboardingStartDate"] = Timestamp.now();
      }

      if (currentStatus === "onboarding" && status === "active") {
        sendSMSClient({
          phone: employeePrivateData?.phone,
          body: `Linked Lives: Hi ${employeePrivateData?.firstName}, your onboarding is complete! You are now active and available for visits.`,
        });
        updates["setup.activeStartDate"] = Timestamp.now();
      }

      if (currentStatus === "onboarding" && status === "standby") {
        sendSMSClient({
          phone: employeePrivateData?.phone,
          body: `Linked Lives: Hi ${employeePrivateData?.firstName}, your onboarding is complete! You are on standby. Once there is enough demand in your area, we'll move you to active.`,
        });
        updates["setup.standbyStartDate"] = Timestamp.now();
      }

      if (currentStatus === "standby" && status === "active") {
        sendSMSClient({
          phone: employeePrivateData?.phone,
          body: `Linked Lives: Hi ${employeePrivateData?.firstName}, there's an increase of demand in your area. You are now active and available to be booked for visits!`,
        });
        updates["setup.activeStartDate"] = Timestamp.now();
      }

      // Reference to the employee document in Firestore
      const employeeRef = doc(db, "employees", employeeId);
      const employeePrivateRef = doc(db, "employeesPrivate", employeeId);
      const employeeAdminRef = doc(db, "employeesAdmin", employeeId);

      // If moving from onboarding/interview/applicant to active, set the startDate and payout the stripe
      if (
        (currentStatus === "onboarding" ||
          currentStatus === "interview" ||
          currentStatus === "applicant") &&
        status === "active"
      ) {
        updates.startDate = Timestamp.now();
        // Let's not auto reimburse merch
        // if (!reimbursed) {
        //   await handleReimburseMerch();
        // }
      }

      // Update the status in Firestore
      await updateDoc(employeeRef, updates);
      await updateDoc(employeePrivateRef, updates);
      await updateDoc(employeeAdminRef, updates);

      // Handle the referral (person who referred them)
      if (
        (currentStatus === "onboarding" ||
          currentStatus === "interview" ||
          currentStatus === "applicant") &&
        status === "active" &&
        employeePrivateData?.referredBy?.id
      ) {
        await handleReferral();
      }

      onClose(status);
    } catch (error) {
      console.error("Error updating employee status: ", error);
      // Handle any errors here, such as updating component state or displaying a message
    } finally {
      setLoading(false);
    }
  };

  const handleReimburseMerch = async () => {
    setLoading(true);

    const bonusAmount = Math.min(roundedPrice, 30);

    try {
      if (bonusAmount > 0) {
        const applyBonus = httpsCallable(functions, "applyBonus");
        const bonusResponse = await applyBonus({
          bonusAmount,
          helperId: employeeId,
          bonusDescription: "Reimbursement for uniform",
        });
        console.log("Bonus applied:", bonusResponse.data);
      }
      const employeePrivateRef = doc(db, "employeesPrivate", employeeId);
      await setDoc(
        employeePrivateRef,
        { setup: { merch: { reimbursed: true } } },
        { merge: true }
      );
    } catch (bonusError) {
      console.error("Error applying bonus:", bonusError);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      // Run a transaction to delete the Firestore documents first
      await runTransaction(db, async (transaction) => {
        transaction.delete(doc(db, "employees", employeeId));
        transaction.delete(doc(db, "employeesPrivate", employeeId));
        transaction.delete(doc(db, "employeesAdmin", employeeId));
      });
      // Delete the auth object (note: this is separate from Firestore transaction)
      // Call Cloud Function to delete the auth user with the given employeeId
      const deleteUserAuth = httpsCallable(functions, "deleteUserAuth");
      await deleteUserAuth({ uid: employeeId });

      onClose("deleted");
    } catch (error) {
      console.error("Error deleting account: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReferral = async () => {
    const referrerId = employeePrivateData.referredBy.id;
    const referrerPrivateSnap = await getDoc(
      doc(db, "employeesPrivate", referrerId)
    );
    if (referrerPrivateSnap.exists()) {
      const referrerPhone = referrerPrivateSnap.data().phone;

      sendSMSClient({
        phone: referrerPhone,
        body: `Exciting news, you referred ${employeePrivateData.firstName} ${employeePrivateData.lastName} and they are now a helper. You've ranked up on the leaderboard and earned a new badge!`,
      });

      // Apply the helper boost
      await applyBoost(referrerId, "employeeReferral");
    }

    const now = new Date();
    const year = now.getFullYear().toString();
    const monthKey = `${year}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;

    const month = now.getMonth(); // 0 = January, 11 = December
    let season, seasonYear;

    if (month === 11 || month === 0 || month === 1) {
      season = "winter";
      seasonYear =
        month === 0 || month === 1 ? (now.getFullYear() - 1).toString() : year;
    } else if (month >= 2 && month <= 4) {
      season = "spring";
      seasonYear = year;
    } else if (month >= 5 && month <= 7) {
      season = "summer";
      seasonYear = year;
    } else {
      season = "fall";
      seasonYear = year;
    }

    await updateDoc(doc(db, "employees", referrerId), {
      "referrals.total": increment(1),
      [`referrals.bins.${year}.total`]: increment(1),
      [`referrals.bins.${year}.months.${monthKey}.total`]: increment(1),
      [`referrals.bins.${seasonYear}.seasons.${season}.total`]: increment(1),
      [`referrals.referralArr`]: arrayUnion({
        id: employeeId,
        firstName: employeePrivateData.firstName,
        lastName: employeePrivateData.lastName,
        timestamp: Timestamp.now(),
      }),
    });
  };

  const handleDeleteClick = async () => {
    try {
      // Open a confirmation modal that onConfirm causes onPlanClick
      openDrawer(CloseConfirmation, {
        showDefaultHeader: false,
        modalType: "dialog",
        modalTitle: `Delete helper account?`,
        modalBody: `You are about to delete the helper account for ${firstName} ${lastName}. This action cannot be reversed.`,
        onConfirm: () => {
          handleDeleteAccount(); // Proceed with the actual dialog close
        },
        // onCancel: () => setOpenPricingCards(false), // Simply close the confirmation
      });
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="employee-status-label">Employee Status</InputLabel>
          <Select
            labelId="employee-status-label"
            value={status}
            label="Employee Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="applicant">Applicant</MenuItem>
            <MenuItem value="applicant_failed">Failed application</MenuItem>
            <MenuItem value="interview">Interview</MenuItem>
            <MenuItem value="interview_failed">Failed interview</MenuItem>
            <MenuItem value="onboarding">Onboarding</MenuItem>
            {/* <MenuItem value="new_hire">New Hire</MenuItem> */}
            {/* <MenuItem value="trainee">Trainee</MenuItem> */}
            <MenuItem value="standby">Review</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="fired">Fired</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="error"
          disabled={loading}
          onClick={handleDeleteClick}
          startIcon={<DeleteForever />}
          sx={{
            mt: "auto",
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          Delete Account
        </Button>
        {!reimbursed && (
          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleReimburseMerch}
            sx={{
              mt: "auto",
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "56px" },
            }}
          >
            Reimburse merch ${roundedPrice}
          </Button>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={handleSave}
          sx={{
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ChangeEmployeeStatus;
