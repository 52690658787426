import { Box } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import ReviewCard from "../ads/ReviewCard";

const ReviewView = () => {
  const { reviewId } = useParams();
  const [reviewData, setReviewData] = useState(null);
  const [isReviewLoaded, setIsReviewLoaded] = useState(false);

  useEffect(() => {
    const fetchReviewData = async () => {
      if (!reviewId) return;
      try {
        const reviewDocRef = doc(db, "visits", reviewId);
        const reviewDocSnap = await getDoc(reviewDocRef);
        if (reviewDocSnap.exists()) {
          setReviewData(reviewDocSnap.data());
        }
      } catch (error) {
        console.error("Error fetching review data:", error);
      } finally {
        setIsReviewLoaded(true);
      }
    };

    fetchReviewData();
  }, [reviewId]);

  if (!reviewData) return;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 540,
          height: 540,
          // border: "1px solid",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", height: "auto" }}>
          <ReviewCard visit={reviewData} noCard={true} fontScale={1.25} />
        </Box>
      </Box>{" "}
      {isReviewLoaded && <div id="page-loaded" style={{ display: "none" }} />}
    </Box>
  );
};

export default ReviewView;
