import {
  ArrowBackIosNewRounded,
  CheckCircle,
  Close
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions"; // Import Firebase functions
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import logo192 from "../../assets/logo192.png";
import { db, functions } from "../../firebase";
import { useSession } from "../../providers/SessionProvider";
import { sendSMSClient } from "../../services/communicationServices";
import { setMembershipPlan } from "../../services/memberServices";
import ServiceStatusAlert from "../availability/ServiceStatusAlert";
import PlansRadioGroup from "../plans/PlansRadioGroup";
import { useHelpers } from "../../providers/HelpersProvider";

const benefits = [
  // "One-time $50 signup fee",
  // "Lifetime membership, zero monthly fees",
  "Trusted and reliable help on-demand",
  // "Reliable help on demand at $40/hr",
  // "Peace of mind with household tasks",
  // "Earn a free hour per referral",
  // "Deals and promotions",
  // "1-hour visit minimum",
  // "Schedule 3-4 days in advance",
  // "Availability within the week",
  "Our satisfaction guarantee",
  // "End anytime with no fees",
  // "Excellent customer service",
  // `Trusted by over ${Math.floor(MEMBER_COUNT / 10) * 10}+ seniors`,
  "BBB Accredited",
  // "Mix and match any tasks to fill the visit",
  // "Senior-friendly online scheduling",
  // "Join over 300+ seniors"
  // "Schedule recurring visits",
  // "Same day visits",
  // ... more benefits
];

const SignupFeePayment = ({
  customerId,
  mName = "",
  eName = "",
  eId = "",
  memberId,
  onClose,
  zipCode,
  signupFeeStatus = "incomplete",
  adminMode = true,
  initialStatus = { status: "active" },
  city = "",
  showAlert = true,
  showClose = false,
}) => {
  const { createLog, updateHelperAnalytics } = useSession();

  const stripe = useStripe();
  const elements = useElements();

  const {employeesData = () => {}} = useHelpers() || {}
  const helpersArr = Object.keys(employeesData);

  console.log("SignupFeePayment - helpersArr: ", helpersArr)


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const idempotencyKey = memberId;

  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Add this state near your other useState declarations
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const isPriorityPlan = selectedPlan?.tier == 2;

  console.log("selectedPlan: ", selectedPlan);

  const [view, setView] = useState("select");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [signupFee, setSignupFee] = useState(50);
  const [payOption, setPayOption] = useState("card");

  // Add useEffect to log the event
  useEffect(() => {
    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Viewed the pricing cards.`,
      });
    }
  }, []);

  const handleSubmit = () => {
    // Paying now with card
    if (payOption === "card") {
      handlePayment();
    } else if (payOption === "invoice") {
      handleCreateInvoice();
    }
  };

  const handleSetPlan = async (plan) => {
    // Calculate next billing date as a month from now at noon in the correct time zone
    const nextBillingIsoDate = DateTime.now()
      .setZone("America/Los_Angeles")
      .plus({ months: 1 })
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .toISO();

    // Do nothing if they select the same.
    try {
      await setMembershipPlan({
        plan: plan.tier,
        hourlyRate: plan.hourlyRate,
        membershipFee: plan.membershipFee,
        timeZoneId: "America/Los_Angeles",
        nextBillingIsoDate,
      });
      console.log(`Plan Tier: ${plan.tier} set successfully`);
    } catch (error) {
      console.error("Error setting membership plan:", error);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    createLog({
      collectionName: "members",
      idsArr: [memberId],
      summary: `Submitting payment to Stripe.`,
      logType: "activity",
    });

    // Reference to the Cloud Functions
    const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");
    const updateDefaultPaymentMethod = httpsCallable(
      functions,
      "updateDefaultPaymentMethod"
    );

    try {
      // Fire and forget `handleSetPlan` if `selectedPlan.tier !== 0`, since rate is already set by default.
      if (selectedPlan?.tier !== 0) {
        handleSetPlan(selectedPlan).catch((err) =>
          console.error("Error setting plan:", err)
        );

        const smsBody = `New Premium member: ${mName}`;

        // Send Admin a message that a new person has signed up
        sendSMSClient({
          phone: "3603366344",
          body: smsBody,
        });
      }

      const { data } = await createPaymentIntent({
        customerId,
        amount: signupFee,
        idempotencyKey,
      }); // pass the fee in cents
      const clientSecret = data.clientSecret;

      if (!clientSecret) throw new Error("Failed to get client secret.");

      const cardElement = elements.getElement(CardElement);

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
        setup_future_usage: "off_session", // Save the payment method for future use
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
        console.error(paymentResult.error);

        // Send Admin a message that shows the error
        sendSMSClient({
          phone: "3603366344",
          body: paymentResult.error.message,
        });

        createLog({
          collectionName: "members",
          idsArr: adminMode ? [eId, memberId] : [memberId],
          summary: `Error processing card ${paymentResult.error.message}.`,
        });
      } else if (paymentResult.paymentIntent.status === "succeeded") {
        await handleChangeSignupFeeStatus("paid");

        // Step 3: Update the default payment method on Stripe
        const paymentMethodId = paymentResult.paymentIntent.payment_method;
        updateDefaultPaymentMethod({
          paymentMethodId,
          customerId,
        })
          .then((res) => {
            if (
              res.data.result === "Default payment method updated successfully"
            ) {
              console.log("Default payment method updated successfully");
            } else {
              console.error("Failed to update default payment method.");
            }
          })
          .catch((err) =>
            console.error("Error updating default payment method:", err)
          );

        // Payment succeeded
        setSuccess(true);

        updateHelperAnalytics({
          helperIds: helpersArr,
          bin: "newMembers",
        });

        createLog({
          collectionName: "members",
          idsArr: adminMode ? [eId, memberId] : [memberId],
          summary: adminMode
            ? `${eName} completed the signup fee for ${mName}.`
            : `${mName} paid the signup fee.`,
        }).catch((err) => console.error("Error creating log:", err));
      }
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSignupFeeStatus = async (status) => {
    console.log("memberId: ", memberId);

    // Update member private fee paid
    const memberPrivateDocRef = doc(db, "membersPrivate", memberId);

    // Update the property
    await setDoc(
      memberPrivateDocRef,
      { signupFeeStatus: status },
      { merge: true }
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success"; // Replace with actual color or theme color
      case "invoice":
        return "primary"; // Replace with actual color or theme color
      default:
        return "error"; // Gray or Incomplete
    }
  };

  const handleCreateInvoice = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    // Reference to the Cloud Functions
    const createInvoiceItem = httpsCallable(functions, "createInvoiceItem");

    try {
      const { data } = await createInvoiceItem({
        customerId,
        amount: signupFee,
        description: "Signup fee for lifetime Linked Lives membership",
        taxable: false,
        createInvoice: true,
        idempotencyKey,
      });

      handleChangeSignupFeeStatus("invoice");

      console.log("Invoice item created successfully:", data);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (plan) => {
    if (selectedPlan == null || plan?.tier !== selectedPlan?.tier) {
      setSelectedPlan(plan);
      setView("pay");
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected the ${plan.chip} plan.`,
      });
    } else {
      setSelectedPlan(null);
      setView("select");
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Unselected a plan.`,
      });
    }
  };

  const renderPaymentButton = () => (
    <>
      <Button
        variant="contained"
        color={success ? "success" : "primary"}
        onClick={success ? handleClose : handleSubmit}
        disabled={
          !stripe || !elements || loading //||
          // (payOption === "card" && !isCardComplete)
        }
        sx={{
          height: "56px",
          textTransform: "none",
          maxWidth: "500px",
          width: "100%",
          mx: "auto",
        }}
        startIcon={success ? <CheckCircle /> : null}
      >
        {loading ? (
          <>
            <CircularProgress color="inherit" size={24} sx={{ mr: 1 }} />{" "}
            Processing...
          </>
        ) : success ? (
          "Success (click to close)"
        ) : payOption === "invoice" ? (
          `Send invoice for $${signupFee}`
        ) : (
          payOption === "card" &&
          (isPriorityPlan ? `Pay & start membership` : "Pay sign-up fee")
        )}
      </Button>

      {!(payOption === "card" && success) && adminMode && (
        <Button
          variant="text"
          onClick={() => {
            handleChangeSignupFeeStatus("paid");
            setSuccess(true);
          }}
          fullWidth
          disabled={loading}
          sx={{
            height: "60px",
            textTransform: "none",
          }}
        >
          Mark as paid
        </Button>
      )}

      {!adminMode && (
        <Box sx={{ mt: "4px" }}>
          <Typography align="center" variant="caption" color={"text.secondary"}>
            Your card is securely stored and charged the morning after each
            visit. For excellent work, in person tips are appreciated.
            {/* Update card anytime. */}
            {/* No need to pay helpers directly, */}
          </Typography>
        </Box>
      )}
    </>
  );

  const handleClose = () => {
    createLog({
      collectionName: "members",
      idsArr: [memberId],
      summary: `Closed the pricing cards.`,
    });
    onClose();
  };

  return (
    <>
      {showClose && (
        <>
          {view === "select" && (
            <Button
              onClick={() => {
                handleClose();
              }}
              // color="grayscale"
              sx={{ textTransform: "none", ml: 2, mr: "auto", mt: 1.5, mb: 1 }}
              startIcon={<Close />}
            >
              <Typography sx={{ fontSize: "16px" }}>Close</Typography>
            </Button>
          )}
        </>
      )}
      <DialogContent>
        {showAlert && (
          <ServiceStatusAlert
            signupFeeStatus={signupFeeStatus}
            initialStatus={initialStatus}
            city={city}
            showWaitlist={true}
            showIcon={false}
          />
        )}
        {view === "pay" && (
          <Button
            onClick={() => {
              setView("select");
              setSelectedPlan(null);
              createLog({
                collectionName: "members",
                idsArr: [memberId],
                summary: `Clicked change plan.`,
              });
            }}
            // color="grayscale"
            sx={{ textTransform: "none", ml: -1, mt: -1, mb: 3 }}
            startIcon={<ArrowBackIosNewRounded />}
          >
            <Typography sx={{ fontSize: "16px", textDecoration: "underline" }}>
              Change plan
            </Typography>
          </Button>
        )}
        <Collapse in={view === "select"}>
          <Box sx={{ maxWidth: "600px" }}>
            <>
              {adminMode && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  {/* <Typography align="left" fontWeight={"600"} fontSize={"1.15rem"}>
              Complete your signup fee
            </Typography> */}
                  <Alert
                    color={getStatusColor(signupFeeStatus)}
                    icon={false}
                    sx={{
                      ml: 1,
                      px: 1,
                      py: 0.5,
                      borderRadius: "15px",
                    }}
                  >
                    {signupFeeStatus}
                  </Alert>
                </Box>
              )}
              <Typography variant="h4" sx={{ mt: { xs: 0, sm: 2 }, mb: 0 }}>
                Choose your plan
              </Typography>
              <Typography align="left" color={"text.secondary"} sx={{ mb: 2 }}>
                Please select the plan that's best for you.
              </Typography>
            </>
            {/* {!adminMode && (
            <List dense={true} sx={{ mt: 1, mb: 2 }}>
              {benefits.map((benefit, idx) => (
                <ListItem key={idx} disableGutters>
                  <Check color="primary" sx={{ mr: 1 }} />
                  <Typography variant="body1">{benefit}</Typography>
                </ListItem>
              ))}
            </List>
          )} */}
          </Box>
        </Collapse>
        {view === "select" && (
          <PlansRadioGroup
            onClick={handleClick}
            selectedPlan={selectedPlan}
            shrink={view === "pay" ? true : false}
          />
        )}

        <Collapse in={view === "pay"}>
          <>
            <Box
              sx={{
                gap: 1,
                display: "flex",
                mt: { xs: 0, sm: 2 },
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 1, sm: 4 },
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {/* Left column */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: 1,
                }}
              >
                <Typography variant="h4" sx={{ mb: 0 }}>
                  Pay with card
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Please enter your card information below.
                </Typography>
                {adminMode && (
                  <Box
                    sx={{
                      ml: 1,
                      mt: 0.5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 1,
                        backgroundColor: "#F5F5F5",
                        borderRadius: "5px",
                        px: "6px",
                      }}
                      variant="caption"
                    >
                      Postcode: {zipCode}
                    </Typography>
                  </Box>
                )}
                {/* <PlansRadioGroup
                  onClick={handleClick}
                  selectedPlan={selectedPlan}
                  shrink={true}
                  showOnlySelected={true}
                  showCheckmark={false}
                /> */}
                <Box
                  sx={{
                    boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.15)",
                    border: "1px solid hsl(190,100%,30%)",
                    p: 2,
                    mt: 1,
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardElement
                    options={{
                      disableLink: true,
                      style: {
                        base: {
                          fontSize: "16px", // Adjust the font size here
                        },
                      },
                    }}
                    onChange={(e) => {
                      if (!hasStartedTyping && !e.empty) {
                        createLog({
                          collectionName: "members",
                          idsArr: [memberId],
                          summary: "Started entering credit card information.",
                        });
                        setHasStartedTyping(true);
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    ml: 1,
                    mt: 0.5,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="caption">
                    Secured by <strong>Stripe</strong>
                  </Typography>
                </Box>
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    align="center"
                    sx={{
                      my: 1,
                      backgroundColor: "hsla(0, 100%, 95%, 1)",
                      p: 2,
                      borderRadius: "15px",
                    }}
                  >
                    {error} For additional support, call Alex at (360) 336-6344.
                  </Typography>
                )}
              </Box>

              {/* Right column */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* Checkout details */}
                <Card sx={{ p: 2, backgroundColor: "#F5F5F5" }} elevation={0}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      gap: 1,
                      alignItems: "flex-start",
                    }}
                  >
                    <Avatar src={logo192} sx={{ boxShadow: 1 }}></Avatar>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 1,
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight={"600"}>
                          Linked Lives {selectedPlan?.chip}
                        </Typography>
                        <Typography fontWeight={"600"}>
                          ${signupFee.toFixed(2)}
                          {isPriorityPlan && "/mo"}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color={"text.secondary"}>
                        {isPriorityPlan
                          ? "Membership billed monthly"
                          : "One-time sign up fee"}
                      </Typography>

                      {isPriorityPlan && (
                        <Typography
                          align="left"
                          variant="caption"
                          color={"text.secondary"}
                          sx={{ mt: 0, mb: 0, ml: "12px" }}
                        >
                          {" "}
                          Next billing cycle on{" "}
                          {DateTime.now().plus({ months: 1 }).toFormat("MMM d")}
                        </Typography>
                      )}

                      <Typography
                        align="left"
                        variant="body1"
                        // color={"text.secondary"}
                        sx={{ mt: 1.5, mb: 0 }}
                      >
                        {" "}
                        ${selectedPlan?.hourlyRate?.toFixed(2)} per hour flat
                        rate
                      </Typography>

                      <Divider sx={{ my: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 1,
                          width: "100%",
                        }}
                      >
                        <Typography>Subtotal</Typography>
                        <Typography>${signupFee.toFixed(2)}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 1,
                          width: "100%",
                          color: "text.secondary",
                        }}
                      >
                        <Typography>Sales tax</Typography>
                        <Typography>$0.00</Typography>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: 1,
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight={"600"}>
                          Total due today
                        </Typography>
                        <Typography fontWeight={"600"}>
                          ${signupFee.toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>

                {/* Button group */}
                {!isMobile && (
                  <Box sx={{ mt: 2 }}>
                    <Box>{renderPaymentButton()}</Box>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </Collapse>
      </DialogContent>
      {isMobile && (
        <Collapse in={view === "pay"}>
          <DialogActions>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {renderPaymentButton()}
            </Box>
          </DialogActions>
        </Collapse>
      )}
    </>
  );
};

export default SignupFeePayment;
