import { Button, CircularProgress, Typography } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";
import HelperSearchSelect from "./HelperSearchSelect";

const HelperReferralField = ({ employeePrivateData, onSaveSuccess }) => {
  console.log("employeePrivateData: ", employeePrivateData);

  const { id: userId = "", referredBy: currentReferral = null } =
    employeePrivateData || {};

  const [referral, setReferral] = useState(currentReferral);
  const [loading, setLoading] = useState(false);

  const hasChanges = currentReferral?.id !== referral?.id;

  console.log("currentReferral: ", currentReferral);
  console.log("referral: ", referral);

  const handleHelperSelect = (helper) => {
    setReferral(helper);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await setDoc(
        doc(db, "employeesPrivate", userId),
        {
          referredBy: referral
            ? {
                id: referral?.id,
                firstName: referral.firstName,
                lastName: referral.lastName,
                avatarUrl: referral.avatarUrl,
              }
            : null,
        },
        { merge: true }
      );
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    } catch (err) {
      console.error("Error saving referral:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>Referred by</Typography>
      <HelperSearchSelect
        onSelect={handleHelperSelect}
        initialValue={referral}
        referral={currentReferral}
        variant="text"
      />
      {hasChanges && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ width: "100%", height: "56px", textTransform: "none" }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
        </Button>
      )}
    </>
  );
};

export default HelperReferralField;
