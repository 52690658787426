// providers/ContentProvider.jsx
import React, { createContext, useContext, useState, useEffect } from "react";
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";

const ContentContext = createContext();

export const useContent = () => useContext(ContentContext);

export const ContentProvider = ({ userRole, children }) => {
  const { currentUser } = useAuth();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchVideos = async () => {
    if (loading) return;
    console.log("ContentProvider - fetching videos")
    setLoading(true);
    try {
      let constraints = [orderBy("createdAt", "desc"), limit(5)];
      if (lastDoc) {
        constraints.splice(1, 0, startAfter(lastDoc));
      }
      if (userRole !== "admin") {
        constraints.unshift(where("userId", "==", currentUser.uid));
      }
      const q = query(collection(db, "content"), ...constraints);
      const querySnapshot = await getDocs(q);
      const newVideos = [];
      querySnapshot.forEach((docSnap) => {
        newVideos.push({ id: docSnap.id, ...docSnap.data() });
      });
      if (querySnapshot.docs.length > 0) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setHasMore(false);
      }
      setVideos((prev) => [...prev, ...newVideos]);
    } catch (err) {
      console.error("Error fetching videos: ", err);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <ContentContext.Provider value={{ videos, setVideos, loading, hasMore, fetchVideos }}>
      {children}
    </ContentContext.Provider>
  );
};
