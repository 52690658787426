import {
  PasswordOutlined,
  PinOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const PasswordInput = ({
  onBack,
  onNext,
  onSmsLogin,
  values,
  handleChange,
  errors,
  setErrors,
  loading,
  showPassword,
  setShowPassword,
  handleLogin,
}) => {
  const handleBack = () => {
    // Update only the password field in the errors object
    setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    onBack();
  };

  return (
    <>
      <>
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          name="password"
          type={showPassword ? "text" : "password"}
          value={values.password}
          onChange={(e) =>
            handleChange({
              target: { name: "password", value: e.target.value },
            })
          }
          error={!!errors.password}
          helperText={
            <>
              {errors.password}
              <Typography
                align="left"
                color="primary"
                fontSize={"0.8rem"}
                fontWeight={"600"}
                onClick={onSmsLogin}
                sx={{
                  mt: 1,
                  ml: 1,
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Forgot password?
              </Typography>
            </>
          }
          sx={{ mb: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <Button
          variant="text"
          onClick={onSmsLogin}
          sx={{ width: "100%", height: "56px", textTransform: "none" }} // Added marginTop to push the button to the bottom
        >
          <Typography variant="body2" color="primary">
            Forgot Password?
          </Typography>
        </Button> */}
        <Button
          variant={values.password === "" ? "outlined" : "contained"}
          onClick={handleLogin}
          disabled={loading || values.password === ""} // Disabled if loading or email is invalid
          sx={{ width: "100%", height: "56px", mt: 2, textTransform: "none" }} // Added marginTop to push the button to the bottom
          startIcon={<PasswordOutlined />}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Login with password"
          )}
        </Button>
        <Divider sx={{ width: "100%", my: 2 }}>
          <Typography
            variant="body2"
            component="span"
            color="textSecondary"
            mx={2}
          >
            or
          </Typography>
        </Divider>
        <Button
          variant={values?.password ? "outlined" : "contained"}
          onClick={onSmsLogin}
          disabled={loading}
          startIcon={<PinOutlined />} // Add the icon as the startIcon
          sx={{ width: "100%", height: "56px", textTransform: "none" }} // Added marginTop to push the button to the bottom
        >
          Send Login Code
        </Button>
      </>
    </>
  );
};

export default PasswordInput;
