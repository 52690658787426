import { Alert, Box, Collapse, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "../../providers/SnackbarProvider";

import { Today } from "@mui/icons-material";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { useSession } from "../../providers/SessionProvider";
import { formatTimeRange } from "../../services/dateServices";
import BookingOptions from "./BookingOptions";
import DateDisplay from "./DateDisplay";
import DatePicker from "./DatePicker";
import VisitDisplay from "./VisitDisplay";

const VisitPicker = ({
  onSelectSlot = () => {},
  onSelectDate = () => {},
  visits,
  selectedVisit,
  setSelectedVisit,
  setFilters,
  filters,
  employees,
  timeZoneId,
  memberId,
  parentSelectedDate = null,
  bestMatchEmployee = null,
  adminMode = false,
  isOpen = true,
  isDashboard = false,
  userRole = "member",
}) => {
  const { showSnackbar } = useSnackbar();
  const { createLog } = useSession();
  const [isStacked, setIsStacked] = useState(false);
  const containerRef = useRef(null);

  console.log("VisitPicker - isStacked: ", isStacked);

  useEffect(() => {
    const checkStacked = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        console.log("VisitPicker - containerWidth: ", containerWidth);
        setIsStacked(containerWidth < 700); // Set to true if width is less than 500px
      }
    };

    const delayedCheck = () => {
      console.log("VisitPicker - delayedCheck");
      setTimeout(checkStacked, 1000); // Add a 300ms delay
    };

    delayedCheck(); // Initial check with delay

    checkStacked(); // Initial check

    window.addEventListener("resize", checkStacked); // Listen for window resize

    return () => {
      window.removeEventListener("resize", checkStacked); // Cleanup listener
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Additional states for selected date, filters, and available visits
  const [selectedDate, setSelectedDate] = useState(
    parentSelectedDate
      ? moment(parentSelectedDate).tz(timeZoneId).format("YYYY-MM-DD")
      : null
  );
  const [filteredVisits, setFilteredVisits] = useState([]); // filtered visits

  const [availableVisits, setAvailableVisits] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);

  console.log("selectedDate: ", moment(selectedDate).tz(timeZoneId));

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (filteredVisits.length > 0 && availableVisits.length === 0) {
      setSelectedDate(null);
    }
  }, [availableVisits]);

  // If we have selected a day (no visit yet) and that day is no longer available
  // OR If we have selected a visit and that visit is no longer available
  useEffect(() => {
    if (!selectedDate) return;

    console.log("selectedDate: ", selectedDate);

    // Check if there are no more visits on the previously selected day
    const selectedDay = moment(selectedDate).tz(timeZoneId);
    console.log("selectedDay: ", selectedDay.format("MM-DD hh:mm a"));
    const visitsOnSelectedDay = Array.isArray(visits)
      ? visits.filter((visit) => {
          // console.log(
          //   "moment(visit.start).tz(timeZoneId): ",
          //   moment(visit.start).tz(timeZoneId).format("MM-DD hh:mm a")
          // );

          const result = moment(visit.start)
            .tz(timeZoneId)
            .isSame(selectedDay, "day");

          // console.log("result: ", result);
          return result;
        })
      : [];

    if (visitsOnSelectedDay.length === 0) {
      console.log("there are no visits on the selected day");
      setSelectedDate(null);
    }

    if (
      isOpen &&
      selectedVisit.key &&
      !visits.some(
        (visit) =>
          generateVisitKey(memberId, visit, filters.frequency) ===
          selectedVisit.key
      )
    ) {
      setSelectedVisit({}); // Reset selectedVisit
      showSnackbar("The selected time is no longer available.", "warning");
    }
  }, [visits]);

  if (!Array.isArray(visits)) {
    console.error("Visits data is not an array or not loaded yet.");
    return null; // Or return a loading state/component
  }

  const handleDateChange = (date) => {
    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a date${
          isDashboard ? " from the Dashboard" : ""
        }: ${DateTime.fromISO(date).toFormat("LLL d")}`,
        logType: "activity",
      });
    }
    setSelectedVisit({}); // Reset selectedVisit and its key
    setSelectedDate(date);
    onSelectDate(date); // Call the parent function to handle date change
  };

  const handleTimeSlotSelect = (visit) => {
    const visitKey = generateVisitKey(memberId, visit, filters.frequency);

    setSelectedVisit({
      visit,
      key: visitKey,
    });

    const rangeString = formatTimeRange(visit.start, visit.end, timeZoneId);

    console.log("visit: ", visit);
    console.log("employees: ", employees);

    const employeeNames = (visit.employeeIds || [])
      .map((id) => {
        const employee = employees[id];
        return employee ? employee.firstName : "Unknown";
      })
      .join(", ");

    if (!adminMode) {
      createLog({
        collectionName: "members",
        idsArr: [memberId],
        summary: `Selected a time slot with ${employeeNames} at ${rangeString} on ${DateTime.fromMillis(
          visit.start
        ).toFormat("LLL d")}`,
        logType: "activity",
      });
    }
    onSelectSlot();
  };

  const generateVisitKey = (memberId, visit, frequency) => {
    return `${memberId}-${visit.employeeIds.join("-")}-${visit.start}-${
      visit.end
    }-${frequency}-${visit.duration}`;
  };

  const resetFilters = () => {
    setFilters({
      duration: 2,
      frequency: 0,
      employeeIds: [],
      numEmployees: "1",
    });
  };

  const gridSpacing = isMobile ? 0 : 2;

  return (
    <Box ref={containerRef}>
      {/* {!isDashboard && ( */}
      <BookingOptions
        memberId={memberId}
        isStacked={isStacked || isDashboard}
        filters={filters}
        setFilters={setFilters}
        handleReset={resetFilters}
        visits={visits}
        employees={employees}
        bestMatchEmployee={bestMatchEmployee}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        onFilterVisits={(values) => setFilteredVisits(values)}
        onFilterVisitsDate={(values) => setAvailableVisits(values)}
      />
      {/* )} */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: { xs: 2, sm: 4 },
          p: isStacked || isDashboard ? 0 : 2,
        }}
      >
        <Box
          sx={{
            flex: "1 1 calc(50% - 16px)",
            minWidth: "260px", // Minimum width for each box
            mx: isDashboard ? -2 : 0,
          }}
        >
          {isMobile && !isDashboard && (
            <Collapse in={!selectedDate} sx={{ mb: 1, mx: "-12px" }}>
              <Alert icon={<Today fontSize="inherit" />} severity="primary">
                Select a date to see available times.
              </Alert>
            </Collapse>
          )}
          {/* Calendar Component */}
          <DatePicker
            timeZoneId={timeZoneId}
            visits={filteredVisits}
            filters={filters}
            selectedDate={selectedDate}
            onChange={handleDateChange}
            allowCollapse={isStacked || isMobile}
          />
        </Box>

        <Box
          sx={{
            flex: "1 1 calc(50% - 16px)",
            minWidth: "260px", // Minimum width for each box
          }}
        >
          {!isMobile && !isDashboard && (
            <Collapse in={!selectedDate} sx={{ mb: 1 }}>
              <Alert icon={<Today fontSize="inherit" />} severity="primary">
                Select a date to see available times.
              </Alert>
            </Collapse>
          )}
          {selectedDate && (
            <>
              <DateDisplay selectedDate={selectedDate} />
              <Collapse
                in={Object.keys(selectedVisit).length === 0}
                sx={{ mx: { xs: "-8px", sm: 0 } }}
              >
                <Alert icon={<Today fontSize="inherit" />} severity="primary">
                  Select a time slot below to continue
                </Alert>
              </Collapse>
              <div style={{ height: "8px" }} />
              {availableVisits.length > 0 && (
                <VisitDisplay
                  isStacked={isStacked}
                  timeZoneId={timeZoneId}
                  memberId={memberId}
                  filters={filters}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedVisit={selectedVisit}
                  employees={employees}
                  availableVisits={availableVisits}
                  bestMatchEmployee={bestMatchEmployee}
                  handleTimeSlotSelect={handleTimeSlotSelect}
                  userRole={userRole}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VisitPicker;
