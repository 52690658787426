import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase"; // Ensure correct import of Firebase config

/**
 * Fetch user data from membersPrivate and employeesPrivate collections
 * based on a phone number.
 *
 * @param {string} phoneNumber - The phone number to search for.
 * @returns {Promise<Object|Array|null>} - Single match (Object), multiple matches (Array), or null if no match.
 */
export async function fetchUserPrivateData(input) {
  if (!input) return null;

  // Normalize input: Remove "client:" prefix if present
  let cleanedInput = input.replace(/^client:/i, "");

  // Determine if input is a UID (contains letters) or a phone number
  const isUID = /[a-zA-Z]/.test(cleanedInput);

  if (!isUID) {
    // If it's a phone number, remove "+1" prefix and non-numeric characters
    cleanedInput = cleanedInput.replace(/^\+1/, "").replace(/\D/g, "");
  }

  if (!cleanedInput) return null; // Ensure valid input

  if (isUID) {
    // Query Firestore using UID

    // Query Firestore using UID
    const userDocRef = doc(db, "employeesPrivate", cleanedInput);
    const userDocSnap = await getDoc(userDocRef);
    return userDocSnap.exists()
      ? { id: userDocSnap.id, userRole: "helper", ...userDocSnap.data() }
      : null;
  } else {
    // Query Firestore using phone number
    const queryConfigs = [
      {
        collection: "membersPrivate",
        fields: ["phone", "phone2"],
        userRole: "member",
      },
      {
        collection: "employeesPrivate",
        fields: ["phone"],
        userRole: "helper",
      },
    ];

    for (const config of queryConfigs) {
      for (const field of config.fields) {
        const q = query(
          collection(db, config.collection),
          where(field, "==", cleanedInput),
          limit(1)
        );
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const docSnap = snapshot.docs[0];
          return {
            id: docSnap.id,
            userRole: config.userRole,
            ...docSnap.data(),
          };
        }
      }
    }
  }

  return null;
}
