import { doc, setDoc } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import React, { Suspense, lazy, useEffect } from "react";
import CallInterface from "../../components/calls/CallInterface";
import DrawerContainer from "../../components/drawers/DrawerContainer";
import LoadingUser from "../../components/LoadingUser";
import { db, messaging } from "../../firebase";
import { useAuth } from "../../providers/AuthProvider";
import { CallsProvider } from "../../providers/CallsProvider";
import { CRMProvider } from "../../providers/CRMProvider";
import { DrawerProvider } from "../../providers/DrawerProvider";
import EmployeeProvider from "../../providers/EmployeeProvider";
import { MessagesProvider } from "../../providers/MessagesProvider";
import { PollsProvider } from "../../providers/PollsProvider";
import { TwilioProvider } from "../../providers/TwilioProvider";
import { ContentProvider } from "../../providers/ContentProvider";

const AdminDashboard = lazy(() => import("./AdminDashboard"));

const LazyAdminDashboard = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    const updateToken = async () => {
      if (!currentUser?.uid || Notification?.permission !== "granted") return;
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BLa-Fyoxa6OX995BxsbtUYXbDLMicSzSijE4PhExVvCw1pujSn-dp6AnUlX80mBII2ig61Ts74XwtPdOk08-mjQ",
        });
        if (token) {
          await setDoc(
            doc(db, "employeesPrivate", currentUser?.uid),
            { fcmToken: token },
            { merge: true }
          );
          console.log("FCM Token updated:", token);
        }
      } catch (error) {
        console.error("Error updating FCM token:", error);
      }
    };

    updateToken();
  }, [currentUser?.uid]);

  return (
    <Suspense fallback={<LoadingUser />}>
      <TwilioProvider>
        <EmployeeProvider>
          <CRMProvider>
            <DrawerProvider>
              <CallsProvider>
                <PollsProvider>
                  <MessagesProvider>
                    <ContentProvider userRole={"admin"}>
                      <CallInterface />
                      <DrawerContainer />
                      <AdminDashboard />
                    </ContentProvider>
                  </MessagesProvider>
                </PollsProvider>
              </CallsProvider>
            </DrawerProvider>
          </CRMProvider>
        </EmployeeProvider>
      </TwilioProvider>
    </Suspense>
  );
};

export default LazyAdminDashboard;
