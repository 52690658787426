export const setBadgeCount = async (bin, newCount) => {
  try {
    // alert("Opening IndexedDB...");
    const request = indexedDB.open("linked-lives-db", 1);

    request.onupgradeneeded = (event) => {
      // alert("IndexedDB upgrade needed...");
      const db = event.target.result;
      if (!db.objectStoreNames.contains("badges")) {
        db.createObjectStore("badges", { keyPath: "id" });
        // alert("Created 'badges' object store.");
      }
    };

    request.onsuccess = () => {
      // alert("IndexedDB opened successfully.");
      const db = request.result;
      const transaction = db.transaction("badges", "readwrite");
      const store = transaction.objectStore("badges");

      let badgeValue = Math.max(0, Number(newCount) || 0); // Convert to number, default to 0 if NaN

      // alert(`Setting badge count for ${bin} to ${badgeValue}`);

      // Overwrite the specific bin (messages, calls, etc.)
      const putRequest = store.put({ id: bin, count: badgeValue });

      putRequest.onsuccess = () => {
        // alert(`Successfully set ${bin} badge to ${badgeValue}`);
      };

      putRequest.onerror = (event) => {
        alert(`Error updating badge count for ${bin}: ${event.target.error}`);
      };

      // Recalculate total badge count and update the app badge
      recalculateTotalBadgeCount()
        .then(() => {
          // alert("Successfully recalculated total badge count.");
        })
        .catch((error) => {
          alert(`Error recalculating total badge count: ${error}`);
        });
    };

    request.onerror = (event) => {
      alert(`IndexedDB error opening database: ${event.target.error}`);
    };
  } catch (error) {
    alert(`Unexpected error in setBadgeCount: ${error.message || error}`);
  }
};

// Function to increment or decrement badge count
export const incrementBadgeCount = (bin, delta) => {
  return new Promise((resolve, reject) => {
    // alert("Opening IndexedDB...");
    const request = indexedDB.open("linked-lives-db", 1);

    request.onsuccess = () => {
      // alert("IndexedDB opened successfully");
      const db = request.result;
      const transaction = db.transaction("badges", "readwrite");
      const store = transaction.objectStore("badges");

      // alert(`Fetching current value for bin: ${bin}`);
      // Fetch current value
      const getRequest = store.get(bin);
      getRequest.onsuccess = () => {
        let currentCount = getRequest.result?.count || 0;
        let newCount = Math.max(0, currentCount + delta); // Ensure non-negative values

        // alert(`Current count: ${currentCount}, Delta: ${delta}, New count: ${newCount}`);

        // Update the bin
        const putRequest = store.put({ id: bin, count: newCount });

        putRequest.onsuccess = () => {
          // alert("Badge count updated successfully");
          // Recalculate total badge count and update the app badge
          recalculateTotalBadgeCount()
            .then(() => {
              // alert("Total badge count recalculated successfully");
              resolve();
            })
            .catch((error) => {
              alert("Error recalculating total badge count: " + error);
              reject(error);
            });
        };

        putRequest.onerror = (event) => {
          alert("Error updating badge count: " + event.target.error);
          reject(event.target.error);
        };
      };

      getRequest.onerror = (event) => {
        alert("Error fetching badge count: " + event.target.error);
        reject(event.target.error);
      };
    };

    request.onerror = (event) => {
      alert("Error opening IndexedDB: " + event.target.error);
      reject(event.target.error);
    };
  });
};

// Function to sum all badge bins and update the app badge
const recalculateTotalBadgeCount = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("linked-lives-db", 1);

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("badges", "readonly");
      const store = transaction.objectStore("badges");

      const getAllRequest = store.getAll();

      getAllRequest.onsuccess = () => {
        // Sum badge counts across all bins (messages, calls, notifications, etc.)
        const totalCount = getAllRequest.result.reduce((sum, item) => sum + item.count, 0);

        // Update the app badge
        if ("setAppBadge" in navigator) {
          navigator.setAppBadge(totalCount).catch((err) => console.error("Failed to set badge:", err));
        }

        resolve(totalCount);
      };

      getAllRequest.onerror = reject;
    };

    request.onerror = reject;
  });
};

// Function to clear all badge bins
export const clearAllBadges = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("linked-lives-db", 1);

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("badges", "readwrite");
      const store = transaction.objectStore("badges");

      store.clear(); // Remove all badge counts

      // Reset App Badge
      if ("clearAppBadge" in navigator) {
        navigator.clearAppBadge().catch((err) => console.error("Failed to clear badge:", err));
      }

      resolve();
    };

    request.onerror = reject;
  });
};

export const clearBadgeBin = (bin) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("linked-lives-db", 1);

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("badges", "readwrite");
      const store = transaction.objectStore("badges");

      // Set the specific bin to 0
      store.put({ id: bin, count: 0 });

      // Recalculate total badge count and update the app badge
      recalculateTotalBadgeCount().then(resolve).catch(reject);
    };

    request.onerror = reject;
  });
};
