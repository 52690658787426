import { Device } from "@twilio/voice-sdk";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { db, functions } from "../firebase";
import { fetchUserPrivateData } from "../services/userServices";
import { useAuth } from "./AuthProvider";

const TwilioContext = createContext(null);

const MAX_RETRIES = 3; // Maximum number of retries for initialization
const RETRY_DELAY_MS = 5000; // Delay between retries in milliseconds
const TOKEN_REFRESH_INTERVAL_MS = 50 * 60 * 1000; // Refresh token every 50 minutes

export function useTwilio() {
  return useContext(TwilioContext);
}

export function TwilioProvider({ children }) {
  const { customClaims, currentUser } = useAuth();

  const fetchTwilioToken = async () => {
    const getToken = httpsCallable(functions, "getTwilioAccessToken");
    const result = await getToken();
    const token = result.data.token;

    const userRef = doc(db, "employeesPrivate", currentUser?.uid);
    await updateDoc(userRef, {
      twilioClientActive: serverTimestamp(), // Set last active time
    });

    return token;
  };

  const [device, setDevice] = useState(null);
  const [incomingCall, setIncomingCall] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [callSid, setCallSid] = useState(null);
  const [callStart, setCallStart] = useState(null);
  const [callerInfo, setCallerInfo] = useState({});
  const [isMuted, setIsMuted] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(false);

  // useState({
  //   callerNumber: "1234567890",
  //   firstName: "Alex",
  //   lastName: "Rodriguez",
  // });

  const deviceRef = useRef(null);

  const refreshToken = async () => {
    try {
      const newToken = await fetchTwilioToken();
      if (deviceRef.current) {
        deviceRef.current.updateToken(newToken);
        console.log("Token refreshed successfully.");
      }
    } catch (error) {
      console.error("Failed to refresh Twilio token:", error);
    }
  };

  const initializeDevice = async (retryCount = 0) => {
    try {
      // https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice
      const token = await fetchTwilioToken();

      // Initialize the Device
      // const newDevice = new Device(token, { logLevel: "debug" });

      const deviceOptions = {
        // logLevel: "debug"
        allowIncomingWhileBusy: false, // defaults to false
      };

      const newDevice = new Device(token, deviceOptions);

      newDevice.on("registered", () => {
        console.log("Twilio Device is ready");
      });

      newDevice.on("tokenWillExpire", () => {
        refreshToken();
      });

      // Handle incoming calls
      newDevice.on("incoming", handleIncomingCall);

      newDevice.on("error", (error) => {
        console.error("Twilio Device error:", error);
      });

      deviceRef.current = newDevice;
      setDevice(newDevice);

      await newDevice.register();
      console.log("Device registered and ready for outgoing calls");
    } catch (error) {
      console.error(
        `Failed to initialize Twilio Device (Attempt ${retryCount + 1}):`,
        error
      );

      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
        setTimeout(() => initializeDevice(retryCount + 1), RETRY_DELAY_MS);
      } else {
        console.error(
          "Max retries reached. Failed to initialize Twilio Device."
        );
      }
    }
  };

  useEffect(() => {
    const isAdmin = customClaims?.isAdmin || false;
    if (isAdmin) {
      initializeDevice();
    }

    return () => {
      if (deviceRef.current) {
        deviceRef.current.destroy();
        deviceRef.current = null;
        setDevice(null);
      }
    };
  }, [customClaims]);

  const handleIncomingCall = async (call) => {
    const rawPhoneNumber = call.parameters.From || "Unknown Caller";

    setIncomingCall(call);

    const userData = await fetchUserPrivateData(rawPhoneNumber);

    if (userData) {
      setCallerInfo({
        ...userData, // Spread existing user data if found
        callerNumber: rawPhoneNumber, // Always include the raw caller number as a fallback
      });
    } else {
      setCallerInfo({
        callerNumber: rawPhoneNumber, // Ensure callerNumber is always set
      });
    }

    call.on("accept", () => {
      console.log("Incoming call accepted");
      setCallInProgress(true);
      setCallStart(Date.now());
      setCallSid(call.parameters.CallSid || null);
    });

    call.on("disconnect", () => {
      console.log("Incoming call ended");
      resetCallState();
    });

    call.on("error", (error) => {
      console.error("Error during incoming call:", error);
    });
  };

  const makeOutgoingCall = async (toNumber) => {
    if (!deviceRef.current) {
      console.error("Twilio Device is not initialized");
      return;
    }

    try {
      // Fetch user details by phone number
      const userData = await fetchUserPrivateData(toNumber);

      const call = await deviceRef.current.connect({
        params: { To: toNumber },
      });

      // Set caller information
      setCallerInfo(
        userData
          ? { ...userData, callerNumber: toNumber }
          : { callerNumber: toNumber }
      );

      // Update call state
      setCallInProgress(true);
      setCallStart(Date.now());
      setCallSid(call.parameters.CallSid || null);

      call.on("accept", () => {
        console.log("Outgoing call connected");
        setCallInProgress(true);
        setCallStart(Date.now());
      });

      call.on("disconnect", () => {
        console.log("Outgoing call ended");
        resetCallState();
      });

      call.on("error", (error) => {
        console.error("Error during outgoing call:", error);
        resetCallState();
      });
    } catch (error) {
      console.error("Failed to initiate outgoing call:", error);
    }
  };

  const resetCallState = () => {
    setIncomingCall(null);
    setCallInProgress(false);
    setCallSid(null);
    setCallStart(null);
    setCallerInfo("");
  };

  const answerCall = () => {
    if (incomingCall) {
      incomingCall.accept();
    }
  };

  const declineCall = () => {
    if (incomingCall) {
      incomingCall.reject();
      resetCallState();
    }
  };

  const endCall = () => {
    deviceRef.current.disconnectAll();
    resetCallState();
  };

  const toggleMute = () => {
    const activeCall = deviceRef.current?.calls[0];
    if (activeCall) {
      const newMuteState = !isMuted;
      activeCall.mute(newMuteState);
      setIsMuted(newMuteState);
    }
  };

  const toggleSpeaker = () => {
    const activeCall = deviceRef.current?.calls[0];
    if (activeCall) {
      const newSpeakerState = !isSpeakerOn;
      activeCall.audio.speaker(newSpeakerState);
      setIsSpeakerOn(newSpeakerState);
    }
  };

  return (
    <TwilioContext.Provider
      value={{
        device,
        incomingCall,
        callerInfo,
        callInProgress,
        answerCall,
        declineCall,
        endCall,
        makeOutgoingCall,
        toggleMute,
        toggleSpeaker,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
}
