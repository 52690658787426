import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import {
  Call,
  KeyboardArrowLeft,
  Password,
  PasswordOutlined,
  PhoneIphone,
  PinOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const LoginView = ({ email, setView, showBack = false }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const auth = getAuth();

  // Handle Login with email and password
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    let newError; // initialize newError object here
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case "auth/missing-password":
          newError = "You must enter your password.";
          break;
        case "auth/invalid-email":
          newError = "The email address is badly formatted.";
          break;
        case "auth/user-not-found":
          newError = "There is no user with this email.";
          break;
        case "auth/wrong-password":
          newError = "The password is incorrect.";
          break;
        default:
          newError = "An unknown error occurred. Please try again later.";
          break;
      }
      setError(newError);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
    setError("");
  };

  return (
    <Box>
      {showBack && (
        <ButtonBase
          onClick={() => setView("EMAIL")}
          disabled={loading}
          sx={{ color: "text.secondary", my: 1 }}
        >
          <KeyboardArrowLeft /> Back
        </ButtonBase>
      )}
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
        Welcome back
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Enter password to login or receive a code.
      </Typography>
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        name="password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleChange}
        error={!!error}
        helperText={
          <>
            {error}
            <Typography
              align="left"
              color="primary"
              fontSize={"0.8rem"}
              fontWeight={"600"}
              onClick={() => {
                setView("SMS");
              }}
              sx={{
                mt: 1,
                ml: 1,
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Forgot password?
            </Typography>
          </>
        }
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        variant={password === "" ? "outlined" : "contained"}
        onClick={handleLogin}
        disabled={loading || password === ""} // Disabled if loading or email is invalid
        sx={{ width: "100%", height: "56px", mt: 1, textTransform: "none" }} // Added marginTop to push the button to the bottom
        startIcon={<PasswordOutlined />} // Add the icon as the startIcon
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Login with Password"}
      </Button>
      <Divider sx={{ width: "100%", my: 2 }}>
        <Typography
          variant="body2"
          component="span"
          color="textSecondary"
          mx={2}
        >
          or
        </Typography>
      </Divider>
      <Button
        variant= {password ? "outlined" : "contained"}
        onClick={() => setView("SMS")}
        disabled={loading}
        startIcon={<PinOutlined />} // Add the icon as the startIcon
        sx={{ width: "100%", height: "56px", textTransform: "none" }} // Added marginTop to push the button to the bottom
      >
        Send Login Code
      </Button>
    </Box>
  );
};

export default LoginView;
