import { Box, Chip, useMediaQuery } from "@mui/material";
import React from "react";
import { TASKS_LIST } from "../../services/taskServices";

const TaskChips = ({ tasks }) => {
  const matchedTasks = (tasks || TASKS_LIST.map((t) => t.title))
    .map((taskTitle) =>
      TASKS_LIST.find(
        (t) => t.title === taskTitle || t.helperTitle === taskTitle
      )
    )
    .filter(Boolean); // removes undefined

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        mx: { xs: -2, sm: 0 },
        justifyContent: { xs: "center", sm: "flex-start" },
      }}
    >
      {matchedTasks.map((task) => (
        <Chip
          key={task.title}
          icon={task.iconOutlined ?? null}
          // variant="outlined"

          size={isMobile ? "small" : "medium"}
          label={task.title}
          sx={{ py: 2, px: 1, borderRadius: "10px", backgroundColor: "#F5F5F5" }}
        />
      ))}
    </Box>
  );
};

export default TaskChips;
