import { InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import BadgesGroup from "./BadgesGroup";
import LevelBar from "./LevelBar";
import { getActiveBadges } from "./ProgressTab";

const PointsBar = ({
  showHeader = true,
  currentLevel,
  totalMultiplier,
  progress,
  pointsInCurrentLevel,
  progressPercentage,
  handleOpenLevelUp,
  showLevelUp,
  onClick = () => {},
  avatarUrl = "",
}) => {
  const activeBadges = getActiveBadges(progress);

  return (
    <>
      {/* <Header
        title={"Overview"}
        // subtitle={"Earn points by completing visits."}
        IconComponent={Insights}
      /> */}
      {showHeader && (
        <Avatar
          src={avatarUrl}
          sx={{
            width: { xs: "64px", sm: "96px" },
            height: { xs: "64px", sm: "96px" },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 0.5,
          mt: 0.5,
          flexWrap: "wrap",
          justifyContent: { xs: "space-around", sm: "center" },
        }}
      >
        {/* Header with Level and Progress */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            justifyContent: { xs: "space-between", sm: "space-between" },
            alignItems: { xs: "center", sm: "baseline" },
            width: "100%",
          }}
        >
          {/* Level display */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1.75rem", sm: "2rem" },
                color: "#333",
              }}
            >
              Level {currentLevel}
            </Typography>
          </Box>
        </Box>

        <LevelBar
          progressPercentage={progressPercentage}
          handleOpenLevelUp={handleOpenLevelUp}
          showLevelUp={showLevelUp}
          pointsInCurrentLevel={pointsInCurrentLevel}
        />

        {/* Badges Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 1,
            mt: 1,
          }}
        >
          <BadgesGroup progress={progress} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              // backgroundColor: "#F5F5F5",
              borderRadius: "5px",
              px: "8px",
            }}
          >
            <Typography
              sx={{
                py: 0.5,
                fontWeight: "bold",
              }}
            >
              {totalMultiplier.toFixed(0)}{" "}
              {totalMultiplier.toFixed(0) == 1 ? "point" : "points"}
            </Typography>
            <Typography variant="body1" sx={{ color: "rgba(0,0,0,0.8)" }}>
              /visit
            </Typography>
          </Box>
        </Box>

        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            mt: "4px",
            color: "#666",
          }}
        >
          $17.00/hr
        </Typography>
        <Typography
          variant="body2"
          sx={{
            position: "relative", // Relative to its new outer container
            mt: "4px",
            right: 0,
            textAlign: "right",
            color: "#666",
          }}
        >
          Next $17.20/hr
        </Typography>
      </Box> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography align="left" variant="caption" color={"text.secondary"}>
            {activeBadges?.length} active{" "}
            {activeBadges?.length === 1 ? "badge" : "badges"}
          </Typography>
        </Box>
        <div style={{ height: "10px" }} />
      </Box>
      <Button
        sx={{
          textTransform: "none",
          width: { xs: "100%", sm: "150px" },
        }}
        startIcon={<InfoOutlined />}
        color="primary"
        variant="outlined"
        onClick={onClick}
      >
        Details
      </Button>
      <div style={{ height: "10px" }} />
    </>
  );
};

export default PointsBar;
