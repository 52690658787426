import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import StyledProgress from "../styled/StyledProgress";

const RatingSummary = ({ ratings }) => {
  if (!ratings) return null;

  const {
    ratingsHistogram = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    byCategory = {},
  } = ratings || {};

  const maxStarCount = Math.max(...Object.values(ratingsHistogram)) || 1;

  // Add these lines right after the ratings destructuring:
  const totalReviews = Object.values(ratingsHistogram).reduce(
    (acc, count) => acc + count,
    0
  );

  const overallRating = totalReviews
    ? Object.entries(ratingsHistogram).reduce(
        (acc, [star, count]) => acc + Number(star) * count,
        0
      ) / totalReviews
    : 0;

  return (
    <Box
      sx={{
        // p: 2,
        // backgroundColor: "white",
        // borderRadius: "15px",
        // boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
        gap: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "stretch", gap: 2 }}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#F5F5F5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "15px",
            px: 2,
          }}
        >
          <Typography variant="caption" color="text.secondary">
            Overall
          </Typography>
          <Typography variant="h3" fontWeight={"500"}>
            {overallRating.toFixed(1)}
          </Typography>
          <Rating value={overallRating} precision={0.1} readOnly size="small" />
          <Typography variant="body2">{totalReviews} reviews</Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          {[5, 4, 3, 2, 1].map((star) => {
            const count = ratingsHistogram[star] || 0;
            const progress = (count / maxStarCount) * 100;
            return (
              <Box
                key={star}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Typography sx={{ width: "30px" }}>{star}</Typography>
                <Box sx={{ flexGrow: 1, mr: 2 }}>
                  <StyledProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: 10, borderRadius: "5px" }}
                  />
                </Box>
                {/* <Typography>{count}</Typography> */}
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: 1,
          mt: 1,
        }}
      >
        {Object.entries(byCategory)
          .sort(([, a], [, b]) => b.count - a.count) // Sort descending by count
          .map(([category, data]) => {
            const average = data.count ? data.totalScore / data.count : 0;
            return (
              <Box
                key={category}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // justifyContent: "space-between",
                  p: 1,
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{ mr: 1, flexGrow: { xs: 1, sm: 0 }, fontWeight: "550" }}
                >
                  {category}
                </Typography>
                <Rating value={average} precision={0.1} readOnly size="small" />
                <Typography
                  sx={{ textAlign: "right", color: "text.secondary" }}
                >
                  ({data.count})
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default RatingSummary;
