import { DoubleArrow } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom for routing

const GetStartedButton = ({
  color="success",
  isStandalone = false,
  width = { xs: "100%", sm: "400px" },
  buttonText = "Get started online",
  buttonUrl = "/get-started",
}) => {
  const renderButton = () => (
    <Button
      variant="contained"
      size="large"
      color={color}
      disableElevation
      component={Link} // Use the Link component as the root node of the Button
      to={buttonUrl} // Use 'to' instead of 'href' for react-router's Link
      sx={{
        width,
        height: "60px",
        textTransform: "none",
        mx: "auto",
        position: "relative", // Needed for the overlay
        overflow: "hidden", // Ensures the effect stays within the button boundaries
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: "-150%", // Start off the button
          width: "100%", // Make it wide enough to cover the button
          height: "100%",
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
          transform: "skewX(-20deg)", // Slight skew to angle the wipe
          animation: "wipe 2.5s infinite", // Smooth, infinite animation
        },
        "@keyframes wipe": {
          "0%": { left: "-200%" },
          "100%": { left: "200%" }, // Move it fully across
        },
      }}
      startIcon={<DoubleArrow />} // Add start icon
    >
      {buttonText}
    </Button>
  );

  if (isStandalone) {
    return renderButton();
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: 2,
        boxSizing: "border-box",
        mt: 2,
      }}
    >
      {renderButton()}
    </Box>
  );
};

export default GetStartedButton;
