import { httpsCallable } from "firebase/functions";
import React from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../firebase";

const TestFacebookAdCampaign = () => {
  const { helperId } = useParams();

  const handleCreateCampaign = async () => {
    try {
      const createFacebookAdCampaignTrigger = httpsCallable(
        functions,
        "createFacebookAdCampaignTrigger"
      );

      const result = await createFacebookAdCampaignTrigger({ userId: helperId });
      console.log("Facebook ad campaign created successfully:", result.data);
    } catch (error) {
      // Robust error logging
      console.error("Error calling createFacebookAdCampaignTrigger:", error);
      if (error.code) {
        console.error("Error code:", error.code);
      }
      if (error.details) {
        console.error("Error details:", error.details);
      }
      if (error.message) {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <div>
      <h1>Test Facebook Ad Campaign</h1>
      <button onClick={handleCreateCampaign}>Create Campaign</button>
    </div>
  );
};

export default TestFacebookAdCampaign;
