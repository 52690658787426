// --/services/memberServices.js
import { getFunctions, httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import {
  distanceBetweenGeohashes,
  getEmployeeStatus,
} from "./locationServices";
import { MEMBER_COUNT } from "./variableServices";

export const generalFaq = [
  {
    question: {
      title: "How do I book a visit?",
    },
    answer:
      "Booking a visit is wonderfully quick and easy, both online and over the phone. If you're not already a member, sign up or give us a call. You'll then have access to the entire calendar with all helpers real-time availability, ranging from days to weeks in advance. To book, simply enter your tasks and choose the day, time, and helper that works best for you. You can also book visits over the phone during business hours. We have a 1-hour minimum and you can book in hour increments.",
  },
  {
    question: {
      title: "What tasks can I get help with?",
    },
    answer:
      "For the tasks you don't want to do, can't do anymore, or simply need an extra hand with, our helpers are an excellent choice! This includes yard work, gardening, garage organization, technology help, seasonal decoration, heavy lifting, furniture assembly, cleaning, errands, and more. When you book a visit, you'll be able to write a detailed task list for your helper.",
  },
  {
    question: {
      title: "What does a typical visit look like?",
    },
    answer:
      "In a single visit, your helper can tackle a variety of tasks. They might move heavy boxes from storage, fix a computer issue, scrub your kitchen cabinets, and weed your flower bed. You'll love the versatility!",
  },
  {
    question: {
      title: "Can I pick my helper?",
    },
    answer:
      "Yes, you have complete control who you comes to help. Once you have a favorite helper, you can prioritize booking them for future visits. On our website, you can view the entire schedule for all helpers.",
  },
  {
    question: {
      title: "What if I need more than one helper?",
    },
    answer:
      "You can book multiple helpers depending on their availability. While this is less common, scheduling with two or more helpers can be great for bigger projects like moving furniture, yard work, and deep cleaning. You'll be billed per helper, per hour.",
  },
  {
    question: {
      title: "Are helpers reliable and dependable?",
    },
    answer:
      "Yes, our helpers are reliable, dependable, personable, and excellent in every aspect. These are Linked Lives' core values! All helpers are held to the highest standard. Our members love for the serivces they receive says it all!",
  },
  {
    question: {
      title: "Can I book a helper for same-day help.. today?",
    },
    answer:
      "Yes, depending on the helper's availability. Our calendar gives you the entire monthly availability in real-time, allowing you to book help for your needs as soon as today - if there's an opening.",
  },
  {
    question: {
      title: "How many hours per month do members typically book?",
    },
    answer:
      "About 2 hours per month on average. Our members keep an ongoing to-do list over the weeks, and schedule a visit when ready. Some book weekly, others a couple times per year. It's flexible for your budget and needs!",
  },
  {
    question: {
      title: "How many members do you serve?",
    },
    answer: `We serve over ${
      Math.floor(MEMBER_COUNT / 10) * 10
    }+ older adults who trust Linked Lives to get help with their tasks whenever they need an extra hand.`,
  },
  {
    question: {
      title: "How do I know if your service a good fit for me?",
    },
    answer:
      "If you have household tasks piling up that you don’t want to do, can’t do anymore, or need an extra hand with, we're a great fit for you! With a personal helper, you'll find freedom from time consuming and overwhelming tasks. Just make a list and your helper will take care of it. Our helpers are friendly and flexible to meet your specific needs.",
  },
  {
    question: {
      title: "How do I pay?",
    },
    answer:
      "The day after your visit, we'll bill your preferred payment method or email an invoice. There is no need to pay helpers directly, but tips are appreciated for excellent work that goes above and beyond.",
  },
  {
    question: {
      title: "What is your tipping policy?",
    },
    answer:
      "While tips are not expected, they are always appreciated for excellent work! We love to feel recognized in this way. While we prefer tips in cash, members have also tipped with warm cookies, fresh bread, vibrant flowers, unwanted artwork, spare furniture, and more! Feel free to express your gratitude.",
  },
  {
    question: {
      title: "Are there any minimums or contracts?",
    },
    answer:
      "There are no monthly minimums and no contracts. We make it flexible for you! After paying the $50 one-time sign up fee, you'll get lifetime membership access to our platform. On our Basic plan, there are $0 monthly dues, simply help when needed. On our Premium plan, your $50 sign-up fee gets seamlessly rolled into your monthly dues.",
    },

  {
    question: {
      title: "Do I have to book monthly or is it help as needed?",
    },
    answer:
      "Help as needed! There are no monthly minimums. Simply book whenever you're ready about 4-5 days in advance to get the best selection of days and times. In addition on-demand visits, you can also setup recurring visits every 1, 2, or 4 weeks if that better suits your needs.",
  },
  {
    question: {
      title: "How soon in advance should I schedule?",
    },
    answer:
      "We suggest scheduling at least 4-5 days in advance for the best selection of days and times. Depending on our availability, you can book anywhere from tomorrow, up to a month in advance.",
  },
  {
    question: {
      title: "What happens if I need to cancel or reschedule?",
    },
    answer:
      "You can easily cancel or reschedule bookings anytime on our website or over the phone up to an hour before the visit start time, with no penalty. Review our Cancellation Policy for more details.",
  },
  {
    question: {
      title: "Do I need to provide tools and supplies?",
    },
    answer:
      "Yes, we ask that members provide all the tools and supplies for the tasks you want completed. This ensures that your helper can focus on completing your tasks efficiently, using the tools you're comfortable with and that are best suited for the job. For most of our tasks, this might look like having a rake, vacuum, or ladder ready to go!",
  },
  {
    question: {
      title: "What happens if a visit ends early or goes late?",
    },
    answer:
      "If a visit ends early or runs longer than expected, your helper can round the time up to the nearest 15-minute mark. This ensures you only pay for the time used while maintaining flexibility. Just remember to ask!",
  },
  {
    question: {
      title: "Are you licensed and BBB accredited?",
    },
    answer:
      "Yes, Linked Lives, LLC is a licensed business entity and is BBB accredited. For more details, please review our Terms of Service. There are also hundreds of reviews available on our website for references and ideas on how our members use Linked Lives.",
  },
  {
    question: {
      title: "Are there programs available for those with limited income?",
    },
    answer:
      "Yes, we offer need-based rates for those on limited or fixed income under a certain threshold. Please reach out to us to arrange a custom plan with a team member.",
  },
  {
    question: {
      title: "What do you cost per hour?",
    },
    answer:
      // "Our members book about 2-hours per month on average which keeps it affordable at our flat rate of $40 / hour. We help as needed, anywhere from weekly to seasonally, to fit your budget and needs.",
      "Our members book about 2-hours per month on average which keeps it affordable. Our Basic plan is $40 / hour, and our Premium plan is $25/hour. We help as needed, anywhere from weekly to seasonally, to fit your budget and needs.",

    },
  {
    question: {
      title: "How do you handle travel or mileage?",
    },
    answer:
      "There is no travel or mileage fee for our service. You only pay for the time your helper spends at your house. Our helpers set their own service areas to the neighborhoods near where they live, so you're always getting local help.",
  },
  {
    question: {
      title:
        "If I want a variety of tasks completed during my visit, does it cost more?",
    },
    answer:
      "It's always the same flat hourly rate for all tasks. Our usually members have a whole list of tasks ready for us when we arrive. You can always count on our predictable pricing without the need to wait for a quote.",
  },
  {
    question: {
      title: "What happens if I forget or miss a booked visit?",
    },
    answer:
      "We understand that life can be unpredictable. For last minute cancellations and missed visits, we do charge a $20 fee per cancelled hour that goes to your helper for their inconvenience and loss of opportunity. You can always call or cancel online to an hour before.",
  },
  // {
  //   question: {
  //     title: "What sets your service apart from others?",
  //   },
  //   answer:
  //     "We are set apart as a service that builds strong relationships over time. We show up on-time, ready to help! We love building strong relationships with our members, and provide excellent communication and easy scheduling.",
  // },
  // {
  //   question: {
  //     title: "What is the most bizarre task you've been asked to do?",
  //   },
  //   answer:
  //     "One of the most bizarre tasks involved a customer asking our helper to look for woodpecker markings on the side of their house using binoculars. We love it when our clients think outside the box!",
  // },
  {
    question: {
      title: "What if I'm interested but not ready to book yet?",
    },
    answer:
      "If you're interested but not quite ready, we encourage you to sign up on our website. This lets us keep in touch, share info, and send occasional promotions. By signing up early, you won’t forget about us, and we’ll send helpful updates at your pace. There’s no obligation to book right away, and we’re here to answer any questions as you explore your options.",
  },

  {
    question: {
      title: "What is your mission?",
    },
    answer:
      "Our mission is to foster meaningful, intergenerational relationships through excellent, local, and dependable help. We believe that connecting seniors with young adults forms lifelong friendships that enrich communities and help us gain better perspective and understanding of the world.",
  },
  {
    question: {
      title: "How can I help?",
    },
    answer:
      "There are several ways to support our mission. First, sharing our service with friends, family, and neighbors helps us reach people offline. We love word of mouth! Second, signing up, even if you don't book right away, allows us to stay connected. Most people who visit once might forget about us. We'll send helpful reminders and get to know you over time to better understand your needs.",
  },
];

export const createMemberAccountStripe = async (data) => {
  const functions = getFunctions();
  const createMemberAccountStripeFunction = httpsCallable(
    functions,
    "createMemberAccountStripe"
  );
  try {
    const result = await createMemberAccountStripeFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createMemberAccountStripe function:", error);
    throw error;
  }
};

export const createStripeCustomer = async (data) => {
  const functions = getFunctions();
  const createStripeCustomerFunction = httpsCallable(
    functions,
    "createStripeCustomer"
  );
  try {
    const result = await createStripeCustomerFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createStripeCustomer function:", error);
    throw error;
  }
};

export const createAccount = async (data) => {
  const functions = getFunctions();
  const createAccountFunction = httpsCallable(functions, "createAccount");
  try {
    const result = await createAccountFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAccount function:", error);
    throw error;
  }
};

export const applyReferralDiscount = async (data) => {
  const functions = getFunctions();
  const applyReferralDiscountFunction = httpsCallable(
    functions,
    "applyReferralDiscount"
  );
  try {
    const result = await applyReferralDiscountFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling applyReferralDiscount function:", error);
    throw error;
  }
};

export const addAddress = async (data) => {
  console.log("data: ", data);
  const functions = getFunctions();
  const addAddressFunction = httpsCallable(functions, "addAddress");
  try {
    const result = await addAddressFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addAddress function:", error);
    throw error;
  }
};

export const calculateVisits = async (
  employees,
  timeZoneId,
  memberGeohash,
  durations = []
) => {
  console.time("-------------------- Execution Time");

  // console.log("memberGeohash: ", memberGeohash);

  // console.log("==> Calculate Visits employees: ", employees);
  // console.log("==> Calculate Visits timeZoneId: ", timeZoneId);

  // employees must have key as id and property "windows"
  // employees also can have a startDate and endDate (luxon) that says when they are available for this time.

  const HOUR_TO_MILLIS = 60 * 60 * 1000;
  const TRAVEL_TIME_MILLIS = 30 * 60 * 1000; // 30 minutes in milliseconds
  const visits = []; // Initialize your visits variable

  // Function to adjust an employee's windows based on their booking constraints
  const adjustWindowsBasedOnBookingConstraints = (
    windows,
    bookingDaysInAdvance,
    isAvailableToday,
    limitDrivingRadiusMiles,
    memberGeohash,
    startDate, // the sequential area window start
    endDate // the sequential area window end
  ) => {
    if (windows && windows.length === 0) {
      // console.log("EMPTY");
      return [];
    }

    const now = moment().tz(timeZoneId);
    const oneHourFromNow = moment().tz(timeZoneId).add(1, "hours");

    // Calculate how many minutes to add to round up to the next quarter-hour
    const minutes = 15 - (oneHourFromNow.minutes() % 15);
    if (minutes !== 15) {
      // This check prevents adding 15 minutes when already at the quarter-hour
      oneHourFromNow.add(minutes, "minutes");
    }

    return windows
      .map((window) => {
        const windowStart = moment(window.start.toMillis()).tz(timeZoneId);
        const windowEnd = moment(window.end.toMillis()).tz(timeZoneId);

        const startGeohash6 = window.startGeohash6 || null;
        const endGeohash6 = window.endGeohash6 || null;

        // Adjust windowStart and windowEnd based on employee's startDate and endDate
        // if (
        //   typeof startDate === "undefined" &&
        //   typeof endDate === "undefined"
        // ) {
        //   // Exit early if both startDate and endDate are strictly undefined
        //   return null;
        // }
        // console.log("limitDrivingRadiusMiles: ", limitDrivingRadiusMiles);

        if (limitDrivingRadiusMiles) {
          if (startGeohash6) {
            const distance = distanceBetweenGeohashes(
              startGeohash6,
              memberGeohash
            );
            // console.log("distance from start: ", distance);
            if (distance > limitDrivingRadiusMiles) {
              return null; // Distance exceeds the limit, return null
            }
          }

          if (endGeohash6) {
            const distance = distanceBetweenGeohashes(
              endGeohash6,
              memberGeohash
            );
            // console.log("distance from end: ", distance);
            if (distance > limitDrivingRadiusMiles) {
              return null; // Distance exceeds the limit, return null
            }
          }
        }

        // Adjust windowStart and windowEnd based on employee's startDate and endDate
        if (startDate) {
          const employeeStart = moment(startDate.toMillis()).tz(timeZoneId);
          if (employeeStart.isAfter(windowEnd)) {
            // The window ends before the employee starts, discard it
            return null;
          } else if (employeeStart.isAfter(windowStart)) {
            // Adjust windowStart to employeeStart
            windowStart = employeeStart.clone();
          }
        }

        if (endDate) {
          const employeeEnd = moment(endDate.toMillis()).tz(timeZoneId);
          if (employeeEnd.isBefore(windowStart)) {
            // The window starts after the employee ends, discard it
            return null;
          } else if (employeeEnd.isBefore(windowEnd)) {
            // Adjust windowEnd to employeeEnd
            windowEnd = employeeEnd.clone();
          }
        }

        // Create a separate variable for the start of the day
        const windowStartDay = windowStart.clone().startOf("day");
        const daysFromToday = windowStartDay.diff(
          now.clone().startOf("day"),
          "days"
        );

        // If 'isAvailableToday' is off and it's today, discard the window.
        if (!isAvailableToday && daysFromToday === 0) {
          return null;
        }

        // If 'isAvailableToday' is on, it's today, and the window is relevant (i.e., hasn't ended yet),
        if (
          isAvailableToday &&
          daysFromToday === 0 &&
          oneHourFromNow.isBefore(windowEnd)
        ) {
          if (oneHourFromNow.isAfter(windowStart)) {
            return {
              ...window,
              start: { toMillis: () => oneHourFromNow.valueOf() },
            };
          } else {
            return window;
          }
        }

        // If the window is before the 'bookingDaysInAdvance', discard it.
        if (daysFromToday < bookingDaysInAdvance) {
          return null;
        }

        // For any other scenarios, the window remains valid and unmodified.
        return window;
      })
      .filter(Boolean); // Filter out the null entries after processing.
  };

  // Preprocess the employees to adjust their windows based on booking constraints.
  const adjustedEmployees = Object.fromEntries(
    Object.entries(employees).map(([id, employee]) => {
      const adjustedWindows = adjustWindowsBasedOnBookingConstraints(
        employee.availableWindows,
        employee.bookingDaysInAdvance,
        employee.isAvailableToday,
        employee.limitDrivingRadiusMiles,
        memberGeohash,
        employee.startDate,
        employee.endDate
      );
      return [id, { ...employee, windows: adjustedWindows }];
    })
  );

  const getEmployeeCombos = (employees) => {
    const employeeIds = Object.keys(employees);
    const totalEmployees = employeeIds.length;

    const generateCombinations = (arr, comboSize) => {
      // generate all combinations of a given size (comboSize) from an input array (arr).
      if (comboSize === 0) return [[]];
      if (arr.length === 0) return [];

      const head = arr[0];
      const withoutHead = generateCombinations(arr.slice(1), comboSize);
      const withHead = generateCombinations(arr.slice(1), comboSize - 1).map(
        (c) => [head, ...c]
      );

      return [...withoutHead, ...withHead];
    };

    const getComboWindows = (combo, employees) => {
      const getIntersectionOfWindows = (windowA, windowB) => {
        const start = Math.max(
          windowA.start.toMillis(),
          windowB.start.toMillis()
        );
        const end = Math.min(windowA.end.toMillis(), windowB.end.toMillis());

        if (start < end) {
          // Determine Geohash6 values to include
          const startGeohash6 =
            windowA.startGeohash6 || windowB.startGeohash6 || null;
          const endGeohash6 =
            windowA.endGeohash6 || windowB.endGeohash6 || null;
          const sequentialVisits =
            windowA.sequentialVisits || windowB.sequentialVisits || null;

          return {
            start: { toMillis: () => start },
            end: { toMillis: () => end },
            ...(startGeohash6 && { startGeohash6 }),
            ...(endGeohash6 && { endGeohash6 }),
            ...(sequentialVisits && { sequentialVisits }),
          };
        } else {
          return null;
        }
      };

      let overlaps = employees[combo[0]].windows;

      for (let i = 1; i < combo.length; i++) {
        const employeeWindows = employees[combo[i]].windows;

        let newOverlaps = [];

        for (let overlap of overlaps) {
          for (let window of employeeWindows) {
            const intersection = getIntersectionOfWindows(overlap, window);
            if (intersection) {
              newOverlaps.push(intersection);
            }
          }
        }

        overlaps = newOverlaps;
      }

      return overlaps;
    };

    const results = {};

    // Main loop to generate combinations and find overlaps
    for (let comboSize = 1; comboSize <= totalEmployees; comboSize++) {
      const combos = generateCombinations(employeeIds, comboSize);

      combos.forEach((combo) => {
        const windows = getComboWindows(combo, employees);
        if (windows && windows.length > 0) {
          // join all employeeId's into a dashed string and set windows as property
          results[combo.join("-")] = { windows };
        }
      });
    }

    return results;
  };

  function calculateFrequencyForVisit(visit, employeeWindows) {
    // Helper function to check if a visit (shifted by 'weeksToAdd') falls within any of the employee's windows

    function fallsWithinFutureWindow(visit, employeeWindows) {
      const frequencies = [1, 2, 3, 4];

      // Calculate the shifted visits (1 to 4 weeks ahead)
      const futureVisits = frequencies.map((weeks) => {
        return {
          start: moment
            .tz(visit.start, "America/Los_Angeles")
            .add(weeks, "weeks")
            .valueOf(),
          end: moment
            .tz(visit.end, "America/Los_Angeles")
            .add(weeks, "weeks")
            .valueOf(),
        };
      });

      const checks = futureVisits.map((futureVisit) => {
        return employeeWindows.some((window) => {
          return (
            futureVisit.start >= window.start.toMillis() &&
            futureVisit.end <= window.end.toMillis()
          );
        });
      });

      return checks;
    }

    const checks = fallsWithinFutureWindow(visit, employeeWindows);

    if (checks.every(Boolean)) {
      return [0, 1, 2, 4];
    }
    if (checks[1] && checks[3]) {
      return [0, 2, 4];
    }
    if (checks[3]) {
      return [0, 4];
    }
    return [0];
  }

  function calculateLongestDurationRounded(employeeWindows) {
    // Extract the durations of all windows for the current employee/combo
    const durations = employeeWindows.map(
      (window) => window.end.toMillis() - window.start.toMillis()
    );

    // Find the longest duration in milliseconds
    const maxDurationMillis = Math.max(...durations);

    // Convert the longest duration to hours
    const longestDuration = maxDurationMillis / HOUR_TO_MILLIS;

    // Round down the duration to get full hours
    const roundedLongestDuration = Math.floor(longestDuration);

    return roundedLongestDuration;
  }

  const employeeCombos = getEmployeeCombos(adjustedEmployees);

  // Loop through each employee (or employee combo)
  Object.keys(employeeCombos).forEach((employeeKey) => {
    const employeeWindows = employeeCombos[employeeKey].windows;
    const roundedLongestDuration =
      calculateLongestDurationRounded(employeeWindows);

    /*
    // Calculate and log available visits for each duration
    employeeWindows.forEach((window) => {
      const endMillis = window.end.toMillis();
      for (
        let durationHours = 1;
        durationHours <= roundedLongestDuration;
        durationHours++
      ) {
        const visitDurationMillis = durationHours * HOUR_TO_MILLIS;
        const totalIntervalMillis = visitDurationMillis + TRAVEL_TIME_MILLIS;

        let startMillis = window.start.toMillis();

        while (startMillis + visitDurationMillis <= endMillis) {
          const frequency = calculateFrequencyForVisit(
            { start: startMillis, end: startMillis + visitDurationMillis },
            employeeWindows
          );
          visits.push({
            employeeIds: employeeKey.split("-"),
            start: startMillis,
            end: startMillis + visitDurationMillis,
            frequency: frequency,
            duration: durationHours, // Added the roundedLongestDuration to the visit object
          });
          startMillis += totalIntervalMillis; // Move to the next possible visit start
        }
      }
    });
    */

    employeeWindows.forEach((window) => {
      const endMillis = window.end.toMillis();
      const startMillisInitial = window.start.toMillis();
      const hasStartGeohash = !!window.startGeohash6;
      const hasEndGeohash = !!window.endGeohash6;

      for (
        let durationHours = 1;
        durationHours <= roundedLongestDuration;
        durationHours++
      ) {
        const visitDurationMillis = durationHours * HOUR_TO_MILLIS;
        const totalIntervalMillis = visitDurationMillis + TRAVEL_TIME_MILLIS;

        if (hasStartGeohash || (!hasStartGeohash && !hasEndGeohash)) {
          // Work forward
          let startMillis = startMillisInitial;
          while (startMillis + visitDurationMillis <= endMillis) {
            const frequency = calculateFrequencyForVisit(
              { start: startMillis, end: startMillis + visitDurationMillis },
              employeeWindows
            );
            visits.push({
              employeeIds: employeeKey.split("-"),
              start: startMillis,
              end: startMillis + visitDurationMillis,
              startDate: DateTime.fromMillis(startMillis, { zone: timeZoneId }),
              endDate: DateTime.fromMillis(startMillis + visitDurationMillis, {
                zone: timeZoneId,
              }),
              frequency: frequency,
              duration: durationHours,
            });

            // Exit the loop if sequentialVisits is true
            if (window.sequentialVisits && (hasStartGeohash || hasEndGeohash))
              break;
            startMillis += totalIntervalMillis; // Move forward
          }
        } else if (hasEndGeohash) {
          // Work backward
          let startMillis = endMillis - visitDurationMillis;
          while (startMillis >= startMillisInitial) {
            const frequency = calculateFrequencyForVisit(
              { start: startMillis, end: startMillis + visitDurationMillis },
              employeeWindows
            );
            visits.push({
              employeeIds: employeeKey.split("-"),
              start: startMillis,
              end: startMillis + visitDurationMillis,
              startDate: DateTime.fromMillis(startMillis, { zone: timeZoneId }),
              endDate: DateTime.fromMillis(startMillis + visitDurationMillis, {
                zone: timeZoneId,
              }),
              frequency: frequency,
              duration: durationHours,
            });

            // Exit the loop if sequentialVisits is true
            if (window.sequentialVisits && (hasStartGeohash || hasEndGeohash))
              break;
            startMillis -= totalIntervalMillis; // Move backward
          }
        }
      }
    });
  });

  // Log the visits
  /*
  console.log("All available visits:");
  visits.forEach((visit) => {
    const startTime = moment(visit.start)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD hh:mm:ss A");
    const endTime = moment(visit.end)
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD hh:mm:ss A");
    console.log(
      `Employees ${visit.employeeIds.join(
        ", "
      )}: Start - ${startTime}, End - ${endTime}, Duration - ${visit.duration}, frequency: ${visit.frequency.join(
        ", "
      )}`
    );
  });
  */

  console.timeEnd("-------------------- Execution Time");

  return visits;
};

export const createVisit = async (data) => {
  const functions = getFunctions();
  const createVisitFunction = httpsCallable(functions, "createVisit");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createVisit function:", error);
    throw error;
  }
};

export const updateVisitEmployees = async (data) => {
  const functions = getFunctions();
  const updateVisitEmployeesFunction = httpsCallable(
    functions,
    "updateVisitEmployees"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await updateVisitEmployeesFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateVisitEmployees function:", error);
    throw error;
  }
};

export const createAuth = async (data) => {
  const functions = getFunctions();
  const createAuthFunction = httpsCallable(functions, "createAuth");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createAuthFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAuth function:", error);
    throw error;
  }
};

export const fetchCards = async (data) => {
  const functions = getFunctions();
  const fetchCardsFunction = httpsCallable(functions, "fetchCards");
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await fetchCardsFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling fetchCards function:", error);
    throw error;
  }
};

export const addCardToCustomer = async (data) => {
  const functions = getFunctions();
  const addCardToCustomerFunction = httpsCallable(
    functions,
    "addCardToCustomer"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await addCardToCustomerFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addCardToCustomer function:", error);
    throw error;
  }
};

export const updateDefaultPaymentMethod = async (data) => {
  const functions = getFunctions();
  const updateDefaultPaymentMethodFunction = httpsCallable(
    functions,
    "updateDefaultPaymentMethod"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await updateDefaultPaymentMethodFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateDefaultPaymentMethod function:", error);
    throw error;
  }
};

export const createSetupIntent = async (data) => {
  const functions = getFunctions();
  const createSetupIntentFunction = httpsCallable(
    functions,
    "createSetupIntent"
  );
  try {
    const result = await createSetupIntentFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createSetupIntent function:", error);
    throw error;
  }
};

export const createPaymentMethod = async (data) => {
  const functions = getFunctions();
  const createPaymentMethodFunction = httpsCallable(
    functions,
    "createPaymentMethod"
  );
  try {
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    const result = await createPaymentMethodFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createPaymentMethod function:", error);
    throw error;
  }
};

export const addMemberRole = async (data) => {
  const functions = getFunctions();
  const addMemberRoleFunction = httpsCallable(functions, "addMemberRole");
  try {
    const result = await addMemberRoleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addMemberRole function:", error);
    throw error;
  }
};

export const rescheduleVisit = async (data) => {
  const functions = getFunctions();
  const rescheduleVisitFunction = httpsCallable(functions, "rescheduleVisit");
  try {
    const result = await rescheduleVisitFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling rescheduleVisit function:", error);
    throw error;
  }
};

export const updateMemberBillingCycle = async (data) => {
  const functions = getFunctions();
  const updateMemberBillingCycleFunction = httpsCallable(
    functions,
    "updateMemberBillingCycle"
  );
  try {
    const result = await updateMemberBillingCycleFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling updateMemberBillingCycle function:", error);
    throw error;
  }
};

export const createAccountDoc = async (data) => {
  const functions = getFunctions();
  const createAccountDocFunction = httpsCallable(functions, "createAccountDoc");
  try {
    const result = await createAccountDocFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling createAccountDoc function:", error);
    throw error;
  }
};

export const addAdminData = async (data) => {
  const functions = getFunctions();
  const addAdminDataFunction = httpsCallable(functions, "addAdminData");
  try {
    const result = await addAdminDataFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling addAdminData function:", error);
    throw error;
  }
};

export const setMembershipPlan = async (data) => {
  const functions = getFunctions();
  const setMembershipPlanFunction = httpsCallable(
    functions,
    "setMembershipPlan"
  );
  try {
    const result = await setMembershipPlanFunction(data);
    return result.data;
  } catch (error) {
    console.error("Error calling setMembershipPlan function:", error);
    throw error;
  }
};

export const getMemberStatus = (employeeData = {}, memberLocation) => {
  if (!employeeData || Object.keys(employeeData).length === 0) {
    return { status: "waitlist" }; // Fallback if employeeData is missing or empty
  }

  const statuses = Object.values(employeeData).map((employee) => {
    const {
      inArea = false,
      futureAvailability,
      end,
      start,
    } = getEmployeeStatus(
      memberLocation?.lat,
      memberLocation?.lng,
      employee.areas,
      employee.availability,
      employee?.totalAvailableHours
    );

    const now = DateTime.now();
    const tenDays = DateTime.now().plus({ days: 10 });

    // If the employee is not necessarily in the area, but has availability set for next week, we count active
    if (employee?.nextAvailableDate && employee.nextAvailableDate < tenDays) {
      return {
        status: "active",
        serviceStart: start || null,
        serviceEnd: end || null,
      };
    }

    // Indefinite future: null is considered an open-ended range
    const isWithinWindow =
      (start === null || now >= start) && (end === null || now <= end);

    if (inArea && isWithinWindow && futureAvailability > 0) {
      return {
        status: "active",
        serviceStart: start || null,
        serviceEnd: end || null,
      };
    }

    if (inArea && isWithinWindow && futureAvailability === 0) {
      return { status: "no_availability" };
    }

    if (inArea && start && now < start) {
      return { status: "paused", serviceStart: start };
    }

    if (start === undefined || end === undefined || futureAvailability === 0) {
      return { status: "suspended" };
    }

    return { status: "unknown" }; // Fallback for unhandled cases
  });

  // Determine furthest out start and end dates, accounting for null (infinity)
  const furthestStart = statuses
    .map(({ serviceStart }) => serviceStart)
    .reduce((furthest, current) => {
      if (current === null) return furthest; // Null is treated as infinite future
      if (furthest === null || current < furthest) return current;
      return furthest;
    }, null);

  const furthestEnd = statuses
    .map(({ serviceEnd }) => serviceEnd)
    .reduce((furthest, current) => {
      // Skip undefined values
      if (current === undefined) return furthest;

      // Treat null as the furthest possible end (infinite future)
      if (current === null) return null;

      if (furthest === null) return current; // If current is finite, replace null furthest
      return current > furthest ? current : furthest;
    }, null);

  // console.log("getMemberStatus - statuses: ", statuses);
  // console.log("getMemberStatus - furthestStart: ", furthestStart);
  // console.log("getMemberStatus - furthestEnd: ", furthestEnd);

  // Check and return the highest precedence status
  const activeStatus = statuses.find(({ status }) => status === "active");
  if (activeStatus) {
    return {
      status: "active",
      serviceStart: furthestStart,
      serviceEnd: furthestEnd,
    };
  }

  if (statuses.some(({ status }) => status === "no_availability")) {
    return { status: "no_availability" };
  }

  // Handle paused: Find the earliest serviceStart
  const pausedData = statuses
    .filter(({ status }) => status === "paused")
    .map(({ serviceStart }) => ({ serviceStart }))
    .filter(({ serviceStart }) => Boolean(serviceStart));

  if (pausedData.length > 0) {
    const earliestPaused = pausedData.reduce((earliest, current) =>
      current.serviceStart < earliest.serviceStart ? current : earliest
    );
    return { status: "paused", serviceStart: earliestPaused.serviceStart };
  }

  if (statuses.some(({ status }) => status === "suspended")) {
    return { status: "suspended" };
  }

  return { status: "waitlist" }; // Fallback if no statuses match
};
