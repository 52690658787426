import { Add, LocationOnOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  DialogContent,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import { getCurrentArea } from "../../services/locationServices";
import EmployeeTimeline from "../timeline/EmployeeTimeline";
import ServiceArea from "./ServiceArea";
import ServiceAreaListItem from "./ServiceAreaListItem";

const ServiceAreasList = ({
  employeeData = {},
  employeeId = "",
  titleVariant = "h5",
}) => {
  const { areas = {} } = employeeData; // Ensure fallback to an empty object

  const { openDrawer } = useDrawer();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleItemClick = (areaKey) => {
    openDrawer(ServiceArea, {
      title: `Edit ${areas[areaKey].city} area`,
      areaKey,
      areas,
      employeeId,
      disableSwipeToClose: true,
    });
  };

  const handleAddServiceArea = () => {
    openDrawer(ServiceArea, {
      title: `Add new area`,
      areas,
      employeeId,
      disableSwipeToClose: true,
    });
  };

  console.log("areas: ", areas);
  console.log("employeeData: ", employeeData);

  console.log("ServiceAreasList - areas: ", areas);

  // Updated active area logic based on service area windows
  let activeAreaKey = "";
  let activeAlertCity = "";
  const areaKeys = Object.keys(areas);
  const allEmpty =
    areaKeys.length > 0 &&
    areaKeys.every(
      (key) =>
        !areas[key].windows ||
        Object.values(areas[key].windows).every((window) => window === 0)
    );
  if (areaKeys.length === 0) {
    activeAlertCity = "";
  } else if (allEmpty) {
    if (areaKeys.length === 1) {
      activeAreaKey = areaKeys[0];
      activeAlertCity = areas[activeAreaKey].city;
    } else {
      activeAreaKey = "none";
      activeAlertCity = null;
    }
  } else {
    const current = getCurrentArea(areas) || {};
    activeAreaKey = current.areaKey || "";
    activeAlertCity = current.areaData?.city || "";
  }

  const handleEditTimeline = () => {
    console.log("Edit timeline");
    openDrawer(EmployeeTimeline, {
      employeeId,
      areas,
      title: "Area Timeline",
    });
  };

  return (
    <DialogContent
      sx={{
        // width: { xs: "unset", sm: "unset" }
        px: { xs: 1, sm: 2 },
        pt: { xs: 2, sm: "unset" },
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            position: "absolute",
            right: "12px",
            top: "12px",
            ml: "auto",
            mr: "auto",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleAddServiceArea}
            sx={{
              width: "100%",
              borderRadius: "10px",
              height: "56px",
              textTransform: "none",
            }}
            startIcon={<Add />}
          >
            New
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant={titleVariant} fontWeight={"700"}>
            Service Areas
          </Typography>
          <Button
            variant="text"
            onClick={handleAddServiceArea}
            sx={{ textTransform: "none" }}
          >
            New
          </Button>
        </Box>
      )}

      <Alert
        onClick={handleEditTimeline}
        severity={activeAlertCity ? "success" : "warning"}
        icon={<LocationOnOutlined />}
        sx={{ mb: 1 }}
        action={
          <Button
            size="small"
            variant="outlined"
            color={activeAlertCity ? "success" : "warning"}
            sx={{ textTransform: "none", borderRadius: "8px" }}
          >
            {activeAlertCity ? "Change" : "Set"}
          </Button>
        }
      >
        {activeAlertCity ? (
          <>
            Currently servicing <strong>{activeAlertCity}</strong>
          </>
        ) : (
          "No area set, booking disabled."
        )}
      </Alert>
      <List
        disablePadding
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        {Object.entries(areas)
          .sort(([keyA], [keyB]) => {
            if (activeAreaKey !== "none") {
              if (keyA === activeAreaKey) return -1;
              if (keyB === activeAreaKey) return 1;
            }
            return 0;
          })
          .map(([key, area]) => {
            console.log("key: ", key);
            return (
              <>
                <ServiceAreaListItem
                  isActive={activeAreaKey !== "none" && key === activeAreaKey}
                  key={key}
                  areaKey={key}
                  area={area}
                  handleItemClick={handleItemClick}
                  employeeData={employeeData}
                />
              </>
            );
          })}
        {Object.keys(areas).length === 0 && (
          <Typography
            align="center"
            sx={{
              p: 4,
              m: 4,
              color: "text.secondary",
              // mx: "auto",
              border: "1px solid rgba(0,0,0,0.23)",
              borderRadius: "15px",
            }}
          >
            Add a service area
          </Typography>
        )}
      </List>
    </DialogContent>
  );
};

export default ServiceAreasList;
