import { Loader } from "@googlemaps/js-api-loader";
import { LocationOn } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
// import mapPng from "../../assets/search-location.png";
import mapPng from "../../assets/location-map.png";
import useTrackVisibility from "../../hooks/useTrackVisibility";
import { useSession } from "../../providers/SessionProvider";
import {
  formatLocationData,
  getActiveEmployeesAtLocation,
} from "../../services/locationServices";
import GetStartedButton from "../buttons/GetStartedButton";

const ServiceLookup = ({ pageName = "", isModal = false }) => {
  const {
    logSessionActivity,
    updateLocationDataFromGoogle,
    setLocalHelpers,
    locationData,
    localHelpers = {},
  } = useSession() || {};

  const [hasTriggered, setHasTriggered] = useState(false);

  const ref = useTrackVisibility(() => {
    if (!hasTriggered && !isModal && Object.keys(localHelpers)?.length > 0) {
      setStatusMessage("Yes");
      // Add the address field
      logSessionActivity(`${Object.keys(localHelpers).length} helpers active.`);

      if (locationData) {
        const formattedAddress = formatLocationData(locationData);
        setAddress(formattedAddress);
      }

      setHasTriggered(true);
    }
  }, `${pageName}/"service lookup"`); // Pass the logMessage here

  const [address, setAddress] = useState("");

  console.log("ServiceLookup - locationData: ", locationData);

  const [statusMessage, setStatusMessage] = useState(""); // "Yes" or "Waitlist"
  // Initialize inputRef with formatted locationData or an empty string
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null); // For storing the autocomplete instance
  const [employees, setEmployees] = useState(null); // Hold employees data from the query

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  // Initialize Google Autocomplete
  const loadAutoComplete = () => {
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      if (inputRef.current) {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        // Event listener when user selects an address
        autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
      }
    });
  };

  // Handle place selection from autocomplete
  const handlePlaceSelect = () => {
    const place = autoCompleteRef?.current?.getPlace() || null;

    let latitude, longitude, formattedAddress;

    // fetch latitude/longitude from google auto complete
    if (place?.geometry) {
      latitude = place.geometry.location.lat();
      longitude = place.geometry.location.lng();
      formattedAddress = place?.formatted_address;

      const addressComponents = {}; // Declare addressComponents here

      place.address_components.forEach((component) => {
        addressComponents[component.types[0]] = component.long_name;
      });

      const line1 = addressComponents.street_number
        ? `${addressComponents.street_number} ${addressComponents.route}`
        : addressComponents.route;
      const line2 = "";
      const city = addressComponents.locality;
      const state = addressComponents.administrative_area_level_1;
      const zipCode = addressComponents.postal_code;

      setAddress(place.formatted_address); // Update address input to selected place
      // Store address components in the location state
      updateLocationDataFromGoogle({
        latitude,
        longitude,
        line1: line1 || "",
        line2: line2 || "",
        city: city || "",
        state: state || "",
        zipCode: zipCode || "",
      });
    }

    // Fetch lag/longitude from session ip data
    else if (locationData) {
      latitude = locationData?.latitude;
      longitude = locationData?.longitude;
      formattedAddress = formatLocationData(locationData);
      setAddress(formattedAddress);
    }

    // Early return if still not populated
    if (!latitude || !longitude) return;

    // Fetch employees using latitude/longitude and set the status message
    getActiveEmployeesAtLocation(latitude, longitude)
      .then((employeesData) => {
        setLocalHelpers(employeesData);
        // If employees are found, set the status message accordingly
        if (Object.keys(employeesData).length > 0) {
          setStatusMessage("Yes");
          // Add the address field
          logSessionActivity(
            `${
              Object.keys(employeesData).length
            } helpers active at ${formattedAddress}`
          );
        } else {
          setStatusMessage("Waitlist");
          // Add the address field
          logSessionActivity(`No helpers active at ${formattedAddress}`);
        }
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
        setStatusMessage("Error");
      });
  };

  // Clear the selected address and latitude/longitude
  const handleClear = () => {
    setAddress("");
    // setLocationData(null);
    setStatusMessage("");
    // setEmployees(null);
  };

  // Load autocomplete when component mounts
  React.useEffect(() => {
    loadAutoComplete();
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        width: { xs: "auto", sm: "100%" },
        maxWidth: 600,
        margin: "auto",
        boxSizing: "border-box",
        mx: { xs: isModal ? 0 : 2, sm: "auto" },
        pt: isModal ? 2 : 7,
        pb: isModal ? 2 : 7,
      }}
    >
      {/* {statusMessage === "Yes" && <ConfettiAnimation />}{" "} */}
      {/* Render Confetti when service is available */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        {!isModal && (
          <Box
            display="flex" // Use flex display
            justifyContent="center" // Center child elements horizontally
            alignItems="flex-start" // Optional: Center child elements vertically
            sx={{ height: "100%" }} // Set height if necessary
          >
            <Box
              component="img"
              src={mapPng}
              alt={"Map-search"}
              sx={{
                height: { xs: "125px", sm: "150px" },
                width: { xs: "125px", sm: "150px" },
                mb: 1,
              }}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography align="left" variant="h3">
            {isModal ? "Set your town" : "Our Locations"}
          </Typography>
          <Typography align="left" variant="body1" color="text.secondary">
            {isModal
              ? "Type your city, zip code, or address and then select from the dropdown."
              : "Type your city, zip code, or address and then select an option from the dropdown to see if we're available in your neighborhood!"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ position: "relative", marginTop: 2 }}>
        <TextField
          color={
            statusMessage === ""
              ? "primary"
              : statusMessage === "Yes"
              ? "success"
              : statusMessage === "Waitlist"
              ? "info"
              : "error"
          }
          inputRef={inputRef}
          value={address}
          sx={{ width: "100%", position: "relative" }}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter your city, zip code, or address..."
          // label="Search location"
          variant="outlined"
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       <LocationOn
          //         color={
          //           statusMessage === ""
          //             ? "default"
          //             : statusMessage === "Yes"
          //             ? "success"
          //             : statusMessage === "Waitlist"
          //             ? "info"
          //             : "error"
          //         }
          //       />
          //     </InputAdornment>
          //   ),
          // }}
        />
        {address && (
          <IconButton
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "white",
            }}
            onClick={handleClear}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      {statusMessage && (
        <Alert
          severity={
            statusMessage === "Yes"
              ? "success"
              : statusMessage === "Waitlist"
              ? "info"
              : "error"
          }
          sx={{ my: 2 }}
        >
          <AlertTitle>
            {statusMessage === "Yes"
              ? "Available"
              : statusMessage === "Waitlist"
              ? "Now hiring "
              : "Error"}
          </AlertTitle>
          {statusMessage === "Yes" ? (
            "There are helpers available in your neighborhood. When you're ready for an extra hand, we're here to help!"
          ) : statusMessage === "Waitlist" ? (
            <Typography variant="body2">
              Coming soon — maybe you can help! If you know an excellent young
              adult in the area, we'd love to hire them part-time. Apply at{" "}
              <span>
                <Link
                  href="https://www.ourlinkedlives.com/hiring"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.ourlinkedlives.com/hiring
                </Link>
              </span>
            </Typography>
          ) : (
            "There was an error fetching the service availability. Please try again later."
          )}
        </Alert>
      )}
      {statusMessage === "Waitlist" && !isModal && (
        // <ContactUs buttonText="Join waitlist" location={location} />
        <GetStartedButton
          color="primary"
          buttonText={"Join waitlist"}
          buttonUrl={"/join-waitlist"}
          isStandalone={true}
          width={"100%"}
        />
      )}
      {statusMessage === "Yes" && !isModal && (
        <GetStartedButton isStandalone={true} width={"100%"} />
      )}
    </Box>
  );
};

export default ServiceLookup;
