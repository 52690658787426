import React, { useEffect } from "react";

import RoleSelection from "../forms/signUp/RoleSelection";
import useLogPageVisit from "../hooks/useLogPageVisit";
import SimplePage from "./SimplePage";

const GeneralSignUp = () => {
  useLogPageVisit("/signup");

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  return (
    <SimplePage>
      <RoleSelection />
    </SimplePage>
  );
};

export default GeneralSignUp;
