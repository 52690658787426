import React, { Suspense, lazy } from "react";
import DrawerContainer from "../../components/drawers/DrawerContainer";
import LoadingUser from "../../components/LoadingUser";
import { ContentProvider } from "../../providers/ContentProvider";
import { DrawerProvider } from "../../providers/DrawerProvider";
import EmployeeProvider from "../../providers/EmployeeProvider";
import { PollsProvider } from "../../providers/PollsProvider";

const EmployeeDashboard = lazy(() => import("./EmployeeDashboard"));

const LazyHelperDashboard = () => {
  return (
    <Suspense fallback={<LoadingUser />}>
      <EmployeeProvider>
        <DrawerProvider>
          <PollsProvider>
            <ContentProvider>
              <DrawerContainer />
              <EmployeeDashboard />
            </ContentProvider>
          </PollsProvider>
        </DrawerProvider>
      </EmployeeProvider>
    </Suspense>
  );
};

export default LazyHelperDashboard;
