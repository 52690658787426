import { ThumbUp } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  ListItemButton,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import { formatTimeRange } from "../../services/dateServices";
import LazyEmployeeCard from "../drawers/LazyEmployeeCard";

const VisitDisplay = ({
  memberId,
  filters,
  selectedDate,
  setSelectedDate,
  selectedVisit,
  employees,
  availableVisits,
  bestMatchEmployee,
  handleTimeSlotSelect,
  timeZoneId,
  isStacked = false,
  userRole = "member",
}) => {
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const [currentVisits, setCurrentVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const { openDrawer = () => {} } = useDrawer() || {};

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isMobile = useMediaQuery("(max-width:600px)");

  // Initial state for slideStyle
  const [slideStyle, setSlideStyle] = useState({
    transform: "translateX(-5%)", // Start off-screen
    opacity: 0,
    transition: "none",
  });

  // Trigger initial slide-in animation
  useLayoutEffect(() => {
    setTimeout(() => {
      setSlideStyle({
        transform: "translateX(0)",
        opacity: 1,
        transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
      });
    }, 100); // Small delay before starting the animation
  }, []); // Empty dependency array to run only once on mount

  console.log("====> availableVisits: ", availableVisits);
  useEffect(() => {
    // Skip the animation if it's the first visit selected (also skips the blank screen)
    if (currentVisits.length === 0) {
      console.log("SETTING CURRENT DATE IMMEDIATELY");
      setCurrentVisits(availableVisits);
      setLoading(false);
      console.log("setLoading false 1");
    }
    if (selectedDate !== currentDate) {
      // Determine the direction of slide
      const slideOutDirection = selectedDate > currentDate ? "left" : "right";

      // Start slide out with fade
      setSlideStyle({
        transform: `translateX(${slideOutDirection === "left" ? "-5%" : "5%"})`,
        opacity: 0,
        transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
      });

      // After slide out, set up new date on the opposite side and then slide in
      setTimeout(() => {
        setCurrentDate(selectedDate);
        console.log("Hello!!");
        setCurrentVisits(availableVisits);
        setLoading(false);
        console.log("setLoading false 2");

        setSlideStyle({
          // This sets the position offscreen to the opposite side, without a transition
          transform: `translateX(${
            slideOutDirection === "left" ? "5%" : "-5%"
          })`,
          opacity: 0,
          transition: "none",
        });

        // Trigger the slide in with fade
        setTimeout(() => {
          setSlideStyle({
            transform: "translateX(0)",
            opacity: 1,

            transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
          });
        }, 75); // Small delay to ensure the offscreen position is set before sliding in
      }, 100); // Match this duration with the transition duration (200)
    }
  }, [selectedDate, availableVisits, currentDate, currentVisits]);

  // Keeps the current visits synced with any changes
  useEffect(() => {
    if (availableVisits.length === 0) {
      console.log("availableVisits: ", availableVisits);
      console.log("Setting selected date to null because no visits available");
      setCurrentVisits(availableVisits);
      return;
    }
    if (selectedDate && availableVisits.length === 0) {
      setSelectedDate(null);
    }
    setTimeout(() => {
      console.log("USE EFFECT ====> availableVisits: ", availableVisits);
      setCurrentVisits(availableVisits);
      setLoading(false);
      console.log("setLoading false 3");
    }, 200); // Match this duration with the transition duration (200)
  }, [availableVisits]);

  function groupByEmployeeIds(visits) {
    return visits.reduce((acc, visit) => {
      const key = visit.employeeIds.sort().join("-");
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(visit);
      return acc;
    }, {});
  }

  const generateVisitKey = (memberId, visit, frequency) => {
    return `${memberId}-${visit.employeeIds.join("-")}-${visit.start}-${
      visit.end
    }-${frequency}-${visit.duration}`;
  };

  console.log("");

  const handleOpenHelper = (employeeId) => {
    openDrawer(LazyEmployeeCard, {
      employeeId,
      memberId,
      userRole,
      title: "Helper details",
      modalType: isMobile ? undefined : "dialog",
      showBookingButton: false,
    });
  };

  return (
    <Box sx={{ overflow: "hidden", mx: isStacked ? -1 : 0 }}>
      <Box sx={{ ...slideStyle }}>
        {employees && Object.keys(employees).length > 0 && (
          <>
            {/* Add this line */}
            {Object.entries(groupByEmployeeIds(currentVisits))
              .sort(([keyA], [keyB]) => {
                if (keyA.includes(bestMatchEmployee)) return -1;
                if (keyB.includes(bestMatchEmployee)) return 1;
                return 0;
              })
              .map(([key, visits]) => {
                const employeeIds = key.split("-");
                const employeeArr = employeeIds.map((id) => employees[id]);

                return (
                  <Card key={key} sx={{ marginBottom: 1 }} variant="outlined">
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          flexWrap: "wrap",
                          width: "fit-content",
                        }}
                      >
                        {employeeArr.map((employee, index) => {
                          const employeeId = employeeIds[index]; // Get the ID of the current employee

                          return (
                            <ListItemButton
                              key={employeeId}
                              onClick={() => handleOpenHelper(employeeId)}
                              sx={{
                                borderRadius: "50px",
                                backgroundColor: "#fafafa",
                                border: "1px solid #F5F5F5",
                                pl: "2px",
                                pr: "16px",
                                py: "2px",
                                display: "flex",
                                gap: 2,
                                width: "fit-content",
                              }}
                            >
                              <Avatar src={employee?.avatarUrl} />
                              <Typography>
                                {employee?.firstName}{" "}
                                {employee?.lastName?.charAt(0)}.
                              </Typography>
                              {employeeId === bestMatchEmployee && (
                                <Tooltip
                                  enterTouchDelay={0}
                                  title="Great match for the tasks you want completed."
                                >
                                  <SvgIcon color="primary">
                                    <ThumbUp />
                                  </SvgIcon>
                                </Tooltip>
                              )}
                            </ListItemButton>
                          );
                        })}
                      </Box>
                      <Box display="flex" flexWrap="wrap" gap={1} marginTop={2}>
                        {visits
                          .sort((a, b) =>
                            moment(a.start).isAfter(moment(b.start)) ? 1 : -1
                          )
                          .map((visit) => {
                            const visitKey = generateVisitKey(
                              memberId,
                              visit,
                              filters.frequency
                            ); // Generate the unique key here

                            const startDate = moment(visit.start).tz(
                              timeZoneId
                            );
                            const endDate = startDate
                              .clone()
                              .add(visit.duration, "hours");

                            return (
                              <Button
                                key={visitKey}
                                disableElevation
                                onClick={() => handleTimeSlotSelect(visit)}
                                variant={
                                  selectedVisit.key === visitKey
                                    ? "contained"
                                    : "outlined"
                                }
                                sx={{
                                  boxShadow: "none",
                                  textTransform: "none",
                                }}
                              >
                                {formatTimeRange(
                                  startDate,
                                  endDate,
                                  timeZoneId
                                )}
                              </Button>
                            );
                          })}
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default VisitDisplay;
