import { AutoGraph, EmojiEvents } from "@mui/icons-material";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import React from "react";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const PointsDialog = ({
  open,
  onClose,
  currentLevel = 1,
  totalMultiplier,
  pointsInCurrentLevel,
  showLevelUp,
  handleOpenLevelUp,
  hourlyRate,
}) => {
  const onClickLevelUp = (event) => {
    handleOpenLevelUp(event);
    onClose();
  };

  return (
    <ResponsiveDialog
      title={`Level overview`}
      width={"450px"}
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      anchor="bottom"
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: "100px",
        }}
      >
        {/* Render the icon and the title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            width: "auto",
            height: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              minWidth: "60px",
              cursor: "pointer",
              overflow: "hidden",
              background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
              boxShadow: `0px 0px 15px 3px hsl(150,100%,30%)`,
            }}
          >
            <AutoGraph sx={{ fontSize: 40, color: "#fff" }} />
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Earn points, level up
          </Typography>
          {showLevelUp && (
            <Button
              disableElevation
              variant="contained"
              color="success"
              sx={{
                width: "100%",
                height: "60px",
                background: "linear-gradient(90deg, #4CAF50 0%, #2196F3 100%)",
                mt: 2,
                mx: "auto",
                position: "relative", // Needed for the overlay
                overflow: "hidden", // Ensures the effect stays within the button boundaries
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "-150%", // Start off the button
                  width: "100%", // Make it wide enough to cover the button
                  height: "100%",
                  background:
                    "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.2) 97%, rgba(255, 255, 255, 0) 100%)", // Fade in/out effect
                  transform: "skewX(-20deg)", // Slight skew to angle the wipe
                  animation: "wipe 2.5s infinite", // Smooth, infinite animation
                },
                "@keyframes wipe": {
                  "0%": { left: "-200%" },
                  "100%": { left: "200%" }, // Move it fully across
                },
              }}
              onClick={onClickLevelUp}
              startIcon={<EmojiEvents />}
            >
              Level up
            </Button>
          )}
          <Typography variant="body1" gutterBottom>
            Complete visits to earn points. Points for visits are
            added at the end of the day. By unlocking badges, you'll increase your points per visit.
            Choose between a 25 cent raise or 50 dollar cash bonus each time you
            level up.
            {/* Increase your multiplier with
            streaks and bonuses. */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#e0e0e0",
            px: 1,
            py: 0.5,
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Level</Typography>
            <Typography sx={{ mr: 1, fontWeight: "bold" }}>
              {currentLevel}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Points </Typography>
            <Typography sx={{ mr: 1 }}>{pointsInCurrentLevel}/1000</Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#e0e0e0",
            px: 1,
            py: 0.5,
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Hourly rate </Typography>
            <Typography sx={{ mr: 1 }}>${hourlyRate.toFixed(2)}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#e0e0e0",
            px: 1,
            py: 0.5,
            borderRadius: "5px",
          }}
        >
          {/*
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Points per visit</Typography>
            <Typography sx={{ mr: 1 }}>1 pt</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Bonus points</Typography>
            <Typography sx={{ mr: 1 }}>+ {(totalMultiplier - 1).toFixed(0)} pts</Typography>
          </Box>
          <Divider sx={{ my: 0.5 }} />
           */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: "4px" }}>Total points per visit</Typography>
            <Typography sx={{ mr: 1, fontWeight: "bold" }}>
              {totalMultiplier.toFixed(0)} pts
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default PointsDialog;
