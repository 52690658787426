import {
  CakeOutlined,
  Call,
  ContentCopy,
  Edit,
  Email,
  EventAvailableOutlined,
  EventOutlined,
  FactCheck,
  LocationOnOutlined,
  MoreVert,
  Straighten,
  Today,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import { useHelpers } from "../../providers/HelpersProvider";
import { useScheduleVisit } from "../../providers/ScheduleVisitProvider";
import { useSession } from "../../providers/SessionProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { calculateAge } from "../../services/dateServices";
import { getEmployeeServiceString } from "../../services/employeeServices";
import { handleCopy } from "../../services/formServices";
import { formatPhoneNumber } from "../../services/stringServices";
import FollowButton from "../buttons/FollowButton";
import InterviewCard from "../interview/InterviewCard";
import ChangeEmployeeStatus from "../manageEmployees/ChangeEmployeeStatus";
import EditProfile from "../profile/EditProfile";

const EmployeeInfo = ({
  employeeData,
  employeePrivateData,
  memberPrivateData = {}, // used for follow tracking, must hold .id
  memberId = "",
  userRole = "member",
  inArea = true, // can be true or false
  futureAvailability = 0, // integer
  serviceStart = undefined, // undefined if not available at all, null treated as indefinite (into past or future), date is a hard start/end date
  serviceEnd = undefined, // undefined if not available at all, null treated as indefinite (into past or future), date is a hard start/end date
  employeeId = "",
  areaData = {},
  onClose = () => {},
  showBookingButton = true,
}) => {
  const { openDrawer = () => {} } = useDrawer() || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const { logSessionActivity = () => {}, updateHelperAnalytics = () => {} } =
    useSession() || {};
  const { openScheduleVisit = () => {} } = useScheduleVisit() || {};

  const {
    phone = "",
    email = "",
    status = "applicant",
    interviewDate = null,
  } = employeePrivateData || {};

  const { city = "" } = areaData || {};

  const { isAvailableToday = false, bookingDaysInAdvance = 2 } =
    employeeData || {};

  const { showSnackbar = () => {} } = useSnackbar() || {};

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (userRole === "member" || userRole === "public") {
      logSessionActivity(
        `Viewed ${employeeData?.firstName} ${employeeData?.lastName}'s helper profile.`
      );

      updateHelperAnalytics({
        helperIds: [employeeId],
        bin: "profileViews",
      });
    }
  }, []);

  // Data structure for menu items
  const menuItems = [
    userRole === "admin"
      ? {
          label: "Edit profile",
          icon: <Edit fontSize="small" />,
          action: () => handleEditProfile(),
        }
      : null,
    {
      label: "Copy phone",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          employeePrivateData?.phone,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Phone number copied!");
      },
    },
    {
      label: "Copy email",
      icon: <ContentCopy fontSize="small" />,
      action: () => {
        handleCopy(
          employeePrivateData?.email,
          (message) => showSnackbar(message, "success"),
          (message) => showSnackbar(message, "error")
        );
        // alert("Email copied!");
      },
    },
    userRole === "admin"
      ? {
          label: "Change status",
          icon: <FactCheck fontSize="small" />,
          action: () => {
            openDrawer(ChangeEmployeeStatus, {
              employeeId,
              employeePrivateData,
              currentStatus: employeeData?.status,
              title: "Change status",
            });
            // alert("Email copied!");
          },
        }
      : null,
  ].filter(Boolean);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleScheduleInterview = () => {
    openDrawer(InterviewCard, {
      userId: employeeId,
      employeePrivateData,
      title: "Schedule interview",
    });
  };

  const handleEditProfile = () => {
    openDrawer(EditProfile, {
      userPublicData: employeeData,
      userPrivateData: employeePrivateData,
      title: "Edit profile",
    });
  };

  const handleBookVisit = () => {
    console.log("booking a visit");
    openScheduleVisit();
    onClose();
  };

  const {
    calculateTotalAvailability = () => {},
    getNextAvailableDate = () => {},
  } = useHelpers() || {};

  const nextAvailableDate = getNextAvailableDate({ employeeId });
  const totalAvailableHours = calculateTotalAvailability({ employeeId });

  const availabilityString = getEmployeeServiceString(
    inArea,
    futureAvailability,
    bookingDaysInAdvance,
    isAvailableToday,
    serviceStart,
    serviceEnd,
    nextAvailableDate,
    totalAvailableHours
  );

  const infoItems = [
    userRole !== "public" && {
      icon: (
        <LocationOnOutlined sx={{ fontSize: 18, color: "text.secondary" }} />
      ),
      text: city,
    },
    employeeData?.created && {
      icon: <EventOutlined sx={{ fontSize: 18, color: "text.secondary" }} />,
      text: `Joined ${DateTime.fromJSDate(
        employeeData.created?.toDate()
      ).toFormat("LLL yyyy")}`,
    },
    {
      icon: <CakeOutlined sx={{ fontSize: 18, color: "text.secondary" }} />,
      text: `${calculateAge(employeeData.birthMonth)}, ${employeeData.gender}`,
    },
    employeeData?.height && {
      icon: (
        <Straighten
          sx={{
            fontSize: 18,
            color: "text.secondary",
            transform: "rotate(90deg)",
          }}
        />
      ),
      text: employeeData.height,
    },
    availabilityString &&
      userRole !== "public" && {
        icon: (
          <EventAvailableOutlined
            sx={{ fontSize: 18, color: "text.secondary" }}
          />
        ),
        text: availabilityString,
      },
  ].filter(Boolean); // Removes any undefined/null items (e.g., height if not available)

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {status === "interview" && !interviewDate && (
        <Button
          variant="outlined"
          onClick={handleScheduleInterview}
          startIcon={<Today />}
          sx={{ textTransform: "none" }}
        >
          Schedule interview
        </Button>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 2,
            width: "100%",
            // border: "1px solid #e0e0e0",
            borderRadius: 1,
            position: "relative"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              width: "100%",
              alignItems: "center",
            }}
          >
            {/* Avatar */}

            <Avatar
              alt={`${employeeData.firstName} ${
                userRole === "member" || userRole === "public"
                  ? employeeData?.lastName?.charAt(0)
                  : employeeData?.lastName
              }`}
              src={employeeData.avatarUrl}
              sx={{ width: 128, height: 128 }}
            />

            {/* Name and info */}
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                <Typography variant="h6" component="div">
                  {employeeData?.firstName ? (
                    `${employeeData.firstName} ${
                      userRole === "member" || userRole === "public"
                        ? `${employeeData?.lastName?.charAt(0)}.`
                        : employeeData?.lastName
                    }`
                  ) : (
                    <Skeleton width="150px" />
                  )}
                </Typography>
                {(userRole === "admin") && (
                  <Chip
                    label={status}
                    variant="outlined"
                    size="small"
                    sx={{ borderRadius: "5px" }}
                  />
                )}
              </Box>

              {infoItems.map((item, index) => {
                const { icon, text } = item || {};
                return (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    {icon}
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: 14,
                        color: "text.secondary",
                      }}
                    >
                      {text}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          {/* Three Dots Menu */}
          {(userRole === "employee" || userRole === "admin") && (
            <IconButton
              onClick={handleMenuOpen}
              sx={{ position: "absolute", top: "-8px", right: "-8px" }}
            >
              <MoreVert />
            </IconButton>
          )}

          {/* Menu dropdown */}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                // elevation: 1,
                sx: { width: "200px" },
              },
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.action();
                  handleMenuClose();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "column-reverse" },
            gap: 1,
            alignItems: { xs: "flex-start", sm: "flex-end" },
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          {/* Follow button */}
          {memberId && (userRole === "admin" || userRole === "member") && (
            <FollowButton
              memberPrivateData={memberPrivateData}
              employeeData={employeeData}
              memberId={memberId}
              employeeId={employeeId}
            />
          )}
          {showBookingButton && userRole === "member" && (
            <Button
              variant="outlined"
              disabled={!nextAvailableDate}
              onClick={handleBookVisit}
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid hsl(190,100%,30%)",
                height: "38px",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "32px",
                  overflow: "hidden",
                  transition: "width 0.3s ease",
                }}
              >
                <Today />
              </div>
              <Typography variant="body1" fontWeight="500" noWrap>
                Book a visit
              </Typography>
            </Button>
          )}
        </Box>
      </Box>

      {/* In service area? */}
      {/* <Box
        sx={{
          backgroundColor: inArea
            ? isAvailableToday
              ? "hsla(120, 38%, 95%, 1)"
              : "#F5F5F5"
            : "hsla(0, 38%, 95%, 1)",
          color: inArea
            ? isAvailableToday
              ? "hsla(120, 25%, 30%, 1)"
              : "unset"
            : "hsla(0, 25%, 30%, 1)",
          p: 2,
          borderRadius: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography variant="body1">
          {getEmployeeServiceString(
            inArea,
            futureAvailability,
            bookingDaysInAdvance,
            isAvailableToday,
            serviceStart,
            serviceEnd
          )}
        </Typography>
      </Box> */}

      {/* Phone number */}
      {(userRole === "employee" || userRole === "admin") && (
        <>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              borderRadius: "15px",
              px: 2,
              height: "56px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Call />
              <Typography variant="body1">
                {formatPhoneNumber(phone)}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                handleCopy(
                  phone,
                  (message) => showSnackbar(message, "success"),
                  (message) => showSnackbar(message, "error")
                );
              }}
            >
              <ContentCopy />
            </IconButton>
          </Box>

          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              borderRadius: "15px",
              px: 2,
              height: "56px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Email />
              <Typography variant="body1">{email}</Typography>
            </Box>
            <IconButton
              onClick={() => {
                handleCopy(
                  email,
                  (message) => showSnackbar(message, "success"),
                  (message) => showSnackbar(message, "error")
                );
              }}
            >
              <ContentCopy />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EmployeeInfo;
