import { Close, LocationOn } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSession } from "../../providers/SessionProvider";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import ServiceLookup from "./ServiceLookup";

const SetLocationButton = () => {
  const [open, setOpen] = useState(false);
  const { locationData = {}, logSessionActivity = () => {} } = useSession();

  const getChipLabel = () => {
    if (!locationData) return "Set Service Location";
    const { city, state, region_code } = locationData;
    const stateDisplay =
      convertStateNameToAbbreviation(state) || region_code || "";
    return `${city}, ${stateDisplay}`;
  };

  const handleOpen = () => {
    logSessionActivity(`Opened the "Set your town" modal.`);
    setOpen(true);
  };

  const handleClose = () => {
    logSessionActivity(`Opened the "Set your town" modal.`);
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Button
          variant="outlined"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            p: "10px",
            flexWrap: "wrap",
            gap: { xs: 1, sm: 2 },
            textTransform: "none",
            borderRadius: "10px",
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: 0.5,
            }}
          >
            <LocationOn
              sx={{ fontSize: "24px !important", color: "primary.main" }}
            />
            <Typography
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                // fontWeight: "700 !important",
                fontSize: "20px !important",
                opacity: "1 !important",
                color: "primary.main",
              }}
            >
              {locationData && (locationData?.state ? "in " : "near ")}
              <strong>{getChipLabel()}</strong>
            </Typography>
          </Box>
          {locationData && !locationData?.state && (
            <Typography
              sx={{
                fontSize: "14px !important",
                fontWeight: "700 !important",
                px: 2,
                py: 1,
                borderRadius: "5px",
                width: { xs: "100%", sm: "auto" },
                color: "white",
                textTransform: "none",
                backgroundColor: "hsla(190,100%,30%, 1)",
              }}
            >
              Set your town
            </Typography>
          )}
        </Button>
      </Box>

      <ResponsiveDialog
        open={open}
        onClose={handleClose}
        title={`Helper details`}
        width="600px"
        fullHeight={"90dvh"}
        showDefaultHeader={false}
      >
        {open && (
          <>
            <IconButton
              onClick={handleClose}
              sx={{ mr: "auto", mt: 1, ml: 1 }}
            >
              <Close />
            </IconButton>
            <DialogContent sx={{ minHeight: "400px" }}>
              <ServiceLookup isModal={true} />
            </DialogContent>
            <DialogActions>
              <Button
                color="allBlack"
                onClick={handleClose}
                variant="text"
                sx={{ textTransform: "none", height: "56px", width: "100%" }}
              >
                Close window
              </Button>
            </DialogActions>
          </>
        )}
      </ResponsiveDialog>
    </>
  );
};

export default SetLocationButton;
