// components/EditProfile.jsx
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormLabel,
  TextField,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { useAuth } from "../../providers/AuthProvider";
import AvatarFieldV2 from "../fields/AvatarFieldV2";
import PhoneField from "../fields/PhoneField";
// import BannerField from "../photos/BannerField";

/**
 * - This version only uploads the avatar if the user finalizes the entire form (handleSave).
 * - If user cancels, the original avatar remains unchanged in Firestore/Firebase.
 */
const EditProfile = ({ userPublicData, userPrivateData, onClose }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const userId = userPublicData?.id || "";
  const [errors, setErrors] = useState({});

  console.log("EditProfile - userPrivateData: ", userPrivateData);

  const { setup: { merch: { deliveredAt = false } = {} } = {} } =
    userPrivateData || {};

  console.log("deliveredAt: ", deliveredAt);

  // Single state for all fields
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    phone: "",
    avatarUrl: "",
    bannerUrl: "",
    personalInterests: "",
    communityInvolvement: "",
    missionAlignment: "",
  });

  const [height, setHeight] = useState("");

  // We'll store the new avatar blob (if user selects/crops) here
  const [newAvatarBlob, setNewAvatarBlob] = useState(null);
  const [newBannerBlob, setNewBannerBlob] = useState(null);

  const formatHeight = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleaned.length === 0) return ""; // Allow full deletion

    let feet = cleaned.charAt(0); // First digit is always feet
    let inches = cleaned.slice(1, 3); // Up to 2 digits for inches

    let formatted = feet + "'"; // Always show the feet part

    if (inches.length === 1) {
      formatted += inches + '"'; // If there's one inch digit, add `"`
    } else if (inches.length === 2) {
      formatted += inches + '"'; // If there are two digits, still add `"`
    }

    return formatted;
  };

  const handleHeightChange = (e) => {
    const rawValue = e.target.value;

    // Allow full deletion (empty input)
    if (rawValue === "") {
      setHeight("");
      return;
    }

    // Remove non-numeric characters except single/double quote
    let numericValue = rawValue.replace(/[^0-9]/g, "");

    // Handle manual backspacing (avoid getting stuck)
    if (rawValue.length < height.length) {
      setHeight(rawValue); // Allow natural deletion
      return;
    }

    setHeight(formatHeight(numericValue));
  };

  // Initialize form values on mount
  useEffect(() => {
    if (!userPublicData) return;
    setValues((prev) => ({
      ...prev,
      firstName: userPublicData.firstName || "",
      lastName: userPublicData.lastName || "",
      bio: userPublicData.bio || "",
      website: userPublicData.website || "",
      avatarUrl: userPublicData.avatarUrl || "",
      bannerUrl: userPublicData.bannerUrl || "",
      // projects: userPublicData.projects || [],
      personalInterests: userPublicData.personalInterests,
      communityInvolvement: userPublicData.communityInvolvement,
      missionAlignment: userPublicData.missionAlignment,
    }));
    setHeight(userPublicData?.height || "");
  }, [userPublicData]);

  useEffect(() => {
    if (!userPrivateData) return;
    setValues((prev) => ({
      ...prev,
      phone: userPrivateData.phone || "",
    }));
  }, [userPrivateData]);

  // Handle text field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  // callback from AvatarUpload: user has selected/cropped a new avatar
  const handleAvatarChange = (blob, previewUrl) => {
    // We show the new preview right away
    setValues((prev) => ({ ...prev, avatarUrl: previewUrl }));
    // Keep the actual blob for final upload
    setNewAvatarBlob(blob);
  };

  // callback from BannerUpload: user has selected/cropped a new banner
  const handleBannerChange = (blob, previewUrl) => {
    console.log("previewUrl callback: ", previewUrl);
    // We show the new preview right away
    setValues((prev) => ({ ...prev, bannerUrl: previewUrl }));
    // Keep the actual blob for final upload
    setNewBannerBlob(blob);
  };

  const validatePhone = (values) => {
    let errors = {};

    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      errors.phone = "Your phone number must be 10 digits.";
    return errors;
  };

  const handleSave = async () => {
    setLoading(true);

    let newError = validatePhone(values);
    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      setLoading(false); // Ensure to stop the loading process here if there are validation errors
      return; // Exit the function if there are errors
    }

    const sanitizedPhone = values.phone.replace(/\D/g, "");

    try {
      let avatarUrl = values.avatarUrl; // Pre-existing avatar URL
      let bannerUrl = values.bannerUrl; // Pre-existing banner URL

      console.log("Initial bannerUrl:", bannerUrl);

      if ((newAvatarBlob || newBannerBlob) && currentUser) {
        const uploadPromises = [];
        const uploadTypes = []; // To keep track of which file is being uploaded

        // Function to handle file upload
        const uploadFile = async ({
          blob,
          path,
          maxSizeMB = 0.15,
          maxWidthOrHeight = 600,
        }) => {
          const options = { maxSizeMB, maxWidthOrHeight, useWebWorker: true };
          const compressedFile = await imageCompression(blob, options);
          const metadata = { contentType: compressedFile.type };
          const fileRef = ref(storage, path);
          const uploadTask = uploadBytesResumable(
            fileRef,
            compressedFile,
            metadata
          );

          return new Promise((resolve, reject) => {
            uploadTask.on("state_changed", null, reject, async () => {
              try {
                const downloadUrl = await getDownloadURL(
                  uploadTask.snapshot.ref
                );
                resolve(downloadUrl);
              } catch (err) {
                console.error("Failed to get download URL:", err);
                reject(err);
              }
            });
          });
        };

        // Add avatar upload if necessary
        if (newAvatarBlob) {
          uploadPromises.push(
            uploadFile({ blob: newAvatarBlob, path: `avatars/${userId}` })
          );
          uploadTypes.push("avatar"); // Track that this is an avatar upload
        }

        // Add banner upload if necessary
        if (newBannerBlob) {
          uploadPromises.push(
            uploadFile({
              blob: newBannerBlob,
              path: `banners/${userId}`,
              maxSizeMB: 0.4,
              maxWidthOrHeight: 1600,
            })
          );
          uploadTypes.push("banner"); // Track that this is a banner upload
        }

        // Execute the uploads and map results to the correct fields
        const uploadResults = await Promise.all(uploadPromises);

        uploadResults.forEach((result, index) => {
          if (uploadTypes[index] === "avatar") {
            avatarUrl = result; // Update avatar URL if uploaded
          } else if (uploadTypes[index] === "banner") {
            bannerUrl = result; // Update banner URL if uploaded
          }
        });

        console.log("Final avatarUrl:", avatarUrl);
        console.log("Final bannerUrl:", bannerUrl);

        // Update Firebase Auth user profile if avatarUrl exists
        if (avatarUrl) {
          await updateProfile(currentUser, { photoURL: avatarUrl });
        }
      }

      console.log("Before Firestore update, bannerUrl:", bannerUrl);

      // Update Firestore with all fields
      const userPublicUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        bio: values.bio,
        avatarUrl, // Use updated avatarUrl if uploaded, fallback to original
        bannerUrl, // Use updated bannerUrl if uploaded, fallback to original
        personalInterests: values?.personalInterests || "",
        communityInvolvement: values?.communityInvolvement || "",
        missionAlignment: values?.missionAlignment || "",
        height,
      };

      const userPrivateUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: sanitizedPhone,
        personalInterests: values?.personalInterests || "",
        communityInvolvement: values?.communityInvolvement || "",
        missionAlignment: values?.missionAlignment || "",
        height,
      };

      if (deliveredAt) {
        userPrivateUpdate.setup = { avatarUpdated: true };
      }

      const userDocRef = doc(db, "employees", userId);
      const userPrivateDocRef = doc(db, "employeesPrivate", userId);

      await setDoc(userDocRef, userPublicUpdate, { merge: true });
      await setDoc(userPrivateDocRef, userPrivateUpdate, { merge: true });

      onClose(); // Close the dialog
    } catch (err) {
      console.error("Failed to save profile:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectChange = (index, field, value) => {
    setValues((prev) => {
      const updatedProjects = [...prev.projects];
      updatedProjects[index] = {
        ...updatedProjects[index],
        [field]: value,
      };
      return { ...prev, projects: updatedProjects };
    });
  };

  const addProject = () => {
    setValues((prev) => ({
      ...prev,
      projects: [...prev.projects, { title: "", description: "" }],
    }));
  };

  const removeProject = (index) => {
    setValues((prev) => {
      const updatedProjects = [...prev.projects];
      updatedProjects.splice(index, 1);
      return { ...prev, projects: updatedProjects };
    });
  };

  return (
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {/* Banner: Example if you want to do immediate upload */}
        {/* <Card
          variant="soft"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
            p: 0,
            boxSizing: "border-box",
            aspectRatio: "3 / 1", // Set the aspect ratio to 3:1
            width: "100%", // Ensure it scales with its parent
          }}
        >
          <BannerField
            bannerUrl={values.bannerUrl}
            onBannerChange={handleBannerChange}
          />
        </Card> */}

        {/* Avatar: Now no immediate upload. Just pick/crop => store new blob. */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 2,
            // mt: { xs: 0, sm: -9 },
            ml: { xs: 0, sm: 3 },
          }}
        >
          <AvatarFieldV2
            avatarUrl={values.avatarUrl}
            onAvatarChange={handleAvatarChange} // only a callback
          />
        </Box>

        {/* Other text fields */}
        <FormLabel>First Name</FormLabel>
        <TextField
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />

        <FormLabel>Last Name</FormLabel>
        <TextField
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />

        <FormLabel>Height</FormLabel>
        <TextField
          name="height"
          type="tel"
          value={height}
          onChange={handleHeightChange}
          fullWidth
          sx={{ mb: 2 }}
        />

        <FormLabel>Bio</FormLabel>
        <TextField
          name="bio"
          value={values.bio}
          onChange={handleChange}
          minRows={3}
          multiline
          sx={{ mb: 2 }}
        />

        <FormLabel>Phone</FormLabel>
        <PhoneField
          label={null}
          phone={values.phone}
          error={errors.phone}
          helperText={"Your phone number is kept private."}
          handleChange={handleChange}
        />
        <div style={{ height: "16px" }} />

        <FormLabel>Personal Interests</FormLabel>
        <TextField
          name="personalInterests"
          value={values.personalInterests}
          onChange={handleChange}
          minRows={3}
          multiline
          sx={{ mb: 2 }}
        />

        <FormLabel>Community Involvement</FormLabel>
        <TextField
          name="communityInvolvement"
          value={values.communityInvolvement}
          onChange={handleChange}
          minRows={3}
          multiline
          sx={{ mb: 2 }}
        />

        <FormLabel>Mission Alignment</FormLabel>
        <TextField
          name="missionAlignment"
          value={values.missionAlignment}
          onChange={handleChange}
          minRows={3}
          multiline
          sx={{ mb: 2 }}
        />
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            width: "100%",
          }}
        >
          <Button
            disabled={loading}
            variant="outlined"
            onClick={onClose}
            sx={{ width: "100%", height: "48px", textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSave}
            sx={{ width: "100%", height: "48px", textTransform: "none" }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default EditProfile;
