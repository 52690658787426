import React, { Suspense } from "react";

const VisitCardV2 = React.lazy(() => import("../visit/VisitCardV2"));

const LazyVisitCard = ({ visitId, userRole, onClose }) => {
  return (
    <>
      <Suspense fallback={<div>Loading Visit Card...</div>}>
        <VisitCardV2 visitId={visitId} userRole={userRole} onClose={onClose} />
      </Suspense>
    </>
  );
};

export default LazyVisitCard;
