// src/providers/PollsProvider.js
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";

const PollsContext = createContext();

export const PollsProvider = ({ children }) => {
  const [pollsData, setPollsData] = useState([]);

  useEffect(() => {
    // Calculate the date three months ago
    const threeMonthsAgo = DateTime.now().minus({ months: 3 }).toJSDate();
    const pollsRef = collection(db, "polls");
    const q = query(pollsRef, where("createdAt", ">=", threeMonthsAgo));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const polls = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPollsData(polls);
    });

    return () => unsubscribe();
  }, []);

  return (
    <PollsContext.Provider value={{ pollsData }}>
      {children}
    </PollsContext.Provider>
  );
};

export const usePolls = () => useContext(PollsContext);
