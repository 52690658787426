import {
  Avatar,
  AvatarGroup,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { db } from "../../firebase";
import { getCurrentArea } from "../../services/locationServices";

const MAX_AVATARS = 6;

// Predefined fallback avatars
const avatarUrls = [
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b",
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02",
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FafZCNQGDLLhQl4wNwG42gs5eXcm2?alt=media&token=29b8936f-99a8-4785-9057-a016c69aeec1",
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FU3uallG7AiUYqtD7ox5ZTUXw3Gb2?alt=media&token=13aa4490-a701-467b-9b73-a500b7914f47",
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FTtluJmUq1CgE9UM1c7L4HEPGSIk1?alt=media&token=54ac4637-2d70-439a-919a-c631de476cf5",
  "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=0a1886c1-3c0f-41b7-b332-0195982cd7af",
];

const AdGroup = ({ helperData, onLoaded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const AVATAR_SIZE = 108;

  const [helperAvatar, setHelperAvatar] = React.useState(null);
  const [otherHelpers, setOtherHelpers] = React.useState([]);

  React.useEffect(() => {
    const fetchHelpers = async () => {
      if (!helperData) return;
      try {
        let helpers = new Set(); // Ensure uniqueness

        // Use provided helperData for main helper
        const mainHelperAvatar = helperData.avatarUrl;
        setHelperAvatar(mainHelperAvatar);
        helpers.add(mainHelperAvatar);

        const currentArea = getCurrentArea(helperData?.areas);
        const { areaData = {} } = currentArea || {};
        const helperGeohash5 = areaData.geohash6.substring(0, 5);

        // Fetch local active helpers (same geohash5)
        const localQuery = query(
          collection(db, "employees"),
          where("status", "==", "active"),
          where("geohash5Arr", "array-contains", helperGeohash5),
          limit(MAX_AVATARS - 1)
        );
        const localSnapshot = await getDocs(localQuery);
        localSnapshot.forEach((docSnap) => {
          const avatarUrl = docSnap.data().avatarUrl;
          if (avatarUrl !== mainHelperAvatar) helpers.add(avatarUrl);
        });

        // Fetch additional stand-ins if needed
        if (helpers.size < MAX_AVATARS) {
          const remainingCount = MAX_AVATARS - helpers.size;
          const standInQuery = query(
            collection(db, "employees"),
            where("status", "==", "active"),
            orderBy("admin.lastActive", "desc"),
            limit(remainingCount)
          );
          const standInSnapshot = await getDocs(standInQuery);
          standInSnapshot.forEach((docSnap) => {
            helpers.add(docSnap.data().avatarUrl);
          });
        }

        // Fill with fallback avatars if still not enough
        if (helpers.size < MAX_AVATARS) {
          const shuffledFallbacks = [...avatarUrls].sort(
            () => 0.5 - Math.random()
          );
          while (helpers.size < MAX_AVATARS && shuffledFallbacks.length > 0) {
            helpers.add(shuffledFallbacks.pop());
          }
        }

        setOtherHelpers(
          Array.from(helpers)
            .filter((url) => url !== mainHelperAvatar)
            .slice(0, MAX_AVATARS - 1)
        );
      } catch (error) {
        console.error("Error fetching helpers:", error);
      } finally {
        if (onLoaded) onLoaded();
      }
    };

    fetchHelpers();
  }, [helperData]);

  return (
    <Box
      sx={{
        width: "540px",
        height: "540px",
        // border: "1px solid",
        boxSizing: "border-box",
        margin: "auto",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AvatarGroup
          spacing={34}
          max={MAX_AVATARS}
          sx={{
            justifyContent: "center",
            "& .MuiAvatar-root": {
              border: "6px solid white",
            },
          }}
        >
          {helperAvatar && (
            <Avatar
              alt="Main Helper"
              src={helperAvatar}
              sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
            />
          )}
          {otherHelpers.slice(0, MAX_AVATARS - 1).map((url, index) => (
            <Avatar
              key={index}
              alt={`Helper ${index + 2}`}
              src={url}
              sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
            />
          ))}
        </AvatarGroup>
      </Box>
      <Typography
        zIndex={30}
        position="relative"
        variant="h2"
        sx={{
          mb: 1,
          mt: 1,
          maxWidth: "500px",
          lineHeight: "64px",
          fontSize: "60px",
        }}
        align="center"
      >
        Household Help for Seniors
      </Typography>
      <Typography
        zIndex={30}
        position="relative"
        variant="caption"
        sx={{ mb: 1, opacity: 0.7, fontSize: 18, mt: 4 }}
        align="center"
      >
        Family Owned • BBB Accredited • Trusted & Reliable
      </Typography>
    </Box>
  );
};

export default AdGroup;
