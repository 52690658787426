import { DateTime } from "luxon";

export const getApplicationCardOrder = (
  employeePrivateData,
  employeeData,
  includeOptional = true
) =>
  [
    {
      name: "PHONE",
      title: "Phone details",
      complete: () => !!employeePrivateData?.phone,
    },
    {
      name: "ADDRESS",
      title: "Address details",
      complete: () =>
        !!(
          employeePrivateData?.address?.line1 &&
          employeePrivateData?.address?.city &&
          employeePrivateData?.address?.state &&
          employeePrivateData?.address?.zipCode &&
          employeePrivateData?.address?.geohash
        ),
    },
    {
      name: "AREA",
      title: "Service area",
      complete: () => !!employeeData?.areas,
      isOptional: true,
    },
    {
      name: "TASKS",
      title: "Task details",
      complete: () => !!(employeePrivateData?.tasks?.length > 0),
      isOptional: true,
    },
    {
      name: "ABOUT",
      title: "About me",
      complete: () => !!employeeData?.bio,
      isOptional: true,
    },
    {
      name: "IDENTITY",
      title: "Finish signing up",
      complete: () =>
        !!(
          // employeePrivateData?.firstName &&
          // employeePrivateData?.lastName &&
          // employeePrivateData?.birthday &&
          (employeePrivateData?.gender && employeePrivateData?.tshirtSize)
        ),
    },
    {
      name: "AVATAR",
      title: "Profile picture",
      complete: () => !!employeePrivateData?.avatarUrl,
      isOptional: true,
    },
    {
      name: "PERSONAL_INTERESTS",
      title: "Personal interests",
      complete: () => !!employeePrivateData?.personalInterests,
    },
    {
      name: "COMMUNITY_INVOLVEMENT",
      title: "Community involvement",
      complete: () => !!employeePrivateData?.communityInvolvement,
    },
    {
      name: "MISSION_ALIGNMENT",
      title: "Mission alignment",
      complete: () => !!employeePrivateData?.missionAlignment,
    },
    {
      name: "HEARD_ABOUT_US",
      title: "How did you hear about us?",
      complete: () => !!employeePrivateData?.heardAboutUs,
      isOptional: true,
    },
    {
      name: "REFERENCES",
      title: "References?",
      complete: () => !!(employeePrivateData?.references?.length > 0),
      isOptional: true,
    },
    {
      name: "TOS",
      title: "Terms of service",
      complete: () => !!employeePrivateData?.tosAcceptance,
      isOptional: false,
    },
    {
      name: "PARENTAL_CONSENT",
      title: "Parental consent",
      complete: () => {
        if (!employeePrivateData?.birthday) {
          return false;
        }

        // Parse the birthday to calculate age
        const birthday = DateTime.fromISO(employeePrivateData.birthday); // Assumes `birthday` is in `yyyy-MM-dd` format
        const now = DateTime.now();
        const age = now.diff(birthday, "years").years;

        // Complete if 18 or older or consent has been given.
        return (
          age >= 18 ||
          !!employeePrivateData?.parentalConsent?.consentAcknowledged
        );
      },
    },
  ].filter((step) => includeOptional || !step.isOptional);

  export const getProfileCardOrder = (
    memberPrivateData,
    memberData,
    includeOptional = true
  ) =>
    [

      {
        name: "ABOUT",
        title: "About me",
        complete: () => !!memberData?.bio,
        isOptional: true,
      },
      {
        name: "REFERRAL",
        title: "Referral source",
        complete: () => !!memberPrivateData?.adSources,
        isOptional: true,
      },
      {
        name: "AVATAR",
        title: "Profile picture",
        complete: () => !!memberPrivateData?.avatarUrl,
        isOptional: true,
      },
    ].filter((step) => includeOptional || !step.isOptional);

export const validatePhone = (values) => {
  let errors = {};

  if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
    errors.phone = "Your phone number must be 10 digits.";
  return errors;
};

export const validateEmail = (values) => {
  let errors = {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!values.email) {
    errors.email = "Email address is required.";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Enter a valid email address.";
  }

  return errors;
};

export const handleCopy = (data, onSuccess, onError) => {
  navigator.clipboard
    .writeText(data)
    .then(() => {
      console.log("Copy success");
      if (onSuccess) onSuccess("Copied to clipboard.");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
      if (onError) onError("Failed to copy to clipboard.");
    });
};
