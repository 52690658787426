import {
  Box,
  ListItemAvatar,
  ListItemButton,
  Rating,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import moment from "moment";
import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import AvatarStatusBadge from "../AvatarStatusBadge";
import ColorAvatar from "../ColorAvatar";

// Helper function to remove sensitive information
const removeSensitiveInfo = (text) => {
  if (!text) return "";

  const phoneRegex = /(\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/g;
  text = text.replace(phoneRegex, "");

  const addressRegex =
    /\b\d+\s+\w+\s+(Street|St|Avenue|Ave|Boulevard|Blvd|Road|Rd|Lane|Ln|Drive|Dr)\b/i;
  text = text.replace(addressRegex, "");

  return text;
};

function formatTimeDifference(startTimestamp, endTimestamp) {
  const startTime = moment(startTimestamp.toDate());
  const endTime = moment(endTimestamp.toDate());

  const duration = moment.duration(endTime.diff(startTime));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let result = "";

  if (hours > 0) {
    result += `${hours} hour`;
    if (minutes > 0) {
      result += ` ${minutes} minute`;
    }
  } else {
    result = `${minutes} minute`;
  }

  return `${result} visit`;
}

const ReviewCard = ({ visit, onClick, STAR_MIN = 5, fontScale = 1 }) => {
  const { currentUser } = useAuth();

  // If it's the current user's visit
  const selfVisit = visit.member === currentUser?.uid;

  return (
    <Box
      sx={{
        my: 2,
        mx: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
        <ColorAvatar avatarUrl={visit.avatarUrl} name={visit.displayName} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="600"
            sx={{ fontSize: 16 * fontScale }}
          >
            {visit.displayName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: 14 * fontScale }}
          >
            {DateTime.fromSeconds(visit.start.seconds).toRelative()} •{" "}
            {formatTimeDifference(visit.start, visit.end)}
          </Typography>
        </Box>
      </Box>

      {/* Capped Description */}
      <Box
        sx={{
          my: 1,
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
        }}
      >
        <Typography sx={{ boxSizing: "border-box", fontSize: 16 * fontScale }}>
          {removeSensitiveInfo(visit.description)}
        </Typography>
      </Box>

      {visit.rating && (visit.rating >= STAR_MIN || selfVisit) && (
        <Box
          sx={{ backgroundColor: "#F5F5F5", p: 2, borderRadius: "15px", my: 1 }}
        >
          <Rating
            value={visit.rating}
            precision={0.1}
            readOnly
            sx={{ fontSize: 20 * fontScale }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: 16 * fontScale,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 6,
            }}
          >
            {removeSensitiveInfo(visit.review)}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          justifyContent: "flex-end",
        }}
      >
        {Object.entries(visit.employees)
          .filter(([, employee]) => employee.responseStatus === "accepted")
          .map(([key, employee], index) => (
            <Box display="flex" justifyContent="flex-end" key={key}>
              <ListItemButton
                onClick={() => onClick(key)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "15px",
                  p: 1,
                }}
              >
                <ListItemAvatar>
                  <AvatarStatusBadge
                    status={employee.responseStatus}
                    avatarUrl={employee.avatarUrl}
                    displayName={employee.firstName}
                    index={index}
                  />
                </ListItemAvatar>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 16 * fontScale, lineHeight: 1 }}
                  >
                    {employee.displayName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: 14 * fontScale }}
                  >
                    completed
                  </Typography>
                </Box>
              </ListItemButton>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ReviewCard;
