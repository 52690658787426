// components/CropImage.jsx
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = () => resolve(image);
    image.onerror = (err) => reject(err);
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
}

function CropImage({
  file,
  cropShape = "round",
  aspect = 1,
  onCropComplete,
  onClose,
  photoGuidelines = "Crop in to show mainly your face and shoulders. This is actual size.",
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Immediately after existing state declarations
  const [previewUrl, setPreviewUrl] = useState(null);

  // Add the following useEffect below the file-loading useEffect
  useEffect(() => {
    let objectUrl;
    if (imageSrc && croppedAreaPixels) {
      getCroppedImg(imageSrc, croppedAreaPixels).then((blob) => {
        objectUrl = URL.createObjectURL(blob);
        setPreviewUrl(objectUrl);
      });
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [imageSrc, croppedAreaPixels]);

  // Load the file as dataURL
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoomValue) => {
    setZoom(zoomValue);
  }, []);

  const onCropCompleteInternal = useCallback((_, croppedAreaPx) => {
    setCroppedAreaPixels(croppedAreaPx);
  }, []);

  const handleSave = useCallback(async () => {
    if (!imageSrc || !croppedAreaPixels) return;
    const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

    // For local preview: Convert the cropped blob to a dataURL
    const croppedPreviewUrl = URL.createObjectURL(croppedBlob);

    // Return both the blob and a local preview
    onCropComplete(croppedBlob, croppedPreviewUrl);
    onClose();
  }, [imageSrc, croppedAreaPixels, onCropComplete]);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            position: "relative",
            // width: "100%",
            height: 300,
            background: "#333",
            boxSizing: "border-box",
            mb: 2,
            mx: { xs: -3, sm: -3 },
            mt: { xs: "-20px", sm: "-20px" },
          }}
        >
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect} // 1:1 for avatar
              cropShape={cropShape}
              showGrid={false}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropCompleteInternal}
            />
          )}
        </Box>

        {/* Zoom Slider */}
        <Box
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
            maxWidth: "200px",
            mx: "auto"
          }}
        >
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(_, val) => onZoomChange(val)}
          />
        </Box>

        {photoGuidelines && (
          <Box
            sx={{
              mb: 2,
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              flexDirection: "row",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              p: 2,
            }}
          >
            <Box
              component="img"
              src={previewUrl || null}
              alt="Cropped preview"
              sx={{
                width: 48,
                height: 48,
                borderRadius: cropShape === "round" ? "50%" : "0",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle2">Guidelines</Typography>
              <Typography variant="body2">{photoGuidelines}</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column-reverse", sm: "row" },
            width: "100%",
            gap: 1,
          }}
        >
          {/* <Button
            variant="text"
            disableElevation
            onClick={onClose}
            sx={{
              width: { xs: "100%", sm: "auto" },
              height: { xs: "48px", sm: "auto" },
              textTransform: "none",
            }}
          >
            Cancel
          </Button> */}
          <Button
            variant="contained"
            disableElevation
            onClick={handleSave}
            sx={{
              width: { xs: "100%", sm: "200px" },
              height: { xs: "48px", sm: "48px" },
              textTransform: "none",
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

export default CropImage;
