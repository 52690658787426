import {
  ArrowForwardIosRounded,
  CallEnd,
  Chat,
  Mic,
  MicOff,
  Pause,
  Phone,
  VolumeUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardContent,
  Fab,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useDrawer } from "../../providers/DrawerProvider";
import { useTwilio } from "../../providers/TwilioProvider";
import { formatPhoneNumber } from "../../services/stringServices";
import ColorAvatar from "../ColorAvatar";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import LiveCallAnimation from "./LiveCallAnimation";

const drawerWidth = 300;

const CallInterface = () => {
  const {
    device,
    callerInfo,
    incomingCall,
    callInProgress,
    answerCall,
    declineCall,
    endCall,
    isSpeakerOn,
    isMuted,
    toggleMute,
    toggleSpeaker,
  } = useTwilio();

  const { openDrawer } = useDrawer();
  const [drawerOpen, setDrawerOpen] = useState(false); // Track drawer state
  const [callTimer, setCallTimer] = useState(0);
  const { customClaims } = useAuth();

  const [outputDevices, setOutputDevices] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState("default");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (device && device.audio && device.audio.isOutputSelectionSupported) {
      const updateDevices = () => {
        const devices = [];
        console.log(
          "device.audio?.availableOutputDevices: ",
          device.audio?.availableOutputDevices
        );
        device.audio?.availableOutputDevices.forEach((d, id) => {
          devices.push({ id, label: d.label });
        });
        setOutputDevices(devices);
      };
      updateDevices();
      device.audio?.on("deviceChange", updateDevices);
      return () => {
        device.audio?.off("deviceChange", updateDevices);
      };
    }
  }, [device]);

  // Update the call duration every second when a call is in progress
  useEffect(() => {
    let timer;
    if (callInProgress) {
      timer = setInterval(() => {
        setCallTimer((prev) => prev + 1);
      }, 1000);
    } else {
      setCallTimer(0);
    }
    return () => clearInterval(timer);
  }, [callInProgress]);

  useEffect(() => {
    if (incomingCall || callInProgress) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  }, [incomingCall, callInProgress]);

  const isAdmin = customClaims?.isAdmin || false;
  if (!isAdmin) return;

  const isCallActive = incomingCall || callInProgress;
  const minutes = Math.floor(callTimer / 60);
  const seconds = callTimer % 60;

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const handleAnswerCall = () => {
    answerCall();
  };

  const handleDeclineCall = () => {
    declineCall();
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      {/* Floating FAB */}
      {isCallActive && (
        <Fab
          color="primary"
          variant="extended"
          onClick={toggleDrawer}
          sx={{
            position: "fixed",
            right: isMobile ? theme.spacing(1) : theme.spacing(3),
            bottom: isMobile ? theme.spacing(10) : theme.spacing(3),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pr: 3,
            pl: 2,
            py: 1,
            gap: 1,
            borderRadius: "50px",
            height: "unset",
            textTransform: "none",
          }}
        >
          <LiveCallAnimation />
          {isCallActive && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                {callerInfo?.firstName
                  ? `${callerInfo?.firstName} ${callerInfo?.lastName}`
                  : formatPhoneNumber(callerInfo.callerNumber)}
              </Typography>

              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: "0.7rem", color: "white" }}
              >
                {minutes}:{seconds.toString().padStart(2, "0")}
              </Typography>
            </Box>
          )}
        </Fab>
      )}

      {/* Drawer for Call Interface */}
      <ResponsiveDialog
        showDefaultHeader={false}
        desktopAnchor="right"
        anchor="bottom"
        open={drawerOpen}
        // open={true}
        onClose={handleCloseDrawer}
        width="300px"
      >
        {isMobile && (
          <Box
            sx={{
              position: "absolute",
              top: 8,
              left: "50%",
              transform: "translateX(-50%)",
              width: 50,
              height: 5,
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: 2.5,
            }}
          />
        )}
        {!isMobile && (
          <Button
            startIcon={<ArrowForwardIosRounded />}
            onClick={handleCloseDrawer}
            sx={{ textTransform: "none", mr: "auto", ml: 1, mt: 1, px: 2 }}
          >
            Minimize
          </Button>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <Box
            variant="outlined"
            style={{ width: "100%", textAlign: "center", borderRadius: 8 }}
          >
            <CardContent>
              {/* User Icon */}
              <Box display="flex" justifyContent="center" mb={2}>
                <ColorAvatar
                  name={
                    callerInfo?.firstName
                      ? `${callerInfo?.firstName} ${callerInfo?.lastName}`
                      : null
                  }
                  avatarUrl={callerInfo?.avatarUrl}
                />
              </Box>

              {/* Caller Info */}
              <Typography variant="h6">
                {callerInfo?.firstName
                  ? `${callerInfo?.firstName} ${callerInfo?.lastName}`
                  : "Unknown"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatPhoneNumber(callerInfo.callerNumber)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {callInProgress
                  ? `${minutes}:${seconds.toString().padStart(2, "0")}`
                  : "Incoming Call"}
              </Typography>

              {/* Action Buttons Layout */}
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                gap={2}
                mt={2}
              >
                <IconButton color="default" disabled>
                  <Chat />
                </IconButton>
                <IconButton color="default" disabled>
                  <Pause />
                </IconButton>
                <IconButton
                  color={isMuted ? "primary" : "default"}
                  onClick={toggleMute}
                >
                  {isMuted ? <MicOff /> : <Mic />}
                </IconButton>

                {isMobile && (
                  <IconButton
                    color={isSpeakerOn ? "primary" : "default"}
                    onClick={toggleSpeaker}
                  >
                    <VolumeUp />
                  </IconButton>
                )}
                {/* <IconButton color="default" disabled>
                  <Dialpad />
                </IconButton> */}
                {/* <IconButton color="default" disabled>
                  <CallMerge />
                </IconButton> */}
              </Box>

              {/* Audio output selection */}
              {device?.audio?.isOutputSelectionSupported &&
                outputDevices.length > 0 && (
                  <Select
                    value={selectedSpeaker}
                    onChange={(e) => {
                      const newDeviceId = e.target.value;
                      device.audio.speakerDevices
                        .set(newDeviceId)
                        .then(() =>
                          console.log("Speaker device set to", newDeviceId)
                        )
                        .catch((err) =>
                          console.error("Error setting speaker device:", err)
                        );
                      setSelectedSpeaker(newDeviceId);
                    }}
                    sx={{
                      mt: 2,
                      mb: 2,
                      width: "100%", // ⬅️ Ensures it takes full width of container
                      // maxWidth: "300px", // ⬅️ Prevents expanding too wide
                      whiteSpace: "nowrap", // ⬅️ Prevents text from breaking into new lines
                      overflow: "hidden", // ⬅️ Hides overflowing content instead of pushing layout
                      textOverflow: "ellipsis", // ⬅️ Adds "..." when content is too long
                    }}
                  >
                    {outputDevices.map((dev) => (
                      <MenuItem key={dev.id} value={dev.id}>
                        {dev.label || "Unknown Device"}
                      </MenuItem>
                    ))}
                  </Select>
                )}

              {/* Answer & Decline Buttons */}
              <Box mt={3} display="flex" justifyContent="center" gap={4}>
                <Button
                  color="error"
                  disableElevation
                  variant="contained"
                  onClick={callInProgress ? endCall : handleDeclineCall}
                  sx={{
                    width: "72px",
                    height: "72px",
                    borderRadius: "50px",
                    boxSizing: "border-box",
                  }}
                >
                  <CallEnd />
                </Button>
                {!callInProgress && (
                  <Button
                    color="success"
                    disableElevation
                    variant="contained"
                    onClick={handleAnswerCall}
                    sx={{
                      width: "72px",
                      height: "72px",
                      borderRadius: "50px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Phone />
                  </Button>
                )}
              </Box>
            </CardContent>
          </Box>
        </Box>
      </ResponsiveDialog>
    </>
  );
};

export default CallInterface;
