import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import SimplePage from "../SimplePage";

import { keyframes } from "@emotion/react";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import EmailView from "./EmailView";
import LoginView from "./LoginView";
import SignUpView from "./SignUpView";
import SmsView from "./SmsView";

const fadeInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const LoginOrSignUp = ({ title = "Get started" }) => {
  const [view, setView] = useState("EMAIL");
  const viewTitles = {
    EMAIL: "Login or Sign Up",
    LOGIN: "Welcome back",
    SMS: "Check your phone",
    SIGNUP: "Finish signing up",
    WELCOME: "Create your profile",
    FORGOT: "Forgot Password",
  };
  const [email, setEmail] = useState("");

  useLogPageVisit("/get-started");

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  const navigate = useNavigate();
  const auth = getAuth();

  // const getAnimation = (isCurrentStep) => {
  //   if (isCurrentStep) {
  //     return navigationDirection === "forward"
  //       ? `${fadeInFromLeft} 0.5s forwards`
  //       : `${fadeInFromRight} 0.5s forwards`;
  //   } else {
  //     return navigationDirection === "forward"
  //       ? `${fadeInFromRight} 0.5s forwards`
  //       : `${fadeInFromLeft} 0.5s forwards`;
  //   }
  // };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const claims = idTokenResult.claims;

          // Check if the custom claims contain a specific role
          if (claims.role) {
            console.log("User has a role, navigating to dashboard.");
            navigate("/dashboard");
          } else {
            console.log("User does not have a specified role.");
            // Optionally, handle users without the specific role
          }
        } catch (error) {
          console.error("Error fetching user token: ", error);
        }
      }
    });

    return unsubscribe;
  }, [navigate]);

  const handleClose = () => {
    console.log("close");
  };

  let content;

  switch (view) {
    case "EMAIL":
      content = (
        <EmailView
          title={title}
          setView={setView}
          email={email}
          setEmail={setEmail}
        />
      );
      break;
    case "LOGIN":
      content = (
        <LoginView
          handleClose={handleClose}
          setView={setView}
          email={email}
          showBack={true}
        />
      );
      break;
    case "SMS":
      content = (
        <SmsView
          onSignUpClick={() => setView("SIGNUP")}
          onBack={() => setView("LOGIN")}
          email={email}
          showBack={true}
        />
      );
      break;
    case "SIGNUP":
      content = (
        <SignUpView
          title={title}
          setView={setView}
          email={email}
          showBack={true}
        />
      );
      break;
    case "WELCOME":
      // content = <WelcomeView handleClose={handleClose} />;
      break;
    default:
      content = null;
  }

  return (
    <SimplePage>
      <Box
        variant="outlined"
        sx={{
          padding: { xs: 1, sm: 3 },
          borderRadius: "15px",
          // border: { xs: "none", sm: "1px solid rgba(0, 0, 0, 0.12)" },
          overflow: "hidden",
          maxWidth: "450px",
          width: "100%",
          minHeight: "300px",
          margin: "0 auto",
          position: "relative",
          display: "flex", // Use flex layout
          flexDirection: "column", // Stack children vertically
          boxSizing: "border-box",
          // animation: getAnimation(), // Apply the animation here
        }}
      >
        {content}
      </Box>
    </SimplePage>
  );
};

export default LoginOrSignUp;
