import { Whatshot } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  ListItemButton,
  Typography,
} from "@mui/material";
import React from "react";
import { renderIcon } from "./ProgressTab";

// Helper function to calculate progress percentage based on the streak count and threshold
const calculateStreakProgress = (count, threshold) => {
  if (count === 0) return 0;

  const currentLevelProgress = count % threshold;

  // If count equals the threshold, return 100% directly
  if (currentLevelProgress === 0) return 100;

  const percentage = (currentLevelProgress / threshold) * 100;
  return Math.min(percentage, 100); // Ensure it doesn't exceed 100%
};

export const renderLevel = (level, color) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "2px",
        height: "8px",
      }}
    >
      {[...Array(level)].map((_, i) => (
        <Box
          key={i}
          sx={{
            width: 8,
            height: 8,
            backgroundColor: color || "#c0c0c0", // Dot color based on streak
            borderRadius: "50%",
          }}
        />
      ))}
    </Box>
  );
};

const StreaksV2 = ({ streaks, map, onClick = () => {} }) => {
  return (
    <>
      {Object.entries(map)
        .filter(([key, value]) => value.type === "streaks") // Filter to only include "streaks"
        .map(([streakKey, streakData]) => {
          // Destructure from streaks
          const { count = 0 } = streaks?.[streakKey] || {};

          console.log("count: ", count);

          // Destructure properties from streakData (not from the full map)
          const { threshold, multiplier, increment, color } = streakData; // Destructure from streakData

          console.log(
            "streakV2 [threshold, multiplier, increment, color, count]: ",
            [threshold, multiplier, increment, color, count]
          );

          // Calculate current level and progress within the level
          const level = Math.min(Math.floor(count / threshold), 5); // Cap the level at 5
          const progress = calculateStreakProgress(count, threshold);
          const currentMultiplier = multiplier + increment * (level - 1);

          return (
            <ListItemButton
              key={streakKey}
              onClick={() => onClick(streakKey)}
              sx={{
                py: 1,
                px: { xs: 1, sm: 3, md: 6 },
                // flexGrow: 0,
                backgroundColor: "#fafafa",
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {renderLevel(level, color)}
                <Box sx={{ position: "relative", mt: "4px" }}>
                  {/* Circular progress ring */}
                  <CircularProgress
                    variant="determinate"
                    value={level === 5 ? 100 : progress}
                    size={80}
                    thickness={4}
                    sx={{
                      color: level >= 1 ? color : "#c0c0c0",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 20,
                      "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round", // This rounds the edges of the progress
                      },
                    }}
                  />

                  {/* Center of the circular progress */}
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "60px",
                      zIndex: 2,
                      m: "10px",
                    }}
                  >
                    {/* Icon and Streak Description */}
                    {renderIcon({
                      key: streakKey,
                      multiplier: progress,
                      glow: level >= 1,
                      active: level >= 1,
                    })}
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: count > 0 ? "#333" : "#c0c0c0",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {count > 0 ? (
                    <>
                      {count} <Whatshot sx={{ color: "#e6594c" }} />
                    </>
                  ) : (
                    "\u00A0"
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(0,0,0,.5)",
                    // backgroundColor: level >= 1 ? "#e0e0e0" : "transparent",
                    borderRadius: "5px",

                    px: 1,
                    py: 0.5,
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  {level >= 1
                    ? `+${currentMultiplier.toFixed(0)} ${
                        currentMultiplier === 1 ? "pt" : "pts"
                      }`
                    : `+${multiplier.toFixed(0)} ${
                        multiplier === 1 ? "pt" : "pts"
                      }`}
                </Typography>
              </Box>
            </ListItemButton>
          );
        })}
    </>
  );
};

export default StreaksV2;
