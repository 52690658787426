import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { debounce } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { setBadgeCount } from "../services/notificationServices";

const MessagesContext = createContext();

const INITIAL_FETCH = 10;
const PAGE_SIZE = 5;

export const MessagesProvider = ({ children }) => {
  const [allConversations, setAllConversations] = useState([]);
  const [unreadConversations, setUnreadConversations] = useState([]);
  const [hasMoreAll, setHasMoreAll] = useState(true); // ✅ Track separately for "All"
  const [hasMoreUnread, setHasMoreUnread] = useState(true); // ✅ Track separately for "Unread"
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterUnread, setFilterUnread] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);

  const mergeConversations = (prev, newConversations) => {
    // Ensure the newest data is kept
    const merged = [ ...prev, ...newConversations];

    // Deduplicate using a Map
    return Array.from(
      new Map(merged.map((conv) => [conv.id, conv])).values()
    ).sort(
      (a, b) =>
        (b?.lastMessageTimestamp?.toMillis() || 0) -
        (a?.lastMessageTimestamp?.toMillis() || 0)
    );
  };

  const fetchUnreadCount = debounce(async () => {
    try {
      const conversationsRef = collection(db, "conversations");
      const unreadQuery = query(conversationsRef, where("isRead", "==", false));

      // Fetch count from Firestore
      const snapshot = await getCountFromServer(unreadQuery);
      const count = snapshot.data().count;

      console.log("Fetched and setting unread count:", count);
      setUnreadCount(count);

      // Instead of incrementing/decrementing, set the badge to the total unread count
      await setBadgeCount("messages", count);
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  }, 300);

  useEffect(() => {
    fetchUnreadCount(); // Fetch once on mount
  }, []);

  useEffect(() => {
    const conversationsRef = collection(db, "conversations");

    // Fetch all conversations
    const allQuery = query(
      conversationsRef,
      orderBy("lastMessageTimestamp", "desc"),
      limit(INITIAL_FETCH)
    );

    const unsubscribeAll = onSnapshot(allQuery, (snapshot) => {
      const convs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllConversations((prev) => mergeConversations(prev, convs));
      setHasMoreAll(snapshot.docs.length === INITIAL_FETCH); // ✅ Track "All"
    });

    // Fetch unread conversations
    const unreadQuery = query(
      conversationsRef,
      where("isRead", "==", false),
      orderBy("lastMessageTimestamp", "desc"),
      limit(INITIAL_FETCH)
    );

    const unsubscribeUnread = onSnapshot(unreadQuery, (snapshot) => {
      const convs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUnreadConversations((prev) => mergeConversations(prev, convs));
      setHasMoreUnread(snapshot.docs.length === INITIAL_FETCH); // ✅ Track "Unread"
      fetchUnreadCount();
    });

    return () => {
      unsubscribeAll();
      unsubscribeUnread();
    };
  }, []);

  console.log("Unread conversations:", unreadConversations);

  const fetchMoreConversations = async () => {
    if (loadingMore) return;

    // Determine if there's more data to fetch based on the filter
    const hasMore = filterUnread ? hasMoreUnread : hasMoreAll;
    if (!hasMore) return;

    setLoadingMore(true);

    const lastTimestamp = (
      filterUnread
        ? unreadConversations[unreadConversations.length - 1]
        : allConversations[allConversations.length - 1]
    )?.lastMessageTimestamp;

    if (!lastTimestamp) {
      setLoadingMore(false);
      return;
    }

    const conversationsRef = collection(db, "conversations");
    const baseQuery = query(
      conversationsRef,
      orderBy("lastMessageTimestamp", "desc"),
      startAfter(lastTimestamp),
      limit(PAGE_SIZE)
    );

    const q = filterUnread
      ? query(baseQuery, where("isRead", "==", false))
      : baseQuery;

    try {
      const snapshot = await getDocs(q);
      const newConversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (filterUnread) {
        setUnreadConversations((prev) =>
          mergeConversations(prev, newConversations)
        );
        setHasMoreUnread(snapshot.docs.length === PAGE_SIZE); // ✅ Update "Unread" state
      } else {
        setAllConversations((prev) =>
          mergeConversations(prev, newConversations)
        );
        setHasMoreAll(snapshot.docs.length === PAGE_SIZE); // ✅ Update "All" state
      }
    } catch (error) {
      console.error("Error fetching more conversations:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const updateConversation = (conversationId, updatedData) => {
    setAllConversations((prev) =>
      prev.map((conv) =>
        conv.id === conversationId ? { ...conv, ...updatedData } : conv
      )
    );

    setUnreadConversations((prev) =>
      prev.map((conv) =>
        conv.id === conversationId ? { ...conv, ...updatedData } : conv
      )
    );
  };

  // Function to mark a conversation as read
  const markConversationAsRead = async (conversation) => {
    // also set unreadCount to 0 and reduce the badge count by that number as well

    try {
      const convRef = doc(db, "conversations", conversation.id);
      await updateDoc(convRef, { isRead: true, unreadCount: 0 });

      // Update allConversations state
      setAllConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversation.id ? { ...conv, isRead: true } : conv
        )
      );

      console.log("Remove from unreadConversations: ", conversation);
      // Remove from unreadConversations
      setUnreadConversations((prev) =>
        prev.filter((conv) => conv.id !== conversation.id)
      );

      fetchUnreadCount();
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Function to mark a conversation as unread
  const markConversationAsUnread = async (conversation) => {
    try {
      const convRef = doc(db, "conversations", conversation.id);
      await updateDoc(convRef, { isRead: false, unreadCount: 1 });

      // Update allConversations state
      setAllConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversation.id ? { ...conv, isRead: false } : conv
        )
      );

      // Add back to unreadConversations if it's not already there
      setUnreadConversations((prev) => {
        const exists = prev.some((conv) => conv.id === conversation.id);
        return exists ? prev : [...prev, { ...conversation, isRead: false }];
      });

      fetchUnreadCount();
    } catch (error) {
      console.error("Error marking conversation as unread:", error);
    }
  };

  const findOrCreateConversation = async (phoneNumber) => {
    const sanitized = phoneNumber.replace(/\D/g, ""); // Strip all non-numeric characters
    if (sanitized.length !== 10) {
      throw new Error("Please enter a valid 10-digit phone number.");
    }

    try {
      const convRef = doc(db, "conversations", sanitized);
      const convSnap = await getDoc(convRef);
      let conversation;

      if (convSnap.exists()) {
        conversation = { id: convSnap.id, ...convSnap.data() };
      } else {
        conversation = {
          id: sanitized,
          participantIds: [sanitized],
          allPhoneNumbers: [sanitized],
          lastMessage: "",
          lastMessageTimestamp: Timestamp.now(),
          createdAt: Timestamp.now(),
          isRead: true,
        };
        await setDoc(convRef, conversation);
      }

      // Add to state while preventing duplicates
      setAllConversations((prev) => mergeConversations(prev, [conversation]));

      return conversation;
    } catch (err) {
      console.error("Error finding or creating conversation:", err);
      throw new Error("Failed to create conversation. Please try again.");
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        unreadCount,
        allConversations,
        unreadConversations,
        fetchUnreadCount,
        filterUnread,
        setFilterUnread,
        fetchMoreConversations,
        loadingMore,
        updateConversation,
        markConversationAsRead, // ✅ Add this
        markConversationAsUnread, // ✅ Add this
        hasMore: filterUnread ? hasMoreUnread : hasMoreAll, // ✅ Expose the correct "hasMore"
        findOrCreateConversation,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
