import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Timestamp, arrayUnion, doc, setDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import GuestHeader from "../../components/GuestHeader";
import { db } from "../../firebase";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import WebFooter from "../../pages/public/sections/WebFooter";
import HelperListPublic from "./HelperListPublic";

function HelpersPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let scrollTimeout;
    let scrollDepth = 0;

    const logScrollDepth = async () => {
      const timestamp = Timestamp.now();

      const logEntry = {
        page: "helpers",
        timestamp: timestamp,
        scrollDepth: scrollDepth,
      };

      let storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) {
        try {
          await setDoc(
            doc(db, "sessions", storedSessionId),
            {
              "~logs": arrayUnion(logEntry),
            },
            { merge: true }
          );
        } catch (err) {}
      }
    };

    const handleScroll = () => {
      const currentScrollDepth = Math.round(window.scrollY); // Measure scroll depth from the top in pixels
      scrollDepth = currentScrollDepth;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        logScrollDepth();
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  useLogPageVisit("/helpers");

  return (
    <div>
      <Helmet>
        <title>
          Visit Helpers and Testimonials - Read Feedback from Past Visits
        </title>
        <meta
          name="description"
          content="Read authentic helpers and testimonials from past visits. Discover how our senior services have helped others with tasks, tech help, and more."
        />
      </Helmet>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              // height: "100%",
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h3"}
              fontWeight="700"
              align="center"
              sx={{ mb: 1 }}
            >
              Meet our Helpers
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                mb: 2,
                gap: "10px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={400}
                sx={{ textAlign: "center" }}
              >
                {isMobile
                  ? "Handpicked for their character."
                  : "Handpicked for their character and skill."}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              mb={4}
              align={isMobile ? "left" : "center"}
              sx={{ maxWidth: "800px", mx: "auto" }}
            >
              From Eagle Scouts to student leaders, varsity athletes to future
              engineers... our helpers are exceptional young adults who are
              hardworking, respectful, and skilled. As you explore their
              backgrounds and talents, you’ll quickly see what sets Linked Lives
              apart—and why our service is truly one of a kind.
            </Typography>
          </Box>
          <HelperListPublic />
        </Container>
      </Box>

      <WebFooter />
    </div>
  );
}

export default HelpersPage;
