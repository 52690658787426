/* global google */

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Draw } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getMembersInArea } from "../../../services/locationServices";

class CustomRenderer {
  render({ count, position }) {
    // Calculate the scale, ensuring a minimum value
    const minScale = 7; // Set your desired minimum scale
    const scale = Math.max(minScale, Math.log(count) * 4);

    return new google.maps.Marker({
      position,
      label: {
        text: String(count),
        color: "white",
        fontSize: "12px",
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "blue",
        fillOpacity: 0.8,
        scale: scale, // Use the adjusted scale
        strokeColor: "darkblue",
        strokeWeight: 1,
      },
      clickable: false, // Disable clickability
    });
  }

  onRemove(marker) {
    marker.setMap(null);
  }
}

function GoogleMapsDisplay({
  employeeGeohash = "",
  initialBounds,
  initialCenter = {},
  handleEdit = null,
  mobileHeight = null,
  desktopHeight = null,
  showLocationDot = true,
}) {
  const mapRef = useRef(null);
  const map = useRef(null);
  const poly = useRef(null);
  const drawing = useRef(null);

  const [polygonBounds, setPolygonBounds] = useState([]);
  const [locating, setLocating] = useState(false);
  const [userLocationMarker, setUserLocationMarker] = useState(null);
  const [points, setPoints] = useState([]);
  const [clusterer, setClusterer] = useState(null); // Store the clusterer reference

  useEffect(() => {
    if (!initialCenter || !map.current) return; // Ensure map is ready and initialCenter is available

    const center = map.current.getCenter(); // Get LatLng object
    const lat = center.lat(); // Get latitude
    const lng = center.lng(); // Get longitude

    console.log("Getting memberCoords for lat:", lat, "lng:", lng);

    const fetchMemberCoords = async () => {
      try {
        const memberCoords = await getMembersInArea(
          { geohash: employeeGeohash, center: { lat, lng } }, // Pass the updated center
          10
        );
        setPoints(memberCoords);
        console.log("memberCoords: ", memberCoords);
      } catch (error) {
        console.error("Error getting member coordinates: ", error);
        setPoints([]); // Set to empty array in case of error
      }
    };

    fetchMemberCoords();
  }, [initialCenter, employeeGeohash]); // Depend on initialCenter and employeeGeohash

  const mapStyle = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides points of interest
    },
    {
      featureType: "poi.park",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides parks
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides administrative labels
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }], // Hides administrative labels
    },
    // {
    //   featureType: "administrative",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }], // Hides administrative labels
    // },
    // {
    //   featureType: "road",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }], // Hides road labels
    // },
  ];

  const disableMapOptions = () => {
    if (map.current) {
      map.current.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        gestureHandling: "none", // Disable gesture handling when drawing
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        clickableIcons: false, // Disable clicks on POIs
        disableDoubleClickZoom: true,
        styles: mapStyle, // Apply custom styles
      });
    }
  };

  const getUserLocation = () => {
    if (!showLocationDot) return null;

    setLocating(true); // Start locating process

    if (navigator.geolocation && !userLocationMarker) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Clear existing marker if it exists
          if (userLocationMarker) {
            userLocationMarker.setMap(null);
          }

          // Create a new marker at the current location
          const marker = new google.maps.Marker({
            position: pos,
            map: map.current,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#4285F4",
              fillOpacity: 1,
              scale: 8,
              strokeColor: "white",
              strokeWeight: 2,
            },
          });
          setUserLocationMarker(marker);
          setLocating(false); // Successfully located, stop locating
        },
        () => {
          setLocating(false); // Failed to locate, stop locating
        }
      );
    } else {
      setLocating(false); // Geolocation is not supported by the browser
    }
  };

  useEffect(() => {
    console.log("initialCenter: ", initialCenter);

    console.log("useEffect GoogleMapsDisplay initialBounds: ", initialBounds);
    if (map.current && initialBounds?.length > 0) {
      console.log("Map.current is TRUE");
      const bounds = new google.maps.LatLngBounds();

      console.log("initialBounds: ", initialBounds);

      initialBounds.forEach((point) => {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      });

      map.current.fitBounds(bounds);
      initializePolygonWithBounds(initialBounds);
      const listener = map.current.addListener("bounds_changed", () => {
        const currentZoom = map.current.getZoom();
        map.current.setZoom(currentZoom); // Optionally adjust the zoom level here
        google.maps.event.removeListener(listener);
      });
    } else if (
      map.current &&
      !isNaN(initialCenter.lat) &&
      !isNaN(initialCenter.lng)
    ) {
      console.log("Map.current is FALSE");
      map.current.setCenter(
        new google.maps.LatLng(initialCenter?.lat || 0, initialCenter?.lng || 0)
      );
    }
  }, [initialBounds, initialCenter, map.current]);

  const initializePolygonWithBounds = (bounds) => {
    // Clear existing polygon if it exists
    // setPolygonBounds([]);
    if (poly.current) {
      poly.current.setMap(null);
      poly.current = null;
    }

    const path = bounds.map(
      (point) => new google.maps.LatLng(point.lat, point.lng)
    );
    poly.current = new google.maps.Polygon({
      map: map.current,
      paths: path,
      clickable: false,
    });

    // setPolygonBounds(bounds); // Set the initial state for polygon bounds
  };

  const initialize = () => {
    const center = new google.maps.LatLng(
      initialCenter.lat || 48.47,
      initialCenter.lng || -122.7
    );
    const mapOptions = {
      zoom: 12,
      center: center,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false, // Removes the zoom in/out buttons
      zoomFractional: true, // Enables fractional zoom (Google Maps API allows fractional zoom natively)
      mapTypeControl: false,
      draggable: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false, // Disable clicks on POIs
      styles: mapStyle, // Apply custom styles
    };
    map.current = new google.maps.Map(mapRef.current, mapOptions);

    const newClusterer = new MarkerClusterer({
      map: map.current,
      markers: [], // Start with an empty set of markers
      renderer: new CustomRenderer(),
    });

    setClusterer(newClusterer); // Set the clusterer reference
  };

  useEffect(() => {
    initialize();
    getUserLocation();
  }, []);

  useEffect(() => {
    if (clusterer && points.length > 0) {
      // Clear previous markers in the clusterer
      clusterer.clearMarkers();

      // Create new markers and add to clusterer
      const markers = points.map((point) => {
        return new google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: point.status === "active" ? "blue" : "red",
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: point.status === "active" ? "darkblue" : "darkred",
          },
        });
      });

      // Add the new markers to the clusterer
      clusterer.addMarkers(markers);
    }
  }, [points, clusterer]);

  return (
    <div>
      <Box
        onClick={handleEdit && handleEdit}
        sx={{
          borderRadius: "15px",
          overflow: "hidden",
          // border: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          cursor: "pointer",
        }}
      >
        <Box
          ref={mapRef}
          sx={{
            height: {
              xs: mobileHeight ? mobileHeight : "350px",
              sm: desktopHeight ? desktopHeight : "350px",
            },
            width: "100%",
          }}
        ></Box>
        {handleEdit && (
          <Button
            variant="contained"
            color="inherit"
            sx={{
              bgcolor: "white",
              borderRadius: "5px",
              fill: "#f5f5f5",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              width: "auto",
              position: "absolute",
              top: 0,
              left: 0,
              m: { xs: 1, sm: 2 },
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
              height: "42px",
              justifyContent: "center",
              pr: "15px",
              pl: "10px",
              textTransform: "none",
            }}
            onClick={handleEdit}
          >
            <Draw color="primary" />
            <Typography color="black">Edit map</Typography>
          </Button>
        )}
      </Box>
    </div>
  );
}

export default GoogleMapsDisplay;
