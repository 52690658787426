import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, ButtonBase, Typography } from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import SmsVerification from "../login/SmsVerification";

const SmsView = ({
  onSignUpClick = () => {},
  onBack = () => {},
  email,
  showBack = false,
}) => {
  const [smsStatus, setSmsStatus] = useState(null);
  const [smsError, setSmsError] = useState("");

  const navigate = useNavigate();

  const handleSendSmsCode = async () => {
    setSmsStatus("sending"); // Set status to 'sending' before the operation
    try {
      console.log("sending code");
      const sendVerificationCode = httpsCallable(
        functions,
        "sendVerificationCode"
      );
      await sendVerificationCode({ email });
      setSmsStatus("success"); // Set status to 'success' after successful operation
      setSmsError("");
    } catch (error) {
      setSmsStatus("error"); // Set status to 'error' in case of failure
      console.log("Error code:", error.code);
      console.error("Error sending SMS: ", error.message);
      // Normalize the error to always include `code` and `message`
      setSmsError({
        code: error?.code?.replace("functions/", "") || "internal",
        message:
          error?.message ||
          "An unexpected error occurred while sending the verification code.",
      });
    }
  };

  useEffect(() => {
    handleSendSmsCode();
  }, []);

  return (
    <Box>
      {showBack && (
        <ButtonBase onClick={onBack} sx={{ color: "text.secondary", my: 1 }}>
          <KeyboardArrowLeft /> Back
        </ButtonBase>
      )}
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 2 }}>
        Enter code
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: "1em" }}
      >
        Check your phone or email for the login code.
      </Typography>
      <SmsVerification
        onBack={onBack}
        navigate={navigate}
        onSendSmsCode={handleSendSmsCode}
        email={email}
        smsStatus={smsStatus}
        smsError={smsError}
        showBack={false}
        onSignUpClick={onSignUpClick}
      />
    </Box>
  );
};

export default SmsView;
